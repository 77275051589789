import React from 'react';

import CommitteeMemberManagement from "../Administration/CommitteeMemberManagement";
import { CommitteeMemberManagementService, CustomEventHandlerService } from "../../../Services";
import { CustomEventType } from "../../../Common/Enums";
import { CommitteeMemberManagementDTO } from "../../../Models"

interface Props {}
interface State {
    CommitteeMembers: CommitteeMemberManagementDTO[];
    CommitteeDTO: CommitteeMemberManagementDTO;
    committeemembers: CommitteeMemberManagementDTO[];
}

class Administration extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);
        this.state = {
            CommitteeMembers: [],
            CommitteeDTO: {} as CommitteeMemberManagementDTO,
            committeemembers: [],
        }
    }

    async componentDidMount() {
        CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);
        const committeemembers = await CommitteeMemberManagementService.getAll();
        this.setState({
            committeemembers, CommitteeMembers: committeemembers
        }, () => {
            CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
        });
    }

    render() {
        const { CommitteeMembers } = this.state;
        return (
            <CommitteeMemberManagement CommitteeMembersData={CommitteeMembers}/>
        )
    }
}

export default Administration;