import React from "react";
import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    createStyles,
    Theme,
    withStyles
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";

import { BulkPPQResponseDTO } from "../../../Models";
import Utils from "../../../Common/Utils";

const TableHeaderCell: React.FC<{ label: string }> = ({ label }) => {
    return (
        <TableCell style={{ minWidth: 125 }}>
            <Typography style={{ fontSize: 16, fontWeight: 500, color: '#181818' }}>{label}</Typography>
        </TableCell>
    );
};

const TableBodyCell: React.FC<{ label: string | number }> = ({ label }) => {
    return (
        <TableCell>
            <Typography style={{ fontSize: 14, fontWeight: 400, color: '#828282' }}>{label}</Typography>
        </TableCell>
    );
};

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(even)': {
                backgroundColor: '#F9F2F2',
            },
        },
    }),
)(TableRow);

interface Props {
    ppqBulkUploadResponses: BulkPPQResponseDTO[];
}
interface State {
    page: number;
    pageSize: number;
}
class PPQBulkUploadTable extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            page: 1,
            pageSize: 10,
        };
    }

    render() {
        const { ppqBulkUploadResponses } = this.props;
        const { page, pageSize } = this.state;

        const paginatedData = Utils.paginate(ppqBulkUploadResponses, pageSize, page);

        return (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead style={{ backgroundColor: '#EEEEEE' }}>
                        <TableRow>
                            <TableHeaderCell label="PPQ ID" />
                            <TableHeaderCell label="Faculty ID" />
                            <TableHeaderCell label="PPQ Year" />
                            <TableHeaderCell label="PPQ Response Status" />
                            <TableHeaderCell label="PPQ Response Code" />
                            <TableHeaderCell label="PPQ Response Description" />
                            <TableHeaderCell label="Created On" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedData.map((row) => (
                            <StyledTableRow key={row.id}>
                                <TableBodyCell label={row.ppqid} />
                                <TableBodyCell label={row.facultyid} />
                                <TableBodyCell label={row.ppqyear} />
                                <TableBodyCell label={row.responseStatus} />
                                <TableBodyCell label={row.responsecode} />
                                <TableBodyCell label={row.responsedescription} />
                                <TableBodyCell label={Utils.getFormattedDateTime(row.createdon)} />
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
                <br />
                {ppqBulkUploadResponses.length > 0 ? <Pagination size="medium"
                    count={Math.ceil(ppqBulkUploadResponses.length / pageSize)} page={page} onChange={this.handlePageChange} /> : null}
                <br />
            </TableContainer>
        );
    }

    handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        this.setState({ page: value });
    }
}

export default PPQBulkUploadTable;