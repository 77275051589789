import React from "react";
import { Grid } from "@material-ui/core";

import { FacultyPPQDataDTO } from "../../../Models";

import SearchFilter from "../../../Components/Search/SearchFilter";
import SearchFilterModel from "../../../Components/Search/SearchFilterModel";
import ExportToExcel from "../../../Components/File/ExportToExcel";
import PPQExceptionFilterModel from "./PPQExceptionFilterModel";

interface Props {
    ppqExceptions: FacultyPPQDataDTO[];
    ppqExceptionFilter: PPQExceptionFilterModel;
    onExceptionFilterChange: ((ppqExceptionFilter: PPQExceptionFilterModel) => void);
}
interface State { }
class PPQExceptionFilter extends React.Component<Props, State> {
    render() {
        const { ppqExceptions, ppqExceptionFilter } = this.props;

        const dataToExport = ppqExceptions.map(ppqException => ({
            firstname: ppqException.firstname,
            lastname: ppqException.lastname,
            facultyid: ppqException.facultyid,
            hcptype: ppqException.hcptype,
            specialty: ppqException.specialty,
            exceptiontype: ppqException.exceptiontype,
        }));

        return (
            <Grid
                container
                direction="row"
                alignItems="center"
                justify="flex-end">
                <Grid item md={5}>
                    <Grid container alignItems="center" justify="flex-end" spacing={3}>
                        <Grid item md={8}>
                            <SearchFilter label="Search by Name, HCP Type or Specialty" value={ppqExceptionFilter.searchText}
                                onChange={this.handleSearchFilterChange} />
                        </Grid>
                        <Grid item>
                            <ExportToExcel fileName="PPQExceptions" data={dataToExport} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    handleSearchFilterChange = (searchFilter: SearchFilterModel) => {
        const { ppqExceptionFilter, onExceptionFilterChange } = this.props;

        ppqExceptionFilter.searchText = searchFilter.searchText;
        onExceptionFilterChange(ppqExceptionFilter);
    }
}

export default PPQExceptionFilter;