import React from "react";
import { Grid, IconButton as IButton, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCloudUploadAlt as CloudUploadIcon,
    faPen as EditIcon,
    faTrash as DeleteForeverIcon,
    faPlus as AddIcon,
    faExternalLinkAlt as PopupIcon,faMinus as Minimize
} from '@fortawesome/free-solid-svg-icons';
import { SizeProp } from "@fortawesome/fontawesome-svg-core";

import ModalDialog from "../Modal/ModalDialog";
import RedButton from "./RedButton";
import WhiteButton from "./WhiteButton";
import BlueButton from "./BlueButton";

type IconType = 'Add' | 'Edit' | 'Delete' | 'DeleteDialog' | 'CloudUpload' | 'Popup' |'Minimize';

interface Props {
    id?: string;
    iconType: IconType,
    isFR: boolean;
    onClick: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void);
}
interface State {
    isDialogOpen: boolean;
}
class IconButton extends React.Component<Props, State> {
    public static defaultProps = {
        isFR: false
    };

    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            isDialogOpen: false,
        };
    }

    render() {
        const { id, iconType, isFR, onClick } = this.props;
        const { isDialogOpen } = this.state;

        const buttonStyle: React.CSSProperties = { color: '#EE3A29' };
        let cloudIconSize: SizeProp = "lg";

        if (isFR) {
            buttonStyle.color = '#004693';
            cloudIconSize = "2x";
        }

        switch (iconType) {
            case 'Add':
                return (
                    <IButton style={buttonStyle} onClick={onClick}>
                        <FontAwesomeIcon id={id} icon={AddIcon} size="xs" />
                    </IButton>
                );
            case 'Minimize':
                return (
                    <IButton style={buttonStyle} onClick={onClick}>
                        <FontAwesomeIcon id={id} icon={Minimize} size="xs" />
                    </IButton>
                );
            case 'Edit':
                return (
                    <IButton style={buttonStyle} onClick={onClick}>
                        <FontAwesomeIcon id={id} icon={EditIcon} size="xs" />
                    </IButton>
                );
            case 'Delete':
                return (
                    <IButton style={buttonStyle} onClick={onClick}>
                        <FontAwesomeIcon id={id} icon={DeleteForeverIcon} size="xs" />
                    </IButton>
                );
            case 'DeleteDialog':
                return (
                    <>
                        <ModalDialog title={`Delete Action`} dialogWidth="sm"
                            isOpen={isDialogOpen} onClose={this.handleCancel}
                            dialogAction={
                                <Grid container
                                    spacing={2}
                                    direction="row"
                                    justify="flex-end"
                                    alignItems="center"
                                    style={{ marginRight: 30 }}>
                                    <Grid item>
                                        <WhiteButton label="Cancel" onClick={this.handleCancel} />
                                    </Grid>
                                    <Grid item>
                                        {isFR ? <BlueButton label="Delete" onClick={this.onDeleteClick} />
                                            : <RedButton label="Delete" onClick={this.onDeleteClick} />}
                                    </Grid>
                                </Grid>
                            }>
                            <Typography style={{ fontSize: 16, fontWeight: 400, color: '#383838' }}>
                                Are you sure you want to delete ?
                            </Typography>
                        </ModalDialog>
                        <IButton style={buttonStyle} onClick={this.handleDelete}>
                            <FontAwesomeIcon id={id} icon={DeleteForeverIcon} size="xs" />
                        </IButton>
                    </>
                );
            case 'CloudUpload':
                return (
                    <IButton style={buttonStyle} onClick={onClick}>
                        <FontAwesomeIcon id={id} icon={CloudUploadIcon} size={cloudIconSize} />
                    </IButton>
                );
            case 'Popup':
                return (
                    <IButton style={buttonStyle} onClick={onClick}>
                        <FontAwesomeIcon id={id} icon={PopupIcon} size="xs" />
                    </IButton>
                );
            default:
                return <></>;
        }
    }

    onDeleteClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        this.handleCancel();
        this.props.onClick(event);
    }

    handleDelete = () => {
        this.setState({ isDialogOpen: true });
    }

    handleCancel = () => {
        this.setState({ isDialogOpen: false });
    }
}

export default IconButton;