import React from "react";
import { Alert as MUIAlert, AlertTitle } from "@material-ui/lab";

import { CustomEventType } from "../../Common/Enums";

interface Props {
    id: string;
}
interface State {
    eventType?: CustomEventType;
    message: string;
    show: boolean;
    alertId: string;
}
class Alert extends React.Component<Props, State> {
    public static defaultProps = {
        id: 'DefaultAlert',
    }
    errorRef: React.RefObject<HTMLDivElement>;

    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.errorRef = React.createRef();

        this.state = {
            eventType: undefined,
            message: '',
            show: false,
            alertId: props.id,
        };
    }

    componentDidMount() {
        document.addEventListener(CustomEventType[CustomEventType.SuccessAlert], (event: any) => {
            const { alertId } = this.state;

            if (alertId === 'DefaultAlert' && !event.detail.alertId) {
                this.setState({
                    eventType: CustomEventType.SuccessAlert,
                    message: event.detail,
                    show: true
                });
            } else {
                if (alertId === event.detail.alertId) {
                    this.setState({
                        eventType: CustomEventType.SuccessAlert,
                        message: event.detail.message,
                        show: true
                    });
                }
            }
        });

        document.addEventListener(CustomEventType[CustomEventType.ErrorAlert], (event: any) => {
            const { alertId } = this.state;

            if (alertId === 'DefaultAlert' && !event.detail.alertId) {
                this.setState({
                    eventType: CustomEventType.ErrorAlert,
                    message: event.detail,
                    show: true
                });
            } else {
                if (alertId === event.detail.alertId) {
                    this.setState({
                        eventType: CustomEventType.ErrorAlert,
                        message: event.detail.message,
                        show: true
                    });
                }
            }

            if (this.errorRef.current) {
                window.scrollTo(0, this.errorRef.current.offsetTop - 50);
            }
        });

        document.addEventListener(CustomEventType[CustomEventType.ClearAlert], (event: any) => {
            this.setState({
                eventType: undefined,
                message: '',
                show: false,
            });
        });
    }

    componentWillUnmount() {
        document.removeEventListener(CustomEventType[CustomEventType.SuccessAlert], (event: any) => {
            this.setState({
                eventType: undefined,
                message: '',
                show: false
            })
        });

        document.removeEventListener(CustomEventType[CustomEventType.ErrorAlert], (event: any) => {
            this.setState({
                eventType: undefined,
                message: '',
                show: false
            })
        });

        document.removeEventListener(CustomEventType[CustomEventType.ClearAlert], (event: any) => {
            this.setState({
                eventType: undefined,
                message: '',
                show: false
            })
        });
    }

    render() {
        const { eventType, show, message } = this.state;

        if (show && typeof eventType !== "undefined") {
            switch (CustomEventType[eventType]) {
                case CustomEventType[CustomEventType.ErrorAlert]:
                    return (
                        <MUIAlert ref={this.errorRef} icon={false} onClose={() => this.setState({ show: false })} severity="error">
                            <AlertTitle>Error</AlertTitle>
                            {message}
                        </MUIAlert>
                    );
                case CustomEventType[CustomEventType.SuccessAlert]:
                    return (
                        <MUIAlert icon={false} onClose={() => this.setState({ show: false })} severity="success">
                            {message}
                        </MUIAlert>
                    );
                default:
                    return null;
            }
        }

        return null;
    }
}

export default Alert;