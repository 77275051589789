import React from "react";

import { CustomEventType } from "../../Common/Enums";
import { CustomEventHandlerService } from "../../Services";
import RateExceptionForm from "./RateExceptionReadOnlyForm";

interface Props { }
interface State { }
class FMVReadOnlyRateExceptionForm extends React.Component<Props, State> {
    componentDidMount() {
        CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
    }

    render() {
        return <RateExceptionForm />;
    }
}

export default FMVReadOnlyRateExceptionForm;