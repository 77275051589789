import React from "react";
import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableBody
} from "@material-ui/core";

import { FacultyDTO, FacultyQuestDTO } from "../../../Models";
import TableHeaderCell from "../../../Components/Table/TableHeaderCell";
import TableBodyCell from "../../../Components/Table/TableBodyCell";
import StyledTableRow from "../../../Components/Table/StyledTableRow";
import Utils from "../../../Common/Utils";

interface Props {
    pendingTieringReportData: FacultyDTO[];
    signOfAnswers: FacultyQuestDTO[];
}
interface State { }
class PendingTieringReportTable extends React.Component<Props, State> {
    render() {
        const { pendingTieringReportData, signOfAnswers } = this.props;

        return (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead style={{ backgroundColor: '#EEEEEE' }}>
                        <TableRow>
                            <TableHeaderCell label="Faculty ID" minWidth={100} />
                            <TableHeaderCell label="First Name" />
                            <TableHeaderCell label="Last Name" />
                            <TableHeaderCell label="Modified Date" />
                            <TableHeaderCell label="Status" />
                            <TableHeaderCell label="SignOff" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {pendingTieringReportData.map((row) => {
                            let signOff = 'Pending';
                            const signOffAnswer = signOfAnswers.find(p => p.facultyid === row.facultyid);

                            if (signOffAnswer) {
                                signOff = 'Completed';
                            }

                            return (
                                <StyledTableRow key={row.id}>
                                    <TableBodyCell label={row.facultyid} />
                                    <TableBodyCell label={row.firstname} />
                                    <TableBodyCell label={row.lastname} />
                                    <TableBodyCell label={Utils.getFormattedDateTime(row.updatedon)} />
                                    <TableBodyCell label={row.questionairystatus} />
                                    <TableBodyCell label={signOff} />
                                </StyledTableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
}

export default PendingTieringReportTable;