import React from "react";
import { Backdrop, CircularProgress } from '@material-ui/core';

import { CustomEventType } from "../../Common/Enums";

interface Props { }
interface State {
    eventType?: CustomEventType;
    show: boolean;
}
class BackdropLoader extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            eventType: undefined,
            show: false,
        };
    }

    componentDidMount() {
        document.addEventListener(CustomEventType[CustomEventType.LoadingStart], (event: any) => {
            this.setState({
                eventType: CustomEventType.LoadingStart,
                show: true
            });
        });

        document.addEventListener(CustomEventType[CustomEventType.LoadingStop], (event: any) => {
            this.setState({
                eventType: CustomEventType.LoadingStop,
                show: false
            });
        });
    }

    componentWillUnmount() {
        document.removeEventListener(CustomEventType[CustomEventType.LoadingStart], (event: any) => {
            this.setState({
                eventType: undefined,
                show: false
            })
        });

        document.removeEventListener(CustomEventType[CustomEventType.LoadingStop], (event: any) => {
            this.setState({
                eventType: undefined,
                show: false
            })
        });
    }

    render() {
        const { show } = this.state;

        return (
            <Backdrop open={show} onClick={this.handleClose} style={{ zIndex: 99 }}>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }

    handleClose = () => { }
}

export default BackdropLoader;