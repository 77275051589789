import React from 'react';
import { Button, Grid } from '@material-ui/core';

import { BIRateDTO } from "../../../Models";
import { SpecialtyService, HCPTypeService, GroupService, TierService } from "../../../Services";
import { ValidationType } from '../../../Common/Enums';

import SelectModel from '../../../Components/Select/SelectModel';
import TextInputField from '../../../Components/TextInput/TextInputField';
import ModalDialog from '../../../Components/Modal/ModalDialog';
import SingleAutoComplete from '../../../Components/Select/SingleAutoComplete';
import MultiAutoComplete from '../../../Components/Select/MultiAutoComplete';

interface Props {
    birate?: BIRateDTO;
    onCancelClick: ((event: React.MouseEvent<HTMLButtonElement> | undefined) => void);
    onSubmit: ((birate: BIRateDTO) => void);
}
interface State {
    birate: BIRateDTO;
    specialties: SelectModel[];
    hcpTypes: SelectModel[];
    groups: SelectModel[];
    tiers: SelectModel[];
    formValidationState: { name: string, isValid: boolean }[];
}
class BIRateForm extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);

        let isValid = false;
        let birate = {} as BIRateDTO;

        if (props.birate && props.birate.birateid) {
            isValid = true;
            birate = props.birate
        }

        this.state = {
            birate,
            specialties: [],
            hcpTypes: [],
            groups: [],
            tiers: [],
            formValidationState: [
                { name: 'hcptype', isValid },
                { name: 'specialty', isValid },
                { name: 'bigroup', isValid },
                { name: 'bifuturegroup', isValid },
                { name: 'bicurrentrate', isValid },
                { name: 'bifuturerate', isValid },
                { name: 'bitier', isValid },
            ],
        };
    }

    async componentDidMount() {
        const specialties = await SpecialtyService.getAllForSelect();
        const hcpTypes = await HCPTypeService.getAllForSelect();
        const groups = await GroupService.getAllForSelect();
        const tiers = await TierService.getAllForSelect();

        this.setState({ specialties, hcpTypes, groups, tiers });
    }

    render() {
        const { birate, specialties, hcpTypes, groups, tiers, formValidationState } = this.state;
        const { onCancelClick } = this.props;

        const biTiers = birate.bitier ? birate.bitier.toString().split(',') : [];
        const isUpdateForm = birate.birateid !== undefined;
        const isFormNotValid = formValidationState.some(p => p.isValid === false);

        const biTierComponent = isUpdateForm ?
            <MultiAutoComplete id="bitier" label="BI Tier"
                selectedValues={biTiers} values={tiers} onChange={this.handleMultiSelectChange} /> :
            <SingleAutoComplete id="bitier" label="BI Tier"
                selectedValue={birate.bitier} values={tiers} onChange={this.handleSelectChange} />;

        return (
            <ModalDialog title={`${isUpdateForm ? 'Update' : 'Add'} BI Specialty Group`}
                isOpen={true} onClose={() => onCancelClick(undefined)}
                dialogAction={
                    <Grid container
                        spacing={2}
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                        style={{ marginRight: 30 }}>
                        <Grid item>
                            <Button size="medium" variant="outlined" style={{ borderColor: '#707070', color: '#555555', fontWeight: 400, fontSize: 16, textTransform: 'none' }}
                                onClick={onCancelClick}>Cancel</Button>
                        </Grid>
                        <Grid item>
                            {isFormNotValid ?
                                <Button disabled={true} size="medium" variant="outlined" style={{ fontWeight: 500, fontSize: 16, textTransform: 'none' }}
                                    onClick={this.handleSubmit}>{isUpdateForm ? 'Update' : 'Add'}</Button> :
                                <Button size="medium" variant="outlined" style={{ backgroundColor: '#C6C6C6', color: '#5D5D5D', fontWeight: 500, fontSize: 16, textTransform: 'none' }}
                                    onClick={this.handleSubmit}>{isUpdateForm ? 'Update' : 'Add'}</Button>}
                        </Grid>
                    </Grid>
                }>
                <form noValidate autoComplete="off">
                    <div>
                        <Grid container spacing={5}>
                            <Grid item xs={6}>
                                <SingleAutoComplete id="hcptype" label="HCP Type"
                                    selectedValue={birate.hcptype} values={hcpTypes} onChange={this.handleSelectChange} />
                            </Grid>
                            <Grid item xs={6}>
                                <SingleAutoComplete id="specialty" label="Specialty"
                                    selectedValue={birate.specialty} values={specialties} onChange={this.handleSelectChange} />
                            </Grid>
                            <Grid item xs={6}>
                                <SingleAutoComplete id="bigroup" label="BI Current Group"
                                    selectedValue={birate.bigroup} values={groups} onChange={this.handleSelectChange} />
                            </Grid>
                            <Grid item xs={6}>
                                <SingleAutoComplete id="bifuturegroup" label="BI Future Group"
                                    selectedValue={birate.bifuturegroup} values={groups} onChange={this.handleSelectChange} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInputField id="bicurrentrate" label="BI Current Year Rate"
                                    inputType="number" validationType={ValidationType.RATE}
                                    isDisabled={biTiers.length > 1} value={birate.bicurrentrate} onChange={this.handleChange} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInputField id="bifuturerate" label="BI Future Year Rate"
                                    inputType="number" validationType={ValidationType.RATE}
                                    isDisabled={biTiers.length > 1} value={birate.bifuturerate} onChange={this.handleChange} />
                            </Grid>
                            <Grid item xs={6}>
                                {biTierComponent}
                            </Grid>
                        </Grid>
                    </div>
                </form>
            </ModalDialog>
        );
    }

    handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined, isValid: boolean) => {
        if (event) {
            this.setState(prevState => {
                const { id, value } = event.target;
                let birate: any = Object.assign({}, prevState.birate);
                birate[id] = value;

                let formValidationState = prevState.formValidationState;
                const index = formValidationState.findIndex(p => p.name === id);
                formValidationState[index] = { name: id, isValid: isValid };

                return { birate, formValidationState };
            });
        }
    }

    handleSelectChange = (selected: SelectModel, targetId?: string, isValid?: boolean) => {
        if (targetId) {
            this.setState(prevState => {
                let birate: any = Object.assign({}, prevState.birate);
                birate[targetId] = selected.value;

                let formValidationState = prevState.formValidationState;
                const index = formValidationState.findIndex(p => p.name === targetId);
                formValidationState[index] = { name: targetId, isValid: isValid ? isValid : false };

                return { birate, formValidationState };
            });
        }
    }

    handleMultiSelectChange = (selected: SelectModel[], targetId?: string, isValid?: boolean) => {
        if (targetId) {
            const values = selected.map(p => p.value).join(',');

            this.setState(prevState => {
                let birate: any = Object.assign({}, prevState.birate);
                birate[targetId] = values;

                let formValidationState = prevState.formValidationState;
                const index = formValidationState.findIndex(p => p.name === targetId);
                formValidationState[index] = { name: targetId, isValid: isValid ? isValid : false };

                return { birate, formValidationState };
            });
        }
    }

    handleSubmit = () => {
        const { birate } = this.state;
        this.props.onSubmit(birate);
    }
}

export default BIRateForm;
