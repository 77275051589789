import React from 'react';
import { AuthProviderService } from '../../Services';

interface Props { }
interface State { }
class AuthLogin extends React.Component<Props, State> {
    async componentDidMount() {
        const isLoggedIn = await AuthProviderService.isLoggedIn();

        if (!isLoggedIn) {
            AuthProviderService.login();
        }
    }

    render() {
        return "Logging in";
    }
}

export default AuthLogin;