import React from "react";
import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableBody
} from "@material-ui/core";

import { FMVDashboardTableModel } from "../../../Models";
import TableHeaderCell from "../../../Components/Table/TableHeaderCell";
import TableBodyCell from "../../../Components/Table/TableBodyCell";
import StyledTableRow from "../../../Components/Table//StyledTableRow";


interface Props {
    groupTierData: FMVDashboardTableModel[];
}
interface State { }
class DashboardTable extends React.Component<Props, State> {
    render() {
        const { groupTierData } = this.props;

        return (
            <TableContainer component={Paper} style={{height: 270}}>
                <Table>
                    <TableHead style={{ backgroundColor: '#EEEEEE' }}>
                        <TableRow>
                            <TableHeaderCell label="PPQ Year" />
                            <TableHeaderCell label="Total PPQ's" />
                            <TableHeaderCell label="Exceptions Approved" />
                            <TableHeaderCell label="Exceptions Denied" />
                            <TableHeaderCell label="Exceptions as % of PPQ's Submitted" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {groupTierData.map((row, i) => (
                            <StyledTableRow key={i}>
                                <TableBodyCell label={row.ppqYear} />
                                <TableBodyCell label={row.totalPPQ} />
                                <TableBodyCell label={row.exceptionApproved} />
                                <TableBodyCell label={row.exceptionDenied} />
                                <TableBodyCell label={row.ppqSubmittedPercent} />
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
}

export default DashboardTable;