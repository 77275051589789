import React from "react";
import { Checkbox, FormControl, FormControlLabel, Grid, IconButton } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { FacultyQuestDTO, ContentfulQuestion, ContentfulAnswer } from "../../../Models";
import { EventType } from "../../../Common/Enums";
import LightTooltip from "../../Tooltip/LightTooltip";

interface Props {
    question: ContentfulQuestion,
    answers: ContentfulAnswer[];
    selectedAnswer?: FacultyQuestDTO;
    disabled: boolean;
    isFR: boolean;
    onChange: ((selected: FacultyQuestDTO, eventType: EventType) => void);
}
interface State { }
class SingleCheckbox extends React.Component<Props, State> {
    public static defaultProps = {
        disabled: false
    };

    render() {
        const { answers, selectedAnswer, isFR } = this.props;

        let infoIconStyle: React.CSSProperties = { color: '#FFA49D' };
        let checkboxStyle: React.CSSProperties = { color: '#EE3A29' };

        if (isFR) {
            infoIconStyle.color = '#669CDD';
            checkboxStyle.color = '#004693';
        }

        return (
            <FormControl>
                <Grid container alignItems="center" spacing={2}>
                    {answers.map((answer, i) => {
                        const label = answer.answerText ? answer.answerText : answer.answerOptions;

                        return (
                            <Grid item key={`SC-${i}`}>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item>
                                        <FormControlLabel control={
                                            <Checkbox
                                                value={answer.answerOptions}
                                                checked={selectedAnswer?.answer === answer.answerOptions}
                                                onChange={this.handleChange}
                                                style={checkboxStyle}
                                            />}
                                            label={label}
                                        />
                                    </Grid>
                                    <Grid item data-html2canvas-ignore>
                                        {answer.helpText ? <LightTooltip title={answer.helpText}>
                                            <IconButton>
                                                <FontAwesomeIcon icon={faInfoCircle} size="xs" style={infoIconStyle} />
                                            </IconButton>
                                        </LightTooltip> : null}
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </FormControl>
        );
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { question, answers } = this.props;
        const value = (event.target as HTMLInputElement).value;

        const selectedAnswer = answers.find(p => p.answerOptions === value);

        if (selectedAnswer) {
            const answerModel: FacultyQuestDTO = {
                id: 0,
                facultyid: 0,
                questionno: selectedAnswer.qid,
                question: question.question,
                answer: selectedAnswer.answerOptions,
                answerid: selectedAnswer.id,
                points: selectedAnswer.point,
                createdby: '',
                createdon: '',
            };

            this.props.onChange(answerModel, EventType.ADD);
        }
    }
}

export default SingleCheckbox;