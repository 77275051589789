import { QuestionnaireDTO, ContentfulQuestion, FacultyQuestDTO, LogicalAnswerModel, ContentfulAnswer } from "../Models";
import { FieldType, ConditionType, EventType, AnswerConditionType } from "./Enums";

class QuestionnaireUtils {
    static validateQuestionnaire(questionnaire: QuestionnaireDTO | undefined) {
        let isValid = false;

        if (questionnaire) {
            const { questionnaire: questionAnswers, facultyQuests } = questionnaire;

            const unAnsweredQuestions: ContentfulQuestion[] = [];
            questionAnswers.forEach(qa => {
                if (qa.responseType !== FieldType[FieldType.Logicstep]) {
                    const isConditional = qa.condition === 'json' ? false : qa.condition && qa.qid;
                    const qaFacultyQuests = facultyQuests.filter(p => p.questionno === qa.id);
                    const isQuestionAnswered = qaFacultyQuests.length > 0;
                    let isQuestionRequired = true;

                    if (qa.config && qa.config.isRequired === false) {
                        isQuestionRequired = false;
                    }

                    if (isConditional) {
                        const conditionalFacultyQuests = facultyQuests.filter(p => p.questionno === qa.qid);
                        const isConditionalQuestionAnswered = conditionalFacultyQuests.length > 0;

                        if (isConditionalQuestionAnswered) {
                            const isAnswerMatch = QuestionnaireUtils.isConditionalAnswerMatch(qa, questionnaire);

                            if (isAnswerMatch && !isQuestionAnswered) {
                                unAnsweredQuestions.push(qa);
                            }
                        }
                    } else {
                        const isJsonConditionMatch = this.isJsonConditionMatch(qa, questionnaire);

                        if (isJsonConditionMatch === null || isJsonConditionMatch === true) {
                            if (isQuestionRequired) {
                                if (!isQuestionAnswered) {
                                    unAnsweredQuestions.push(qa);
                                }
                            }
                        }
                    }

                    if (qa.responseType === FieldType[FieldType.Textbox]) {
                        if (isQuestionAnswered) {
                            const isUnanswered = unAnsweredQuestions.some(p => p.id === qa.id);

                            if (!isUnanswered) {
                                if (!qaFacultyQuests[0].answer) {
                                    if(!(qa.config && qa.config.isRequired === false)) {
                                        unAnsweredQuestions.push(qa);    
                                    }
                                }
                            }
                        }
                    }
                }
            });

            if (unAnsweredQuestions.length < 1) {
                isValid = true;
            }

            if (questionAnswers.length < 1) {
                isValid = false;
            }
        }

        return isValid;
    }

    static getNextQuestions(questionnaire: QuestionnaireDTO, prevAnswerQuestion: FacultyQuestDTO | null): ContentfulQuestion[] {
        let nextQuestions: ContentfulQuestion[] = [];

        if (questionnaire) {
            let { questionnaire: allQuestions } = questionnaire;

            if (prevAnswerQuestion) {
                const currentNextQuestions = allQuestions.filter(p => p.id > prevAnswerQuestion.questionno);

                currentNextQuestions.some(question => {
                    if (question.responseType !== FieldType[FieldType.Logicstep]) {
                        const isConditional = QuestionnaireUtils.isConditionalAnswerMatch(question, questionnaire);

                        if (isConditional === null) {
                            nextQuestions.push(question);
                            return true;
                        } else {
                            if (isConditional) {
                                nextQuestions.push(question);
                                return true;
                            }
                        }
                    }

                    return false;
                });

                const allValidQuestions = allQuestions.filter(q => {
                    const isConditional = QuestionnaireUtils.isConditionalAnswerMatch(q, questionnaire);
                    return isConditional === null || isConditional;
                }).sort((a, b) => a.number - b.number);

                allQuestions.forEach(question => {
                    if (question.responseType !== FieldType[FieldType.Logicstep]) {
                        const isConditional = QuestionnaireUtils.isConditionalAnswerMatch(question, questionnaire);
                        const prevQuestions = allValidQuestions.filter(p => {
                            if (p.numberText) {
                                return (p.id < question.id && p.responseType !== FieldType[FieldType.Logicstep]);
                            } else {
                                return (p.number < question.number && p.responseType !== FieldType[FieldType.Logicstep]);
                            }
                        });

                        if (prevQuestions.length > 0) {
                            const prevQuestion = prevQuestions.reduce((prev, current) => {
                                return (prev.number > current.number) ? prev : current;
                            });

                            const isPreviousQuestionAnswered = questionnaire.facultyQuests.some(p => p.questionno === prevQuestion.id);

                            if (isConditional === null) {
                                if (isPreviousQuestionAnswered) {
                                    nextQuestions.push(question);
                                }
                            } else {
                                if (isConditional && isPreviousQuestionAnswered) {
                                    nextQuestions.push(question);
                                }
                            }
                        }
                    }
                });
            } else {
                nextQuestions.push(allQuestions[0]);
            }

            const isFirstQuestion = nextQuestions.some(p => p.id === allQuestions[0].id);

            if (!isFirstQuestion) {
                nextQuestions.push(allQuestions[0]);
            }
        }

        return nextQuestions;
    }

    static isConditionalAnswerMatch(question: ContentfulQuestion, questionnaire: QuestionnaireDTO): boolean | null {
        const isConditional = question.condition && question.qid;

        if (isConditional) {
            const dependentQuestionAnswers = questionnaire?.facultyQuests.filter(p => p.questionno === question.qid);
            let isAnswerMatch = false;

            if (dependentQuestionAnswers && dependentQuestionAnswers.length > 0) {
                switch (question.condition) {
                    case ConditionType.EQUAL:
                        isAnswerMatch = dependentQuestionAnswers.some(p =>
                            p.answer.includes(question.answers));

                        break;
                    case ConditionType.GREATER_THAN:
                        const expectedAnswer = parseInt(question.answers);
                        const givenAnswer = parseInt(dependentQuestionAnswers[0].answer);
                        isAnswerMatch = givenAnswer > expectedAnswer;

                        break;
                    case ConditionType.IN:
                        const expectedAnswers = question.answers.split(',');
                        let isAnyMatch = false;

                        expectedAnswers.forEach(expectedAnswer => {
                            const isMatch = dependentQuestionAnswers.some(p => p.answer === expectedAnswer.trim());
                            if (isMatch) {
                                isAnyMatch = true;
                            }
                        });

                        isAnswerMatch = isAnyMatch;

                        break;
                }
            }

            return isAnswerMatch;
        }

        return this.isJsonConditionMatch(question, questionnaire);
    }

    static getLogicalAnswers(questionnaire: QuestionnaireDTO, answer: FacultyQuestDTO) {
        const logicalQuestions = questionnaire.questionnaire.filter(p =>
            p.qid === answer.questionno && p.responseType === FieldType[FieldType.Logicstep]);

        const logicalAnswers: { eventType: EventType, facultyQuestDTO: FacultyQuestDTO }[] = [];

        logicalQuestions.forEach(q => {
            q.questionAnswers.forEach(qa => {
                const logicalAnswer: LogicalAnswerModel = JSON.parse(qa.answerOptions);

                const question = questionnaire.questionnaire.find(p => p.id === logicalAnswer.qid);
                const answer = question?.questionAnswers.find(p =>
                    p.answerOptions.includes(logicalAnswer.answer));

                if (question && answer) {
                    const isConditional = q.condition && q.qid;

                    let isAnswerMatch = true;

                    if (isConditional) {
                        isAnswerMatch = questionnaire.facultyQuests.some(p =>
                            p.questionno === q.qid && p.answer === q.answers);
                    }

                    let answerModel: FacultyQuestDTO = {
                        id: 0,
                        facultyid: questionnaire.facultyId,
                        questionno: answer.qid,
                        question: question.question,
                        answer: answer.answerOptions,
                        answerid: answer.id,
                        points: answer.point,
                        createdby: '',
                        createdon: '',
                    };

                    logicalAnswers.push({ eventType: isAnswerMatch ? EventType.ADD : EventType.REMOVE, facultyQuestDTO: answerModel });
                }
            });
        });

        return logicalAnswers;
    }

    static getAnswersToDelete(questionnaire: QuestionnaireDTO, answer: FacultyQuestDTO, eventType: EventType) {
        const answers = questionnaire.facultyQuests.filter(p => {
            if (answer.questionno === 1) {
                if (p.questionno > answer.questionno) {
                    return true;
                }
            }

            const question = questionnaire.questionnaire.find(q => q.id === p.questionno);

            if (question?.qid === answer.questionno) {
                return true;
            }

            return false;
        });

        const question = questionnaire.questionnaire.find(q => q.id === answer.questionno);

        if (question) {
            const conditionalAnswers = question.questionAnswers.filter(qa => qa.condition === AnswerConditionType.REMOVEOTHER);
            const isAnyAnswerConditional = conditionalAnswers.length > 0;
            const questionAnswers = questionnaire.facultyQuests.filter(fq => fq.questionno === question.id);
            const isCurrentAnswerConditional = conditionalAnswers.some(ca => ca.id === answer.answerid);

            if (eventType === EventType.ADD) {
                if (isCurrentAnswerConditional) {
                    conditionalAnswers.forEach(ca => {
                        const answered = questionAnswers.find(qa => qa.answerid === ca.id);

                        if (answered) {
                            questionAnswers.forEach(qa => {
                                if (qa.answerid !== answered.answerid) {
                                    answers.push(qa);
                                }
                            });
                        }
                    });
                } else {
                    if (isAnyAnswerConditional) {
                        conditionalAnswers.forEach(ca => {
                            questionAnswers.forEach(qa => {
                                if (qa.answerid === ca.id) {
                                    answers.push(qa);
                                }
                            });
                        });
                    }
                }
            }
        }

        return answers;
    }

    static showConfirmation(question: ContentfulQuestion, answer: FacultyQuestDTO, questionnaire: QuestionnaireDTO): boolean {
        let showDialog = false;
        let isCurrentAnswered = false;
        let isAnswerMatch = false;

        const questionAnswers = questionnaire.facultyQuests.filter(p => p.questionno === question.id);
        const questionsToReset = questionnaire.questionnaire.filter(p => p.qid === question.id);
        const isRemoveOther = question.questionAnswers.some(p => p.answerOptions === answer.answer && p.condition === AnswerConditionType.REMOVEOTHER);
        const isParentQuestion = questionnaire.questionnaire.some(p => p.qid === question.id);
        const isParentQuestionAnswered = questionAnswers.length > 0;

        switch (question.responseType) {
            case FieldType[FieldType.MultiCheckbox]:
            case FieldType[FieldType.MultiDropdown]:
                isCurrentAnswered = questionAnswers.some(p => p.answer === answer.answer);

                questionsToReset.forEach((resetQuestion) => {
                    switch (resetQuestion.condition) {
                        case ConditionType.EQUAL:
                            if (!isAnswerMatch) {
                                isAnswerMatch = questionAnswers.some(p => p.answer.includes(resetQuestion.answers));
                            }
                            break;
                        case ConditionType.GREATER_THAN:
                            const expectedAnswer = parseInt(resetQuestion.answers);
                            const givenAnswer = parseInt(questionAnswers[0].answer);

                            if (!isAnswerMatch) {
                                isAnswerMatch = givenAnswer > expectedAnswer;
                            }
                            break;
                        case ConditionType.IN:
                            const expectedAnswers = resetQuestion.answers.split(',');
                            let isAnyMatch = false;

                            expectedAnswers.forEach(expectedAnswer => {
                                const isMatch = questionAnswers.some(p => p.answer === expectedAnswer.trim());
                                if (isMatch) {
                                    isAnyMatch = true;
                                }
                            });

                            isAnswerMatch = isAnyMatch;

                            break;
                        case ConditionType.JSON:
                            console.log('showConfirmation', question.config);
                            break;
                    }
                });
                break;
            default:
                if (isParentQuestion && isParentQuestionAnswered) {
                    questionsToReset.forEach((resetQuestion) => {
                        switch (resetQuestion.condition) {
                            case ConditionType.EQUAL:
                                if (!isAnswerMatch) {
                                    isAnswerMatch = resetQuestion.answers === questionAnswers[0].answer;
                                }
                                break;
                            case ConditionType.GREATER_THAN:
                                const expectedAnswer = parseInt(resetQuestion.answers);
                                const givenAnswer = parseInt(questionAnswers[0].answer);

                                if (!isAnswerMatch) {
                                    isAnswerMatch = givenAnswer > expectedAnswer;
                                }
                                break;
                            case ConditionType.IN:
                                const expectedAnswers = resetQuestion.answers.split(',');
                                let isAnyMatch = false;

                                expectedAnswers.forEach(expectedAnswer => {
                                    const isMatch = questionAnswers[0].answer === expectedAnswer.trim();
                                    if (isMatch) {
                                        isAnyMatch = true;
                                    }
                                });

                                isAnswerMatch = isAnyMatch;

                                break;
                            case ConditionType.JSON:
                                console.log('showConfirmation-default', question.config);
                                break;
                        }
                    });

                    if (isAnswerMatch) {
                        showDialog = true;
                    }
                }
                break;
        }

        if (isRemoveOther && isAnswerMatch) {
            showDialog = true;
        }

        if (isParentQuestion && isCurrentAnswered) {
            showDialog = true;
        }

        if (question.id === 1 && isParentQuestionAnswered) {
            showDialog = true;
        }

        return showDialog;
    }

    static isJsonConditionMatch(question: ContentfulQuestion, questionnaire: QuestionnaireDTO) {
        if (question.condition && question.condition === ConditionType.JSON) {
            if (question.config?.jsonCondition) {
                const isMatches: any[] = [];
                question.config.jsonCondition.forEach(rules => {
                    rules.forEach(rule => {
                        const facultyQuests = questionnaire.facultyQuests.filter(p => p.questionno === rule.qid);
                        let matchAnswer: any = null;

                        if (questionnaire) {
                            switch (rule.condition) {
                                case ConditionType.EQUAL:
                                    matchAnswer = facultyQuests.find(p => p.answer === rule.answer);

                                    if (matchAnswer) {
                                        isMatches.push({ logic: 'and', value: true });
                                    } else {
                                        isMatches.push({ logic: 'and', value: false });
                                    }
                                    break;
                                case ConditionType.NOT_EQUAL:
                                    matchAnswer = facultyQuests.find(p => p.answer === rule.answer);

                                    if (matchAnswer) {
                                        isMatches.push({ logic: 'and', value: false });
                                    } else {
                                        isMatches.push({ logic: 'and', value: true });
                                    }
                                    break;
                            }
                        }
                    });
                });

                const result = isMatches.reduce((c1, c2) => ({
                    condition: c2.logic,
                    value: c1.logic === 'and' ? c1.value && c2.value : c1.value || c2.value,
                }));

                return result.value;
            }
        }

        return null;
    }

    static getAnswerText(answerOptions: string, contentfulAnswers: ContentfulAnswer[]) {
        const answers = answerOptions.split(',');
        const answerTexts: string[] = [];

        answers.forEach(ans => {
            const cAnswer = contentfulAnswers.find(p => p.answerOptions === ans);

            if (cAnswer && cAnswer.answerText) {
                answerTexts.push(cAnswer.answerText);
            } else {
                answerTexts.push(ans);
            }
        });

        return answerTexts.join();
    }
}

export default QuestionnaireUtils;