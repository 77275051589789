import React from 'react';

import CommitteeMember from "./CommitteeMemberManagement";
import { CommitteeMemberManagementDTO } from "../../../../Models";


interface Props {
    CommitteeMembersData: CommitteeMemberManagementDTO[]
}
interface State {
    CommitteeDTO: CommitteeMemberManagementDTO;
}

class CommitteeMemberManagement extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);
        this.state = {
            CommitteeDTO: {} as CommitteeMemberManagementDTO,
        }
    }

    

    render() {
        const { CommitteeMembersData } = this.props;
        const { CommitteeDTO } = this.state;
        return (
            <>
                <CommitteeMember CommitteeMembersData={CommitteeMembersData} CommitteeMember={CommitteeDTO} />
            </>

        )
    }
}

export default CommitteeMemberManagement;