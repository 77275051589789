import React from 'react';
import { Card, CardContent, Typography, Grid } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import IconButton from '../Button/IconButton';
import FileUploadModel from './FileUploadModel';

interface Props {
    files: FileUploadModel[],
    isFR: boolean;
    showDelete: boolean;
    isDownload: boolean;
    onDownload: ((file: FileUploadModel) => void);
    onDelete: ((file: FileUploadModel) => void);
}
interface State { }
class FileUploadCard extends React.Component<Props, State> {
    public static defaultProps = {
        isFR: false,
        showDelete: true,
        isDownload: true,
    };

    render() {
        const { files, isFR, showDelete, isDownload } = this.props;

        return (
            <Grid container spacing={2}>
                {files.map((file, i) => {
                    return (
                        <Grid key={`FU-${i}`} item>
                            <Card style={{ border: '1px solid #DDDDDD', borderRadius: '6px', opacity: 1 }}>
                                <CardContent>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="space-between"
                                        alignItems="center"
                                    >
                                        <Grid item data-html2canvas-ignore>
                                            <FontAwesomeIcon icon={faCheckCircle} size="lg" color="#8bc34a" style={{ paddingRight: 10 }} />
                                        </Grid>
                                        <Grid item>
                                            <Typography style={{ fontSize: 16, fontWeight: 400, color: '#666666', cursor: isDownload ? 'pointer' : 'default' }} noWrap
                                                onClick={() => this.onDownloadClick(file)}>
                                                {this.getFormattedFileName(file)}
                                            </Typography>
                                        </Grid>
                                        {showDelete ? <Grid item data-html2canvas-ignore>
                                            <IconButton isFR={isFR} iconType="DeleteDialog" onClick={() => this.onDeleteClick(file)} />
                                        </Grid> : null}
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                })}
            </Grid>
        );
    }

    getFormattedFileName = (file: FileUploadModel) => {
        if (file.name.length > 20) {
            return `${file.name.substring(0, 20)}... (${file.sizeInKB} KB)`;
        }

        return `${file.name} (${file.sizeInKB} KB)`;
    }

    onDownloadClick(file: FileUploadModel) {
        const { isDownload } = this.props;

        if (isDownload) {
            this.props.onDownload(file);
        }
    }

    onDeleteClick(file: FileUploadModel) {
        this.props.onDelete(file);
    }
}

export default FileUploadCard;
