import React from "react";
import {  Grid } from "@material-ui/core";

import {
  
  CustomEventHandlerService,
  FMVRateExceptionService,
} from "../../Services";

import { FileType, DocumentType, CustomEventType } from "../../Common/Enums";

import FileUploadModel from "../../Components/File/FileUploadModel";
import FileUploadCard from "../../Components/File/FileUploadCard";
import Utils from "../../Common/Utils";
interface Props {
  request_id: string;
}
interface State {
  files: FileUploadModel[];
}
class DocumentsAttached extends React.Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);

    this.state = {
      files: [],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    const { files } = this.state;
    

    return (
    
          <Grid item md={12}>
            <FileUploadCard
              files={files}
              onDelete={this.handleFileDelete}
              onDownload={this.handleFileDownload}
            />
          </Grid>
       
    );
  }

  loadData = async () => {
    const { request_id } = this.props;

    if (request_id) {
      const exceptionFiles = await FMVRateExceptionService.getAllFile(
        request_id,
        DocumentType.REQUESTER
      );
      //console.log("exceptionfiles",exceptionFiles)
      const files: FileUploadModel[] = [];

      exceptionFiles
        .filter((file) => file.documenttype === DocumentType.REQUESTER)
        .forEach((exceptionFile) => {
          const file = new FileUploadModel();

          file.id = exceptionFile.id;
          file.name = exceptionFile.filename;
          file.facultyId = exceptionFile.facultyid;
          file.type =
            FileType[exceptionFile.documenttype as keyof typeof FileType];
          file.s3filekey = exceptionFile.s3filekey;
          file.sizeInKB = exceptionFile.fileSizeInKB ?? 0;

          files.push(file);
        });

      this.setState({ files });
    }
  };

  handleFileDownload = async (file: FileUploadModel) => {
    const downloadURL = await FMVRateExceptionService.getFile(
      file.s3filekey,
      file.type
    );
    Utils.openUrlParent(downloadURL);
  };

  handleFileDelete = async (file: FileUploadModel) => {
    const isSuccess = await FMVRateExceptionService.deleteFile(file, DocumentType.REQUESTER);
    let message = "";

    if (isSuccess) {
      const { files } = this.state;

      const index = files.findIndex(
        (p) => p.name === file.name && p.type === file.type
      );
      files.splice(index, 1);

      this.setState({ files }, () => {
        const fileInput = document.getElementById("otherUpload") as HTMLInputElement;

        if (fileInput) {
            fileInput.value = '';
        }
      });

      message = "File deleted successfully";
      CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, {
        alertId: "fileUpload",
        message,
      });
    } else {
      message = "Failed to delete File";
      CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, {
        alertId: "fileUpload",
        message,
      });
    }
  };
}

export default DocumentsAttached;