import React from 'react';
import { Box, Grid } from '@material-ui/core';

import { SpecialtyDTO } from "../../../Models";
import TextInputField from '../../../Components/TextInput/TextInputField';
import RedButton from '../../../Components/Button/RedButton';
import WhiteButton from '../../../Components/Button/WhiteButton';

interface Props {
    specialty: SpecialtyDTO;
    onCancelClick: ((event: React.MouseEvent<HTMLButtonElement>) => void);
    onSubmit: ((specialty: SpecialtyDTO) => void);
}
interface State {
    specialty: SpecialtyDTO;
    formValidationState: { name: string, isValid: boolean }[];
}
class SpecialtyForm extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);

        let isValid = false;

        if (props.specialty && props.specialty.specialtyid) {
            isValid = true;
        }

        this.state = {
            specialty: props.specialty,
            formValidationState: [
                { name: 'specialty', isValid },
            ],
        };
    }

    render() {
        const { specialty, formValidationState } = this.state;
        const { onCancelClick } = this.props;

        const isFormNotValid = formValidationState.some(p => p.isValid === false);

        return (
            <Box>
                <form noValidate autoComplete="off">
                    <div>
                        <Grid
                            container spacing={7}
                            alignItems="center"
                            justify="flex-start"
                            style={{ padding: 10 }}
                        >
                            <Grid item xs={3}>
                                <TextInputField id="specialty" label="Specialty"
                                    value={specialty.specialty} onChange={this.handleChange} />
                            </Grid>
                            <Grid item>
                                <WhiteButton label="Cancel" onClick={onCancelClick} style={{ marginRight: 20 }} />
                                {isFormNotValid ?
                                    <RedButton disabled={true} label={specialty.specialtyid !== undefined ? 'Update' : 'Add'} onClick={this.handleSubmit} /> :
                                    <RedButton label={specialty.specialtyid !== undefined ? 'Update' : 'Add'} onClick={this.handleSubmit} />
                                }
                            </Grid>
                        </Grid>
                    </div>
                </form>
            </Box>
        );
    }

    handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined, isValid: boolean) => {
        if (event) {
            this.setState(prevState => {
                const { id, value } = event.target;
                let specialty: any = Object.assign({}, prevState.specialty);
                specialty[id] = value;

                let formValidationState = prevState.formValidationState;
                const index = formValidationState.findIndex(p => p.name === id);
                formValidationState[index] = { name: id, isValid: isValid };

                return { specialty, formValidationState };
            });
        }
    }

    handleSubmit = () => {
        const { specialty } = this.state;
        this.props.onSubmit(specialty);
    }
}

export default SpecialtyForm;

