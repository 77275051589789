import { withStyles, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const LightTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: '#FFFFFF',
        borderColor: '#D9D9D9',
        color: '#383838',
        boxShadow: theme.shadows[1],
        fontSize: 16,
    },
}))(Tooltip);

export default LightTooltip;