const currentYear = new Date().getFullYear();

const Data = {
    charts:   [{id: 'exceptionbyhcptype', title: 'Exception by HCP Type', year: currentYear, data: [ {name: 'Other Advanced Degree', value: '1', color:'red'},
{name: 'Nurse or Allied Health Provider', value: '4', color: '#BD56A5'},
{name: 'Pharmacist', value: '1', color: '#95D0EC'},
{name: 'Physician/Physician Researcher', value: '4', color: '#D1C4F5'},
{name: 'PhD/Doctoral degree holder', value: '17', color: 'blue'},
{name: 'Advanced Practice Provider', value: '6', color: 'grey'},]},
{id: 'exceptionbyhcptype', title: 'Exception by HCP Type', year: currentYear-1, data: [ {name: 'Other Advanced Degree', value: '18', color:'red'},
{name: 'Nurse or Allied Health Provider', value: '4', color: '#BD56A5'},
{name: 'Pharmacist', value: '11', color: '#95D0EC'},
{name: 'Physician/Physician Researcher', value: '9', color: '#D1C4F5'},
{name: 'PhD/Doctoral degree holder', value: '20', color: 'blue'},
{name: 'Advanced Practice Provider', value: '8', color: 'grey'},]},
{id: 'exceptionbyhcptype', title: 'Exception by HCP Type', year: currentYear-2, data: [ {name: 'Other Advanced Degree', value: '7', color:'red'},
{name: 'Nurse or Allied Health Provider', value: '5', color: '#BD56A5'},
{name: 'Pharmacist', value: '1', color: '#95D0EC'},
{name: 'Physician/Physician Researcher', value: '8', color: '#D1C4F5'},
{name: 'PhD/Doctoral degree holder', value: '10', color: 'blue'},
{name: 'Advanced Practice Provider', value: '18', color: 'grey'},]},
{id: 'exceptionbyhcptype', title: 'Exception by HCP Type', year: currentYear-3, data: [ {name: 'Other Advanced Degree', value: '13', color:'red'},
{name: 'Nurse or Allied Health Provider', value: '9', color: '#BD56A5'},
{name: 'Pharmacist', value: '7', color: '#95D0EC'},
{name: 'Physician/Physician Researcher', value: '19', color: '#D1C4F5'},
{name: 'PhD/Doctoral degree holder', value: '12', color: 'blue'},
{name: 'Advanced Practice Provider', value: '17', color: 'grey'},]},
{id: 'exceptionbyhcptype', title: 'Exception by HCP Type', year: currentYear-4, data: [ {name: 'Other Advanced Degree', value: '20', color:'red'},
{name: 'Nurse or Allied Health Provider', value: '2', color: '#BD56A5'},
{name: 'Pharmacist', value: '15', color: '#95D0EC'},
{name: 'Physician/Physician Researcher', value: '3', color: '#D1C4F5'},
{name: 'PhD/Doctoral degree holder', value: '8', color: 'blue'},
{name: 'Advanced Practice Provider', value: '12', color: 'grey'},]},
{id: 'exceptionbyhcptype', title: 'Exception by HCP Type', year: currentYear-5, data: [ {name: 'Other Advanced Degree', value: '11', color:'red'},
{name: 'Nurse or Allied Health Provider', value: '14', color: '#BD56A5'},
{name: 'Pharmacist', value: '5', color: '#95D0EC'},
{name: 'Physician/Physician Researcher', value: '4', color: '#D1C4F5'},
{name: 'PhD/Doctoral degree holder', value: '8', color: 'blue'},
{name: 'Advanced Practice Provider', value: '16', color: 'grey'},]},
{id: 'exceptionbyhcptype', title: 'Exception by HCP Type', year: currentYear-6, data: [ {name: 'Other Advanced Degree', value: '6', color:'red'},
{name: 'Nurse or Allied Health Provider', value: '1', color: '#BD56A5'},
{name: 'Pharmacist', value: '5', color: '#95D0EC'},
{name: 'Physician/Physician Researcher', value: '14', color: '#D1C4F5'},
{name: 'PhD/Doctoral degree holder', value: '10', color: 'blue'},
{name: 'Advanced Practice Provider', value: '19', color: 'grey'},]},
// {id: 'exceptionbybussinessunit', title: 'Exception by Bussiness Unit', data: [{name: 'Other Advanced Degree', value: '1', color:'red'},
// {name: 'Nurse or Allied Health Provider', value: '4', color: '#BD56A5'},
// {name: 'Pharmacist', value: '1', color: '#95D0EC'},
// {name: 'Physician/Physician Researcher', value: '4', color: '#D1C4F5'},
// {name: 'PhD/Doctoral degree holder', value: '17', color: ''},
// {name: 'Advanced Practice Provider', value: '6', color: 'grey'},]},
// {id: 'exceptionbybrand', title: 'Exception by Brand', data: [{name: 'Other Advanced Degree', value: '1', color:'red'},
// {name: 'Nurse or Allied Health Provider', value: '4', color: '#BD56A5'},
// {name: 'Pharmacist', value: '1', color: '#95D0EC'},
// {name: 'Physician/Physician Researcher', value: '4', color: '#D1C4F5'},
// {name: 'PhD/Doctoral degree holder', value: '17', color: ''},
// {name: 'Advanced Practice Provider', value: '6', color: 'grey'},]},
],

groupTierHCPData: [
    {"ppqYear" : "2022", "totalPPQ": "50", "exceptionApproved": "20", "exceptionDenied": "5", "ppqSubmittedPercent": "20%"},
    {"ppqYear" : "2022", "totalPPQ": "50", "exceptionApproved": "20", "exceptionDenied": "5", "ppqSubmittedPercent": "20%"}
]
}


export default Data;

// const chartsData= [{id: 'CurrentPPQbyHCPType', title: 'Current PPQ by HCP Type', data: [{name: 'Other Advanced Degree', value: '1', color:'red'}],
// {id: 'RateOverrideException', title: 'Rate Override Exception', data: [{name: 'Other Advanced Degree', value: '1', color:'red'}]},
// {id: 'PPQTakenNotTakenByHCP', title: 'PPQ taken / not taken by HCP’s', data: [{name: 'Other Advanced Degree', value: '1', color:'red'}]},
// {id: 'SubmittedSaved', title: 'Submitted / Saved / Exception', data: [{name: 'Other Advanced Degree', value: '1', color:'red'}]
// }]

// export default chartsData;