import React from "react";
import { FormControl, FormControlLabel, Grid, Radio } from "@material-ui/core";

import RadioModel from "./RadioModel";

interface Props {
  values: RadioModel[];
  selectedValue?: string | number;
  disabled: boolean;
  isRequired?: boolean;
  onChange: (selected: RadioModel) => void;
}
interface State {}
class RadioButton extends React.Component<Props, State> {
  public static defaultProps = {
    disabled: false,
    isRequired: true,
  };

  render() {
    const { values, selectedValue, isRequired } = this.props;

    const radioStyle: React.CSSProperties = { color: "#EE3A29" };

    return (
      <FormControl>
        <Grid container direction="row" alignItems="center" spacing={3}>
          {values.map((value, i) => {
            return (
              <Grid item key={`RB-${i}`}>
                <FormControlLabel
                  control={
                    <Radio
                      required={isRequired}
                      value={value.value}
                      checked={selectedValue === value.value}
                      onChange={this.handleChange}
                      style={radioStyle}
                    />
                  }
                  label={value.text}
                />
              </Grid>
            );
          })}
        </Grid>
      </FormControl>
    );
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { values } = this.props;

    const selectedValue = values.find((p) => p.value === event.target.value);

    if (selectedValue) {
      this.props.onChange(selectedValue);
    }
  };
}

export default RadioButton;