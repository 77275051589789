import React from "react";
import { Checkbox, TextField, Chip, WithStyles, withStyles, Theme } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTimesCircle as CancelIcon,
    faTimes as CloseIcon,
    faChevronDown as ExpandMoreIcon,
} from '@fortawesome/free-solid-svg-icons';
import {
    faSquare as CheckBoxOutlineBlankIcon,
    faCheckSquare as CheckBoxIcon,
} from '@fortawesome/free-regular-svg-icons';

import { ValidationType } from "../../Common/Enums";
import ValidationUtils from "../../Common/ValidationUtils";
import SelectModel from "./SelectModel";

const styles = (theme: Theme) => ({
    notchedOutline: {
        border: '1px solid #EE3A29',
        borderRadius: '27px',
    },
});

interface Props extends WithStyles<typeof styles> {
    id?: string;
    label: string;
    selectedValues?: string[];
    values: SelectModel[];
    isRounded: boolean;
    isRequired: boolean;
    onChange: ((selected: SelectModel[], targetId?: string, isValid?: boolean) => void);
}
interface State {
    helperText: string;
    values: (string | number)[];
}
class MultiAutoComplete extends React.Component<Props, State> {
    public static defaultProps = {
        disabled: false,
        isRounded: false,
        isRequired: true,
    };

    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            helperText: '',
            values: props.selectedValues ? props.selectedValues : [],
        };
    }

    render() {
        const { id, label, values, isRounded, classes } = this.props;
        const { values: selectedValues, helperText } = this.state;

        const labelId = id ? id : label;

        const selected = values.filter(v => selectedValues.includes(v.value as string)) ?? null;

        let checkboxStyle: React.CSSProperties = { color: '#EE3A29', marginRight: 8 };
        let arrowDropDownIconStyle: React.CSSProperties = { color: '#EE3A29' };
        let closeIconStyle: React.CSSProperties = { color: '#EE3A29' };
        let cancelIconStyle: React.CSSProperties = { color: '#EE3A29' };
        let chipStyle: React.CSSProperties = { borderColor: '#EE3A29' };

        return (
            <Autocomplete
                multiple
                limitTags={2}
                id={labelId}
                options={values}
                getOptionLabel={(option) => option.text}
                value={selected}
                renderTags={(value: SelectModel[], getTagProps) =>
                    value.map((option: SelectModel, index: number) => (
                        <Chip variant="outlined" label={option.text} {...getTagProps({ index })}
                            style={chipStyle}
                            deleteIcon={<FontAwesomeIcon icon={CancelIcon} size="lg" style={cancelIconStyle} />} />
                    ))
                }
                renderOption={(option, { selected }) => (
                    <React.Fragment>
                        <Checkbox
                            icon={<FontAwesomeIcon icon={CheckBoxOutlineBlankIcon} />}
                            checkedIcon={<FontAwesomeIcon icon={CheckBoxIcon} />}
                            style={checkboxStyle}
                            checked={selected}
                        />
                        {option.text}
                    </React.Fragment>
                )}
                renderInput={(params) => (
                    <TextField {...params} label={label} variant="outlined" helperText={helperText}
                        InputLabelProps={{
                            style: { marginLeft: 5 }
                        }}
                        InputProps={
                            {
                                ...params.InputProps,
                                ...{
                                    classes: isRounded ? classes : undefined,
                                }
                            }}
                    />
                )}
                onChange={this.handleChange}
                popupIcon={<FontAwesomeIcon icon={ExpandMoreIcon} size="xs" style={arrowDropDownIconStyle} />}
                closeIcon={<FontAwesomeIcon icon={CloseIcon} size="xs" style={closeIconStyle} />}
            />
        );
    }

    handleChange = (event: React.ChangeEvent<{}>, selectedValues: SelectModel[]) => {
        const { id, isRequired } = this.props;
        const values = selectedValues.map(p => p.value);

        this.setState({ values, helperText: '' }, () => {
            if (isRequired) {
                const validationResult = ValidationUtils.validate(ValidationType.REQUIRED, this.state.values);

                this.setState({ helperText: validationResult.isValid ? '' : validationResult.message }, () => {
                    this.props.onChange(selectedValues, id, validationResult.isValid);
                });
            } else {
                this.props.onChange(selectedValues, id, true);
            }
        });
    }
}

export default withStyles(styles)(MultiAutoComplete);