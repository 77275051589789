import React from "react";
import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Divider } from "@material-ui/core";

interface Props {
    isOpen: boolean;
    title: string;
    dialogAction?: JSX.Element;
    dialogWidth: DialogProps["maxWidth"];
    onClose: (() => void);
}
interface State { }
class ModalDialog extends React.Component<Props, State> {
    public static defaultProps = {
        isOpen: false,
        title: 'Dialog Modal',
        dialogWidth: 'lg',
    };

    render() {
        const { isOpen, title, onClose, children, dialogAction, dialogWidth } = this.props;

        return (
            <Dialog open={isOpen} fullWidth={true} maxWidth={dialogWidth} onClose={onClose}>
                <DialogTitle>{title}</DialogTitle>
                <Divider orientation="horizontal" style={{ marginBottom: 10, marginLeft: 20, marginRight: 20 }} />
                <DialogContent>
                    {children}
                </DialogContent>
                <DialogActions>
                    {dialogAction}
                </DialogActions>
            </Dialog>
        );
    }
}

export default ModalDialog;