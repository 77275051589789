import React, { DragEvent } from "react";
import { Grid, Typography, Box } from "@material-ui/core";

import FileUploadModel from './FileUploadModel';
import { FileType } from "../../Common/Enums";
import IconButton from "../Button/IconButton";
import Alert from "../Alert/Alert";

interface Props {
    disabled: boolean;
    isFR: boolean;
    isDocumentUpload: boolean;
    onChange: ((file: FileUploadModel) => void);
}
interface State {
    isDragOver: boolean;
}
class FileUpload extends React.Component<Props, State> {
    public static defaultProps = {
        disabled: false,
        isFR: false,
        isDocumentUpload: false,
    };
    fileUploadInput: HTMLInputElement | null = null;

    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            isDragOver: false,
        };
    }

    render() {
        const { isDragOver } = this.state;
        const { isFR, isDocumentUpload, disabled } = this.props;

        let border = 0;
        let borderColor = '';
        let color = '#EE3A29';
        let textColor = '#6E6E6E';
        let textSize = 16;
        let hintColor = '#2B2B2B';
        let fileUploadId = 'cvUpload';
        let fileExplorerStyle: React.CSSProperties = { color, cursor: 'pointer' };

        if (isDragOver) {
            border = 1;
            borderColor = isFR ? '#004693' : '#EE3A29';
        }

        if (isFR) {
            color = '#004693';
            textColor = '#EE3A29';
            textSize = 20;
            hintColor = '#1D1D1D';
            fileExplorerStyle = { color: textColor, cursor: 'pointer', fontWeight: 500, fontSize: textSize };
        }

        if (disabled) {
            return <Box border={border}></Box>;
        }

        if (isDocumentUpload) {
            fileUploadId = 'otherUpload';
        }

        return (
            <Box border={border} borderColor={borderColor} p={2} data-html2canvas-ignore
                onDragOver={this.handleDragOver} onDrop={this.handleDrop} onDragLeave={this.handleDragLeave}>
                <Alert id="fileUpload" />
                <Grid container spacing={1}
                    direction="column" alignItems="center" justify="center">
                    <Grid item>
                        <IconButton isFR={isFR} iconType="CloudUpload" onClick={this.handleCloudClick} />
                    </Grid>
                    <Grid item>
                        {!isDragOver ? <Typography style={{ color: textColor, fontWeight: 400, fontSize: textSize }}>
                            {isFR ? <label style={{ color: textColor, fontWeight: 500, fontSize: textSize }}>Drag and drop</label> : 'Drag and drop'} your file here or browse using
                            <input
                                ref={input => this.fileUploadInput = input}
                                accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                style={{ display: 'none' }}
                                id={fileUploadId}
                                type="file"
                                onChange={this.handleChange}
                            />
                            <label htmlFor={fileUploadId} style={fileExplorerStyle}>
                                &nbsp;File Explorer.
                            </label>
                        </Typography> :
                            <Typography style={{ color: textColor, fontWeight: 400, fontSize: textSize }}>
                                Drop your file here
                            </Typography>
                        }
                    </Grid>
                    {!isDocumentUpload ? <Grid item>
                        <Typography style={{ color: hintColor, fontWeight: 400, fontSize: 15 }}>
                            Only .doc, .docx, or .pdf files can be uploaded. Total size of attachment cannot exceed 10MB.
                        </Typography>
                    </Grid> : null}
                </Grid>
            </Box >
        );
    }

    handleAndCreateFileUpload = (file: File) => {
        const fileReader = new FileReader();

        fileReader.readAsDataURL(file);
        fileReader.onload = (e) => {
            const { name, size, type, } = file;

            const fileModel = new FileUploadModel();
            fileModel.name = name;
            fileModel.sizeInKB = Math.round(size / 1024);
            fileModel.sizeInMB = Math.round(size / 1024 / 1024);
            fileModel.type = type as FileType;
            fileModel.ext = type.replace(/(.*)\//g, '');
            fileModel.file = file;

            this.props.onChange(fileModel);
        };
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target } = event;

        if (target.files && target.files.length > 0) {
            this.handleAndCreateFileUpload(target.files[0]);
        }
    }

    handleCloudClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (this.fileUploadInput) {
            this.fileUploadInput.value = '';
            this.fileUploadInput.click();
        }
    }

    handleDragOver = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({ isDragOver: true });
    }

    handleDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({ isDragOver: false }, () => {
            const { files } = event.dataTransfer;

            if (files && files.length > 0) {
                this.handleAndCreateFileUpload(files[0]);
                event.dataTransfer.clearData();
            }
        });
    }

    handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({ isDragOver: false });
    }
}

export default FileUpload;