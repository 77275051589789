import React from "react";
import { Typography } from "@material-ui/core";

import { MessageModel } from "../../Models";

interface Props {
    code: MessageModel;
}
interface State { }
class Message extends React.Component<Props, State> {
    readonly Messages: { code: string, message: string }[] = [
        { code: 'E032', message: 'We apologize there was an error loading the PPQ. Please contact the Faculty Resources Support Center for assistance via telephone at 1-888-899-6937 or via email to SupportCenter@FacultyResources.com' },
        { code: 'E040', message: 'The session has timed out due to inactivity. All responses have been saved. Please click on the PPQ link again to continue.' }
    ];

    render() {
        const { code } = this.props;

        let message = code.message;

        if (!code.message) {
            const localMessage = this.Messages.find(p => p.code === code.code);

            if (localMessage) {
                message = localMessage.message;
            }
        }

        return (
            <Typography style={{ fontSize: 20, fontWeight: 500, padding: 20 }}>
                {message}
            </Typography>
        );
    }
}

export default Message;
