import ProQuestAPIService from "./ProQuestAPIService";
import { AuditDTO } from "../Models";

class AuditService extends ProQuestAPIService {
    async getAll(ppqid: number): Promise<AuditDTO[]> {
        const response = await this.GetAsync<AuditDTO[]>(`audit/getbyid/${ppqid}`);

        let audits: AuditDTO[] = [];

        if (response.isSuccess) {
            audits = response.data;
        }

        return audits;
    }
}

export default new AuditService();