import React from 'react';
import { Card, CardContent } from '@material-ui/core';

import Header from './Header';
import Footer from './Footer';
import BackdropLoader from '../Loader/BackdropLoader';

class Layout extends React.Component {
    render() {
        return (
            <div style={{ backgroundColor: '#F5F5F5' }}>
                <Header />
                <Card style={{ border: '1px solid #DEDEDE', borderRadius: '8px 8px 0px 0px', margin: 20 }}>
                    <CardContent>
                        <BackdropLoader />
                        {this.props.children}
                    </CardContent>
                </Card>
                <Footer />
            </div>
        )
    }
}

export default Layout;