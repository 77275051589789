import React from 'react';
import { Box, Typography, Menu, MenuItem as MuiMenuItem, withStyles, Theme, WithStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import MenuModel from './MenuModel';

const styles = (theme: Theme) => ({
    menuItemRoot: {
        justifyContent: 'center',
    },
    menuItemSelected: {
        justifyContent: 'center',
        backgroundColor: '#B5271A',
    },
    menuPaper: {
        backgroundColor: '#EE3A29',
    },
    menuList: {
        paddingTop: 0,
        paddingBottom: 0,
    },
});

interface Props extends WithStyles<typeof styles> {
    menu: MenuModel;
    onClick: ((menu: MenuModel) => void);
}
interface State {
    anchorEl: any;
}
class MenuItem extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            anchorEl: null,
        }
    }

    render() {
        const { menu, onClick, classes } = this.props;
        const { anchorEl } = this.state;

        const IconComponent = menu.icon;

        let selectedStyle: any = { padding: 20 };
        if (menu.isSelected) {
            selectedStyle = { backgroundColor: '#B5271A', padding: 20 };
        }

        if (menu.subMenus.length < 1) {
            return (
                <Box style={selectedStyle} display="flex" flexDirection="column" justifyContent="center" onClick={() => onClick(menu)}>
                    <Box display="flex" justifyContent="center" style={{ color: '#FFFFFF', marginBottom: 5 }}>
                        <FontAwesomeIcon icon={IconComponent} size="lg" />
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <Typography align="center" style={{ fontSize: 16, fontWeight: 400, color: '#FFFFFF' }}>{menu.name}</Typography>
                    </Box>
                </Box>
            );
        } else {
            return (
                <div>
                    <Box style={selectedStyle} display="flex" flexDirection="column" justifyContent="center" onClick={this.handleMenuExpand}>
                        <Box display="flex" justifyContent="center" style={{ color: '#FFFFFF', marginBottom: 5 }}>
                            <FontAwesomeIcon icon={IconComponent} size="lg" />
                        </Box>
                        <Box display="flex" justifyContent="center">
                            <Typography align="center" style={{ fontSize: 16, fontWeight: 400, color: '#FFFFFF' }}>{menu.name}</Typography>
                        </Box>
                    </Box>
                    <Menu classes={{
                        paper: classes.menuPaper,
                        list: classes.menuList,
                    }} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={this.handleMenuClose}
                        elevation={0}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}>
                        {menu.subMenus.map((subMenu, i) => {
                            return (
                                <MuiMenuItem classes={{
                                    root: subMenu.isSelected ? classes.menuItemSelected : classes.menuItemRoot,
                                }} key={`SubMenu+${i}`}>
                                    <Box display="flex" flexDirection="column" justifyContent="center" onClick={() => this.handleSubMenuClick(subMenu)}>
                                        <Box display="flex" justifyContent="center" style={{ color: '#FFFFFF', marginBottom: 5 }}>
                                            <FontAwesomeIcon icon={subMenu.icon} size="lg" />
                                        </Box>
                                        <Box display="flex" justifyContent="center">
                                            <Typography align="center" style={{ fontSize: 16, fontWeight: 400, color: '#FFFFFF' }}>{subMenu.name}</Typography>
                                        </Box>
                                    </Box>
                                </MuiMenuItem>
                            );
                        })}
                    </Menu>
                </div>
            );
        }
    }

    handleMenuExpand = (event: any) => {
        this.setState({ anchorEl: event.currentTarget });
    }

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    }

    handleSubMenuClick = (subMenu: MenuModel) => {
        this.setState({ anchorEl: null }, () => {
            this.props.onClick(subMenu);
        });
    }
}

export default withStyles(styles)(MenuItem);