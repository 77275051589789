import React from 'react';
import moment from 'moment';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { browserName, browserVersion } from "react-device-detect";

import { FacultyDTO, PPQBrowserDetailsDTO, MessageModel } from '../../Models';
import { HCPFacultyService, HCPTokenService, PPQBrowserDetailsService } from '../../Services';
import Utils from '../../Common/Utils';
import HCPProfile from './HCPProfile';
import ProfessionalProfileQuestionnaire from './ProfessionalProfileQuestionnaire';
import Message from './Message';

interface Props extends RouteComponentProps<any> { }
interface State {
    faculty?: FacultyDTO;
    isLoading: boolean;
    code: MessageModel;
    isPPQSubmit: boolean;
    showQuestionnaire: boolean;
}
class FacultyResource extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            faculty: undefined,
            isLoading: true,
            code: {} as MessageModel,
            isPPQSubmit: false,
            showQuestionnaire: false,
        };
    }

    async componentDidMount() {
        const token = Utils.getQueryParam(this.props.location.search, 'url');
        const code = Utils.getQueryParam(this.props.location.search, 'code');

        if (code) {
            this.setState({ code: { code, message: '' }, isLoading: false });
        } else {
            if (token) {
                try {
                    HCPTokenService.saveToken(token);
                    const tokenModel = HCPTokenService.decodeToken();

                    if (tokenModel) {
                        setTimeout(() => {
                            console.log('Timeout');
                            this.setState({ faculty: undefined, code: { code: 'E040', message: '' }, isLoading: false });
                        }, new Date(tokenModel.exp * 1000).getTime() - new Date().getTime());

                        const faculty = await HCPFacultyService.getByFacultyId(tokenModel.facultyid);


                        if (faculty) {
                            if ('facultyid' in faculty) {
                                await PPQBrowserDetailsService.delete(faculty.facultyid);

                                const browserDetails = {
                                    facultyid: faculty.facultyid,
                                    customerid: faculty.customerid,
                                    osname: this.OS(window),
                                    osversion: this.OS(window),
                                    browsername: browserName,
                                    browserversion: browserVersion,
                                    useragent: navigator.userAgent,
                                    appversion: navigator.appVersion,
                                    platform: navigator.platform,
                                };

                                await PPQBrowserDetailsService.add(browserDetails as PPQBrowserDetailsDTO);

                                this.setState({ faculty, isLoading: false });

                                // browserDetails.facultyid = faculty.facultyid;
                                // browserDetails.customerid = faculty.customerid;
                                // browserDetails.osname = this.OS(window);
                                // browserDetails.osversion = this.OS(window);
                                // browserDetails.browsername = browserName;
                                // browserDetails.browserversion = browserVersion;
                                // browserDetails.useragent = navigator.userAgent;
                                // browserDetails.appversion = navigator.appVersion;
                                // browserDetails.platform = navigator.platform;
                                // browserDetails.createdon = faculty.createdon;
                            }

                            if ('code' in faculty) {
                                this.setState({ code: faculty, isLoading: false });
                            }
                        }
                    }
                } catch (error) {
                    this.setState({ code: { code: 'E032', message: '' }, isLoading: false });
                }
            } else {
                this.setState({ code: { code: 'E032', message: '' }, isLoading: false });
            }
        }
    }

    render() {
        const { faculty, isLoading, code, isPPQSubmit, showQuestionnaire } = this.state;

        if (!isLoading && faculty) {
            return (
                <Grid container direction="column" spacing={2} id="printPPQPDF">
                    <Grid item xs style={{ display: 'none' }} id="ppqPDFDateTimeStamp">
                        <Typography>Submitted On: {moment().format('MM/DD/YYYY/ h:mm a')}</Typography>
                    </Grid>
                    <Grid item xs>
                        <HCPProfile faculty={faculty} isPPQSubmit={isPPQSubmit} onCVChange={this.handleCVChange} />
                    </Grid>
                    <Grid item xs>
                        {showQuestionnaire ? <ProfessionalProfileQuestionnaire faculty={faculty} onSubmitSuccess={this.onQuestionnaireSubmit} /> : null}
                    </Grid>
                </Grid>
            );
        }

        if (!isLoading && code) {
            return <Message code={code} />;
        }

        return null;
    }

    onQuestionnaireSubmit = () => {
        this.setState({ isPPQSubmit: true });
    }

    handleCVChange = (isValid: boolean) => {
        this.setState({ showQuestionnaire: isValid });
    }

    DetectOS = (window: any) => {
        let operatingSystem = "Not known";
        if (window.navigator.appVersion.indexOf("Win") !== -1) {
            operatingSystem = "Windows OS";
        }
        if (window.navigator.appVersion.indexOf("Mac") !== -1) {
            operatingSystem = "MacOS";
        }
        if (window.navigator.appVersion.indexOf("X11") !== -1) {
            operatingSystem = "UNIX OS";
        }
        if (window.navigator.appVersion.indexOf("Linux") !== -1) {
            operatingSystem = "Linux OS";
        }

        return operatingSystem;
    }

    OS = (window: any) => {
        return this.DetectOS(window);
    }
}

export default withRouter(FacultyResource);