import React from "react";
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { WithStyles, withStyles, Theme, TextFieldProps } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const styles = (theme: Theme) => ({
    notchedOutline: {
        border: '1px solid #EE3A29',
        borderRadius: '27px',
    },
});

interface Props extends WithStyles<typeof styles> {
    id?: string;
    label: string;
    dateFormat: string;
    selectedDate: Date | null;
    onChange: ((selectedDate: Date | null, targetId?: string) => void);
    isSquare: boolean;
}
interface State {
    selectedDate: Date | null;
}
class InlineDatePicker extends React.Component<Props, State> {
    public static defaultProps = {
        dateFormat: 'MM/DD/YYYY',
        isSquare: false,
    };

    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            selectedDate: props.selectedDate === undefined ? null : props.selectedDate,
        };
    }

    render() {
        const { id, label, selectedDate, dateFormat, classes, isSquare } = this.props;

        const labelId = id ? id : label;
        let inputProps: TextFieldProps['InputProps'] | undefined = undefined;
        let style: React.CSSProperties = { margin: 0, width: '100%' };

        if (!isSquare) {
            inputProps = { classes };
            style = { margin: 0 };
        }

        return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                    style={style}
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    format={dateFormat}
                    margin="normal"
                    id={labelId}
                    label={label}
                    value={selectedDate}
                    onChange={this.handleChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    InputProps={inputProps}
                    InputLabelProps={{
                        style: { marginLeft: 5 }
                    }}
                    keyboardIcon={<FontAwesomeIcon icon={faChevronDown} size="xs" color="#EE3A29" />}
                />
            </MuiPickersUtilsProvider>
        );
    }

    handleChange = (date: MaterialUiPickersDate, value?: string | null | undefined) => {
        const selectedDate: Date | null = date ? date.toDate() : null;
        this.props.onChange(selectedDate, this.props.id);
    }
}

export default withStyles(styles)(InlineDatePicker);