import React from "react";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
    createStyles,
    Theme,
    withStyles
} from "@material-ui/core";

import { FacultyPPQDataDTO } from "../../../Models";
import IconButton from "../../../Components/Button/IconButton";

const TableHeaderCell: React.FC<{ label: string }> = ({ label }) => {
    return (
        <TableCell style={{ minWidth: 125 }}>
            <Typography style={{ fontSize: 16, fontWeight: 500, color: '#181818' }}>{label}</Typography>
        </TableCell>
    );
};

const TableBodyCell: React.FC<{ label: string | number }> = ({ label }) => {
    return (
        <TableCell>
            <Typography style={{ fontSize: 14, fontWeight: 400, color: '#828282' }}>{label}</Typography>
        </TableCell>
    );
};

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(even)': {
                backgroundColor: '#F9F2F2',
            },
        },
    }),
)(TableRow);

interface Props extends RouteComponentProps<any> {
    ppqExceptions: FacultyPPQDataDTO[];
}
interface State { }
class PPQExceptionTable extends React.Component<Props, State> {
    render() {
        const { ppqExceptions } = this.props;

        return (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead style={{ backgroundColor: '#EEEEEE' }}>
                        <TableRow>
                            <TableHeaderCell label="First Name" />
                            <TableHeaderCell label="Last Name" />
                            <TableHeaderCell label="Faculty ID" />
                            <TableHeaderCell label="HCP Type" />
                            <TableHeaderCell label="Specialty" />
                            <TableHeaderCell label="Exception Type" />
                            <TableHeaderCell label="Action" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ppqExceptions.map((row) => (
                            <StyledTableRow key={row.id}>
                                <TableBodyCell label={row.firstname} />
                                <TableBodyCell label={row.lastname} />
                                <TableBodyCell label={row.facultyid} />
                                <TableBodyCell label={row.hcptype} />
                                <TableBodyCell label={row.specialty} />
                                <TableBodyCell label={row.exceptiontype} />
                                <TableCell><IconButton iconType="Edit" onClick={() => this.handleEditClick(row)} /></TableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }

    handleEditClick = (row: FacultyPPQDataDTO) => {
        this.props.history.push({ pathname: '/questionnairemanagement', state: { facultyId: row.facultyid } });
    }
}

export default withRouter(PPQExceptionTable);