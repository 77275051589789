import React from "react";
import {
    Box,
    Card,
    CardContent,
    Grid,
    Tab,
    Tabs,
    Divider,
    Typography,
    Button,
} from "@material-ui/core";
import moment from "moment";

import { TabModel } from "./TabModel";
import FacultyFilterModel from "./FacultyFilterModel";
import { FacultyDTO, FacultyPPQDataDTO, QuestionnaireDTO, QuestionnaireManagementFacultyDTO, TabFacultyCountDTO } from "../../Models";

import FacultyPanel from "./Faculty/FacultyPanel";
import FacultyInfo from "./Faculty/FacultyInfo";
import HCPProfile from "./Faculty/HCPProfile";
import TieringDetails from "./Faculty/TieringDetails";
import DocumentUpload from "./Faculty/DocumentUpload";
import FacultyFMVInfo from "./Faculty/FacultyFMVInfo";
import ProfessionalProfileQuestionnaire from "./Questionnaire/ProfessionalProfileQuestionnaire";

import TabPanel from "../../Components/Tab/TabPanel";
import SearchFilter from "../../Components/Search/SearchFilter";
import SingleAutoComplete from "../../Components/Select/SingleAutoComplete";
import InlineDatePicker from "../../Components/Date/InlineDatePicker";
import SelectModel from "../../Components/Select/SelectModel";

const TabLabelComponent: React.FC<{ tab: TabModel, count: number, isSelected: boolean }> = ({ tab, count, isSelected }) => {
    return (
        <Grid container alignItems="center" justify="center" spacing={1}>
            <Grid item>
                <Typography style={{ fontSize: 16, fontWeight: 500, color: isSelected ? '#EE3A29' : '#2B2B2B', padding: 5 }}>
                    {tab.name}
                </Typography>
            </Grid>
            <Grid item>
                <div style={{
                    padding: 4,
                    display: "inline-block",
                    backgroundColor: isSelected ? '#EE3A29' : '#C3C3C3',
                    borderRadius: "50%",
                    width: 25,
                    height: 25,
                }}>
                    <Typography style={{ fontSize: 13, fontWeight: 500, color: isSelected ? '#FFFFFF' : '#535353' }}>
                        {count}
                    </Typography>
                </div>
            </Grid>
        </Grid>
    );
};

interface Props {
    currentTab: TabModel;
    tabs: TabModel[];
    onTabChange: ((selectedTab: TabModel) => void);
    hcpTypes: SelectModel[];
    specialties: SelectModel[];
    tabCount?: TabFacultyCountDTO;
    selectedFaculty?: FacultyDTO;
    selectedFacultyPPQData: FacultyPPQDataDTO | null;
    facultyFilter: FacultyFilterModel;
    onFacultyChange: ((selectedFaculty: FacultyDTO) => void);
    onFacultyFilterChange: ((id: string, value: any) => void);
    onFacultyFilterReset: (() => void);
    filterData: QuestionnaireManagementFacultyDTO;
    onSubmitSuccess: ((selectedFaculty: FacultyDTO, message: string) => void);
    showQuestionnaire: boolean;
    onCVChange: ((isValid: boolean) => void);
    onQuestionnaireChange: ((questionnaire: QuestionnaireDTO) => void);
    totalPoints: number;
}
interface State { }
class TabBar extends React.Component<Props, State> {
    render() {
        const {
            tabs, currentTab, tabCount,
            selectedFaculty, selectedFacultyPPQData,
            onFacultyChange, onFacultyFilterChange, onFacultyFilterReset,
            hcpTypes, specialties, facultyFilter,
            filterData, onSubmitSuccess,
            showQuestionnaire, onCVChange,
            totalPoints, onQuestionnaireChange,
        } = this.props;

        const tieredDataCount = tabCount !== undefined ? tabCount.tieredCount : 0;
        const pendingTieringDataCount = tabCount !== undefined ? tabCount.pendingtTieringCount : 0;
        const initiatePPQDataCount = tabCount !== undefined ? tabCount.initiatePPQCount : 0;

        const { faculties, facultyPPQData } = filterData;

        const mainComponent = <div>
            <Grid container alignItems="center" spacing={3} style={{ marginBottom: 5 }}>
                <Grid item xs={3}>
                    <SearchFilter label="Search by Name or ID" value={facultyFilter.searchText}
                        onChange={(searchFilter) => onFacultyFilterChange('searchText', searchFilter.searchText)} />
                </Grid>
                {currentTab.type !== 'InitiatePPQ' ? <>
                    <Grid item xs={2}>
                        <SingleAutoComplete id="hcptype" label="HCP Type" values={hcpTypes} selectedValue={facultyFilter.hcptype}
                            isRounded isRequired={false} onChange={(selected) => onFacultyFilterChange('hcptype', selected.value)} />
                    </Grid>
                    <Grid item xs={2}>
                        <SingleAutoComplete id="specialty" label="Specialty" values={specialties} selectedValue={facultyFilter.specialty}
                            isRounded isRequired={false} onChange={(selected) => onFacultyFilterChange('specialty', selected.value)} />
                    </Grid>
                    <Grid item xs={2}>
                        <InlineDatePicker id="createdon" label="Date" selectedDate={facultyFilter.createdon}
                            onChange={(date) => onFacultyFilterChange('createdon', date)} />
                    </Grid>
                    <Divider orientation="vertical" flexItem style={{ margin: 15 }} />
                    <Grid item>
                        <Typography style={{ fontSize: 16, fontWeight: 500, color: '#5D5D5D' }}>
                            <Button onClick={onFacultyFilterReset} variant="text" style={{ textTransform: 'none' }}>
                                Reset Filters
                            </Button>
                        </Typography>
                    </Grid>
                </> : null}
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <FacultyPanel facultyPPQData={facultyPPQData} faculties={faculties} currentTab={currentTab}
                        selectedFaculty={selectedFaculty} facultyFilter={facultyFilter} onChange={onFacultyChange} />
                </Grid>
                {selectedFaculty !== undefined ? <Grid item xs={9} id='printPPQPDF'>
                    <Card variant="outlined">
                        <CardContent>
                            <Grid container direction="column" spacing={2}>
                                <Grid item style={{ display: 'none' }} id="ppqPDFDateTimeStamp">
                                    <Typography>Submitted On: {moment().format('MM/DD/YYYY/ h:mm a')}</Typography>
                                </Grid>
                                <Grid item data-html2canvas-ignore>
                                    {currentTab.type !== 'InitiatePPQ' ? <FacultyInfo selectedFaculty={selectedFaculty}
                                        facultyPPQData={selectedFacultyPPQData}
                                        currentTab={currentTab} totalPoints={totalPoints} /> : null}
                                </Grid>
                                <Grid item>
                                    <HCPProfile selectedFaculty={selectedFaculty} onCVChange={onCVChange} />
                                </Grid>
                                {showQuestionnaire ? <Grid item>
                                    <ProfessionalProfileQuestionnaire currentTab={currentTab} facultyPPQData={selectedFacultyPPQData}
                                        selectedFaculty={selectedFaculty} onSubmitSuccess={onSubmitSuccess} onQuestionnaireChange={onQuestionnaireChange} />
                                </Grid> : null}
                                {showQuestionnaire && selectedFacultyPPQData && currentTab.type !== 'InitiatePPQ' ? <Grid item data-html2canvas-ignore>
                                    <TieringDetails facultyPPQData={selectedFacultyPPQData} />
                                </Grid> : null}
                                {showQuestionnaire ? <Grid item data-html2canvas-ignore>
                                    {currentTab.type !== 'InitiatePPQ' ? <DocumentUpload selectedFaculty={selectedFaculty} /> : null}
                                </Grid> : null}
                                {showQuestionnaire ? <Grid item data-html2canvas-ignore>
                                    {currentTab.type !== 'InitiatePPQ' ? <FacultyFMVInfo facultyPPQData={selectedFacultyPPQData} totalPoints={totalPoints} /> : null}
                                </Grid> : null}
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid> : null}
            </Grid>
        </div>;

        return (
            <Box mt={2}>
                <Tabs
                    value={currentTab.index}
                    onChange={this.handleTabChange}
                    aria-label="Questionnaire Management Tabs"
                    style={{
                        background: '#FFFFFF 0% 0% no-repeat padding-box',
                        borderBottom: '1px solid #DEDEDE',
                        boxShadow: 'none',
                    }}
                    TabIndicatorProps={{
                        style: {
                            display: 'none',
                        },
                    }}
                >
                    {tabs.map((tab, i) => {
                        const tabProps = {
                            id: `standard-tab-${i}`,
                            'aria-controls': `standard-tabpanel-${i}`,
                        };

                        const isSelected = currentTab.index === i;

                        const tabStyle: React.CSSProperties = {
                            backgroundColor: '#E6E6E6',
                            textTransform: 'none',
                        };

                        if (isSelected) {
                            tabStyle.backgroundColor = '#FFFFFF';
                        }

                        let totalCount = 0;

                        switch (tab.type) {
                            case "Tiered":
                                totalCount = tieredDataCount;
                                break;
                            case "PendingTiering":
                                totalCount = pendingTieringDataCount;
                                break;
                            case "InitiatePPQ":
                                totalCount = initiatePPQDataCount;
                                break;
                            default:
                                break;
                        }

                        return (
                            <Tab {...tabProps} key={tabProps.id}
                                label={
                                    <TabLabelComponent
                                        tab={tab}
                                        count={totalCount}
                                        isSelected={isSelected}
                                    />
                                }
                                style={tabStyle}
                            />
                        );
                    })}
                </Tabs>
                <TabPanel value={currentTab.index} index={0}>
                    {mainComponent}
                </TabPanel>
                <TabPanel value={currentTab.index} index={1}>
                    {mainComponent}
                </TabPanel>
                <TabPanel value={currentTab.index} index={2}>
                    {mainComponent}
                </TabPanel>
            </Box>
        )
    }

    handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        const { tabs } = this.props;
        const selectedTab = tabs.find(p => p.index === newValue);

        if (selectedTab) {
            this.props.onTabChange(selectedTab);
        }
    }
}

export default TabBar;