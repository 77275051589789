import React from "react";
import moment from "moment";
import { Divider, Grid, Typography } from "@material-ui/core";
import { RateExceptionCommentsDTO } from "../../Models";
import DetailCard from "../../Components/Card/DetailCard";

interface Props {
  comments: RateExceptionCommentsDTO[];
}
interface State {}
class ExceptionAuditDetails extends React.Component<Props, State> {
  render() {
    const { comments } = this.props;

    return (
      <DetailCard title="Exception Comments Details">
        <Grid container direction="row">
          {comments.map((comment, i) => {
            const actionDateTime = `${moment(comment.actiondate).format(
              "DD MMM YYYY"
            )} | ${moment(comment.actiondate).format("LT")}`;

            return (
              <Grid item md={12} key={`AUDIT-${i}`}>
                <Grid container direction="row" spacing={4}>
                  <Grid item md={12}>
                    <Typography
                      style={{
                        fontSize: 16,
                        fontWeight: 400,
                        color: "#5D5D5D",
                      }}
                    >
                      {actionDateTime}
                    </Typography>
                  </Grid>
                  <Grid item md={12}>
                    <Grid container justify="space-between" spacing={4}>
                      <Grid item md={3}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#5D5D5D",
                          }}
                        >
                          Action Type
                        </Typography>
                        <Typography
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: "#1A1A1A",
                          }}
                        >
                          {comment.actiontype}
                        </Typography>
                      </Grid>
                      <Grid item md={3}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#5D5D5D",
                          }}
                        >
                          Action Taken by
                        </Typography>
                        <Typography
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: "#1A1A1A",
                          }}
                        >
                          {comment.actiontakenby}
                        </Typography>
                      </Grid>
                      <Grid item md={3}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#5D5D5D",
                          }}
                        >
                          Action Details
                        </Typography>
                        <Typography
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: "#1A1A1A",
                          }}
                        >
                          {comment.actiondetail}
                        </Typography>
                      </Grid>
                      <Grid item md={3}>
                        <Typography
                          style={{
                            fontSize: 14,
                            fontWeight: 400,
                            color: "#5D5D5D",
                          }}
                        >
                          Comment
                        </Typography>
                        <Typography
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: "#1A1A1A",
                          }}
                        >
                          {comment.comment}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {comments.length - 1 !== i ? (
                  <Divider
                    variant="fullWidth"
                    style={{ marginTop: 15, marginBottom: 30 }}
                  />
                ) : null}
              </Grid>
            );
          })}
        </Grid>
      </DetailCard>
    );
  }
}

export default ExceptionAuditDetails;