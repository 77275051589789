import React from "react";
import { Grid } from "@material-ui/core";

import { FacultyPPQDataDTO } from "../../../Models";

import SearchFilter from "../../../Components/Search/SearchFilter";
import SearchFilterModel from "../../../Components/Search/SearchFilterModel";
import ExportToExcel from "../../../Components/File/ExportToExcel";
import CredentialReportFilterModel from "./CredentialReportFilterModel";
import SelectModel from "../../../Components/Select/SelectModel";
import MultiAutoComplete from "../../../Components/Select/MultiAutoComplete";
import SingleAutoComplete from "../../../Components/Select/SingleAutoComplete";

interface Props {
    hcptypes: SelectModel[];
    credentials: SelectModel[];
    credentialReportData: FacultyPPQDataDTO[];
    credentialReportFilter: CredentialReportFilterModel;
    onFilterChange: ((credentialReportFilter: CredentialReportFilterModel) => void);
}
interface State { }
class CredentialReportFilter extends React.Component<Props, State> {
    render() {
        const {
            credentials, hcptypes,
            credentialReportData, credentialReportFilter,
        } = this.props;

        const dataToExport = credentialReportData.map(credentialData => ({
            facultyid: credentialData.facultyid,
            ppqsubmissiondate: credentialData.ppqsubmissiondate,
            firstname: credentialData.firstname,
            lastname: credentialData.lastname,
            hcptype: credentialData.hcptype,
            specialty: credentialData.specialty,
            credentials: credentialData.credentials,
            points: credentialData.points,
            lillygroup: credentialData.lillygroup,
            lillyrate: credentialData.lillyrate,
            lillytier: credentialData.lillytier,
            bigroup: credentialData.bigroup,
            birate: credentialData.birate,
            bitier: credentialData.bitier,
            lillyfuturegroup: credentialData.lillyfuturegroup,
            lillyfuturerate: credentialData.lillyfuturerate,
            bifuturegroup: credentialData.bifuturegroup,
            bifuturerate: credentialData.bifuturerate,
        }));

        return (
            <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between">
                <Grid item md={11}>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justify="flex-start"
                        spacing={3}>
                        <Grid item md={4}>
                            <SearchFilter label="Search by HCP Type or Specialty Name" value={credentialReportFilter.searchText}
                                onChange={this.handleSearchFilterChange} />
                        </Grid>
                        <Grid item md={2}>
                            <SingleAutoComplete id="hcptype" label="Select HCP Type" values={hcptypes} selectedValue={credentialReportFilter.hcptype}
                                isRounded isRequired={false} onChange={this.handleSelectFilterChange} />
                        </Grid>
                        <Grid item md={4}>
                            <MultiAutoComplete id="credentials" label="Select Multiple Credentials"
                                values={credentials} selectedValues={credentialReportFilter.credentials}
                                isRounded isRequired={false} onChange={this.handleMultiSelectChange} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={1}>
                    <ExportToExcel fileName="CredentialsReport" data={dataToExport} />
                </Grid>
            </Grid>
        );
    }

    handleSearchFilterChange = (searchFilter: SearchFilterModel) => {
        const { credentialReportFilter, onFilterChange } = this.props;

        credentialReportFilter.searchText = searchFilter.searchText;
        onFilterChange(credentialReportFilter);
    }

    handleSelectFilterChange = (selected: SelectModel, targetId?: string) => {
        const { credentialReportFilter, onFilterChange } = this.props;

        if (targetId) {
            let filterData: any = Object.assign({}, credentialReportFilter);
            filterData[targetId] = selected.value;
            onFilterChange(filterData);
        }
    }

    handleMultiSelectChange = (selected: SelectModel[], targetId?: string) => {
        if (targetId) {
            const { credentialReportFilter, onFilterChange } = this.props;
            const values = selected.map(p => p.value as string);

            credentialReportFilter.credentials = values;
            onFilterChange(credentialReportFilter);
        }
    }
}

export default CredentialReportFilter;