import React from "react";
import moment from "moment";
import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableBody,
} from "@material-ui/core";

import { FacultyPPQDataDTO } from "../../../Models";
import TableHeaderCell from "../../../Components/Table/TableHeaderCell";
import TableBodyCell from "../../../Components/Table/TableBodyCell";
import StyledTableRow from "../../../Components/Table//StyledTableRow";

interface Props {
    ppqSummaryReportData: FacultyPPQDataDTO[];
}
interface State { }
class PPQSummaryReportTable extends React.Component<Props, State> {
    render() {
        const { ppqSummaryReportData } = this.props;

        return (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead style={{ backgroundColor: '#EEEEEE' }}>
                        <TableRow>
                            <TableHeaderCell label="Faculty ID" minWidth={100} />
                            <TableHeaderCell label="PPQ Submission Date" minWidth={170} />
                            <TableHeaderCell label="PPQ Submission Year" minWidth={170} />
                            <TableHeaderCell label="FN" />
                            <TableHeaderCell label="LN" />
                            <TableHeaderCell label="HCP Type" />
                            <TableHeaderCell label="Specialty" />
                            <TableHeaderCell label="Points" />
                            <TableHeaderCell label="Current Lilly Group" minWidth={170} />
                            <TableHeaderCell label="Current Lilly Rate" minWidth={170} />
                            <TableHeaderCell label="Current Lilly Tier" minWidth={170} />
                            <TableHeaderCell label="Current BI Alliance Group" minWidth={190} />
                            <TableHeaderCell label="Current BI Alliance Rate" minWidth={190} />
                            <TableHeaderCell label="Current BI Alliance Tier" minWidth={190} />
                            <TableHeaderCell label="Future Lilly Group" minWidth={170} />
                            <TableHeaderCell label="Future Lilly Rate" minWidth={170} />
                            <TableHeaderCell label="Future Lilly Tier" minWidth={170} />
                            <TableHeaderCell label="Future BI Alliance Group" minWidth={190} />
                            <TableHeaderCell label="Future BI Alliance Rate" minWidth={190} />
                            <TableHeaderCell label="Future BI Tier" minWidth={170} />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ppqSummaryReportData.map((row) => (
                            <StyledTableRow key={row.id}>
                                <TableBodyCell label={row.facultyid} />
                                <TableBodyCell label={moment(row.ppqsubmissiondate).format('MM-DD-YYYY')} />
                                <TableBodyCell label={moment(row.ppqsubmissiondate).format('YYYY')} />
                                <TableBodyCell label={row.firstname} />
                                <TableBodyCell label={row.lastname} />
                                <TableBodyCell label={row.hcptype} />
                                <TableBodyCell label={row.specialty} />
                                <TableBodyCell label={row.points} />
                                <TableBodyCell label={row.lillygroup} />
                                <TableBodyCell label={row.lillyrate} />
                                <TableBodyCell label={row.lillytier} />
                                <TableBodyCell label={row.bigroup} />
                                <TableBodyCell label={row.birate} />
                                <TableBodyCell label={row.bitier} />
                                <TableBodyCell label={row.lillyfuturegroup} />
                                <TableBodyCell label={row.lillyfuturerate} />
                                <TableBodyCell label={row.lillytier} />
                                <TableBodyCell label={row.bifuturegroup} />
                                <TableBodyCell label={row.bifuturerate} />
                                <TableBodyCell label={row.bitier} />
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
}

export default PPQSummaryReportTable;