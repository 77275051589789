import React from "react";
import { Grid, Typography } from "@material-ui/core";

import RedButton from "../../../Components/Button/RedButton";
import WhiteButton from "../../../Components/Button/WhiteButton";
import { TabModel } from "../TabModel";
import ExportToExcel from "../../../Components/File/ExportToExcel";
import { BulkPPQResponseDTO } from "../../../Models";
import Utils from "../../../Common/Utils";

interface Props {
    currentTab: TabModel;
    ppqBulkUploadRefresh: boolean;
    ppqBulkUploadResponses: BulkPPQResponseDTO[];
    onStartBatch: (() => void);
    onBulkUploadRefreshClick: (() => void);
}
interface State { }
class PPQBulkUploadFilter extends React.Component<Props, State> {
    render() {
        const { currentTab, ppqBulkUploadRefresh, ppqBulkUploadResponses, onStartBatch, onBulkUploadRefreshClick } = this.props;

        const dataToExport = ppqBulkUploadResponses.map(ppqBulkUploadResponse => ({
            ppqid: ppqBulkUploadResponse.ppqid,
            facultyid: ppqBulkUploadResponse.facultyid,
            ppqyear: ppqBulkUploadResponse.ppqyear,
            ppqresponsestatus: ppqBulkUploadResponse.responseStatus,
            ppqresponsecode: ppqBulkUploadResponse.responsecode,
            ppqresponsedescription: ppqBulkUploadResponse.responsedescription,
            createdon: Utils.getFormattedDateTime(ppqBulkUploadResponse.createdon),
        }));

        return (
            <Grid container spacing={2} justify="space-between">
                <Grid item md={4}>
                    <Typography style={{ fontSize: 20, fontWeight: 500 }}>
                        {'PPQ Bulk Upload Result'}
                    </Typography>
                </Grid>
                <Grid item md={6} container spacing={2} justify="flex-end">
                    <Grid item>
                        {ppqBulkUploadRefresh ? <WhiteButton label={`Refresh`} onClick={onBulkUploadRefreshClick} /> : null}
                    </Grid>
                    <Grid item>
                        <RedButton buttonType="Dialog" id={currentTab.type}
                            dialogMessage="Are you sure you want to start the Batch Process ?" label={`Start PPQ Upload Batch Process`}
                            onClick={onStartBatch} />
                    </Grid>
                    <Grid item>
                        <ExportToExcel fileName="PPBulkUploads" data={dataToExport} />
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default PPQBulkUploadFilter;