import ProQuestAPIService from "./ProQuestAPIService";
import CustomEventHandlerService from './CustomEventHandlerService';

import { BIRateDTO } from "../Models";
import { CustomEventType } from '../Common/Enums';

class BIRateService extends ProQuestAPIService {
    async getAll(): Promise<BIRateDTO[]> {
        const response = await this.GetAsync<BIRateDTO[]>('fmvbirate/getall');

        let biRates: BIRateDTO[] = [];

        if (response.isSuccess) {
            biRates = response.data;

            const today = new Date();

            biRates.forEach(biRate => {
                biRate.bicurrentyear = today.getFullYear();
                biRate.bifutureyear = today.getFullYear() + 1;
            });
        }

        return biRates;
    }

    async add(birate: BIRateDTO): Promise<BIRateDTO | null> {
        const response = await this.PostAsync<BIRateDTO>('fmvbirate/create', birate);

        if (response.isSuccess) {
            CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, response.message);
            return response.data;
        }
        else {
            let message = response.message;

            CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, message);
            return null;
        }
    }

    async edit(birate: BIRateDTO): Promise<BIRateDTO | null> {
        const response = await this.PutAsync<BIRateDTO>(`fmvbirate/update/${birate.birateid}`, birate);

        if (response.isSuccess) {
            CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, response.message);
            return response.data;
        }
        else {
            let message = response.message;

            CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, message);
            return null;
        }
    }

    async editAllGroups(birate: BIRateDTO): Promise<BIRateDTO | null> {
        const response = await this.PutAsync<BIRateDTO>(`fmvbirate/updategroup/${birate.birateid}`, birate);

        if (response.isSuccess) {
            CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, response.message);
            return response.data;
        }
        else {
            let message = response.message;

            CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, message);
            return null;
        }
    }

    async delete(birate: BIRateDTO): Promise<boolean> {
        const response = await this.DeleteAsync<null>(`fmvbirate/delete/${birate.birateid}`);

        if (response.isSuccess) {
            CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, 'The FMV BI Rate has been deleted successfully.');
        }
        else {
            let message = response.message;

            CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, message);
        }

        return response.isSuccess;
    }
}

export default new BIRateService();