import React from 'react';
import { Card, CardContent, Typography, Grid, Avatar } from '@material-ui/core';

import IconButton from '../../../Components/Button/IconButton';
import { FacultyPPQDataDTO } from "../../../Models";

interface Props {
    rateExceptions: FacultyPPQDataDTO[],
    onEdit: ((rateException: FacultyPPQDataDTO) => void);
}
interface State { }
class RateExceptionCard extends React.Component<Props, State> {
    render() {
        const { rateExceptions } = this.props;

        return (
            <Grid container spacing={2}>
                {rateExceptions.map((rateException, i) => {
                    return (
                        <Grid key={`REC-${i}`} item md={4}>
                            <Card style={{
                                border: '1px solid #E5E5E5',
                                boxShadow: '0px 2px 6px #BCBCBC29',
                            }}>
                                <CardContent>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="space-between"
                                    >
                                        <Grid item>
                                            <Avatar style={{ height: '51px', width: '51px', marginLeft: 12 }} />
                                        </Grid>
                                        <Grid item>
                                            <Grid
                                                container
                                                direction="column">
                                                <Grid item>
                                                    <Typography style={{ fontSize: 16, fontWeight: 500, color: '#383838' }}>{rateException.firstname} {rateException.lastname}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography style={{ fontSize: 14, fontWeight: 400, color: '#383838' }}>{rateException.facultyid}</Typography>
                                                </Grid>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    alignItems="center">
                                                    <Typography style={{ fontSize: 14, fontWeight: 400, color: '#747474' }}>{`Lilly Old Rate : ${rateException.lillyrate}`}</Typography>
                                                    <Typography variant="subtitle1" style={{ marginLeft: 10, marginRight: 10 }}>|</Typography>
                                                    <Typography style={{ fontSize: 14, fontWeight: 400, color: '#747474' }}>{`Lilly Exception Rate : ${rateException.lillyexceptionrate}`}</Typography>
                                                </Grid>
                                                <Grid
                                                    container
                                                    direction="row"
                                                    alignItems="center">
                                                    <Typography style={{ fontSize: 14, fontWeight: 400, color: '#747474' }}>{`Alliance Old Rate : ${rateException.birate}`}</Typography>
                                                    <Typography variant="subtitle1" style={{ marginLeft: 10, marginRight: 10 }}>|</Typography>
                                                    <Typography style={{ fontSize: 14, fontWeight: 400, color: '#747474' }}>{`Alliance Exception Rate : ${rateException.biexceptionrate}`}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <IconButton iconType="Edit" onClick={() => this.onEditClick(rateException)} />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                })}
            </Grid>
        );
    }

    onEditClick(rateException: FacultyPPQDataDTO) {
        this.props.onEdit(rateException);
    }

}

export default RateExceptionCard;