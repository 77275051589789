import React from "react";
import { Route, RouteProps } from "react-router-dom";

import { AuthProviderService } from "../../Services";

interface Props extends RouteProps { }
interface State { }
class UserRoute extends React.Component<Props, State> {
    componentDidMount() {
        const { location } = this.props;

        if (location) {
            AuthProviderService.setCallbackPath(location.pathname);
        }
    }

    render() {
        return <Route {...this.props} />;
    }
}

export default UserRoute;