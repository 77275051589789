import Utils from '../Common/Utils';
import AuthProvider from '../Common/AuthProvider';
import AdminAuthProvider from '../Common/AdminAuthProvider';
import { AuthClaimModel, AuthTokenModel } from '../Models';

class AuthProviderService {
    private readonly _CallbackPathName = 'callbackPath';

    login() {
        AuthProvider.login();
    }

    logout() {
        AuthProvider.signout();
    }

    setCallbackPath(path?: string | readonly string[]) {
        if (typeof path === 'string') {
            localStorage.setItem(this._CallbackPathName, path);
        } else {
            localStorage.setItem(this._CallbackPathName, '/');
        }
    }

    getCallbackPath(): string {
        const path = localStorage.getItem(this._CallbackPathName);

        if (path) {
            return path;
        }

        return '/';
    }

    handlerPromise(callback: () => void) {
        AuthProvider.handlePromiseRedirectCallback(callback);
    }

    async isLoggedIn(): Promise<boolean> {
        return AuthProvider.isLoggedIn();
    }

    async getAccessToken(): Promise<AuthTokenModel> {
        return AuthProvider.getAccessToken();
    }

    async getAdminAccessToken(): Promise<AuthTokenModel> {
        return AdminAuthProvider.getAccessToken();
    }

    getClaims(): AuthClaimModel {
        return AuthProvider.getIdTokenClaims();
    }

    async isAdminUser(): Promise<boolean> {
        let isUserAuthorized = false;
        const { accessToken } = await this.getAdminAccessToken();
        const loggedInUserToken = Utils.decodeJWT<any>(accessToken);
        const env = Utils.getEnvVars();

        const headers = new Headers();
        const bearer = "Bearer " + accessToken;
        headers.append("Authorization", bearer);
        headers.append("ConsistencyLevel", "eventual");
        const options = {
            method: "GET",
            headers: headers
        };

        const graphEndpoint = `https://graph.microsoft.com/v1.0/groups/${env.ADMIN_GROUP_ID}/members`;
        const response = await fetch(graphEndpoint, options);
        const data = await response.json();

        if (data && data.value) {
            data.value.forEach((groupmember: any) => {
                if (loggedInUserToken.unique_name === groupmember.mail) {
                    isUserAuthorized = true;
                }
            });
        }

        return isUserAuthorized;
    }
}

export default new AuthProviderService();