import React from "react";
import { Box, Divider, Grid } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import TabBar from "./TabBar";
import { TabModel, FormType } from "./TabModel";

import Alert from "../../Components/Alert/Alert";
import PageHeader from "../../Components/Text/PageHeader";
import RedButton from "../../Components/Button/RedButton";

import { SpecialtyDTO, CredentialDTO, LillyRateDTO, BIRateDTO } from "../../Models";
import { SpecialtyService, CredentialService, LillyRateService, BIRateService, HCPTypeService, CustomEventHandlerService } from "../../Services";

import SpecialtyForm from "./Specialty/SpecialtyForm";
import CredentialForm from "./Credential/CredentialForm";
import LillyRateForm from "./LillyRate/LillyRateForm";
import BIRateForm from "./BIRate/BIRateForm";

import SearchFilterModel from "../../Components/Search/SearchFilterModel";
import SelectModel from "../../Components/Select/SelectModel";
import LillyRateFilterModel from "./LillyRate/LillyRateFilterModel";
import BIRateFilterModel from "./BIRate/BIRateFilterModel";
import SpecialtyFilterModel from "./Specialty/SpecialtyFilterModel";
import CredentialFilterModel from "./Credential/CredentialFilterModel";
import { CustomEventType } from "../../Common/Enums";

interface Props { }
interface State {
    isLoading: boolean;
    showForm: FormType;
    specialtyFormDTO: SpecialtyDTO;
    specialties: SpecialtyDTO[];
    filterSpecialties: SpecialtyDTO[];
    specialtyFilter: SpecialtyFilterModel,
    credentialFormDTO: CredentialDTO;
    credentials: CredentialDTO[];
    filterCredentials: CredentialDTO[];
    credentialFilter: CredentialFilterModel,
    lillyrateFormDTO: LillyRateDTO;
    lillyrates: LillyRateDTO[];
    filterLillyRates: LillyRateDTO[];
    lillyRateFilter: LillyRateFilterModel;
    birateFormDTO: BIRateDTO;
    birates: BIRateDTO[];
    filterBIRates: BIRateDTO[];
    biRateFilter: BIRateFilterModel;
    currentTab: TabModel;
    hcpTypeList: SelectModel[];
    specialtyList: SelectModel[];
}
class SpecialtyCredentialManagement extends React.Component<Props, State> {
    readonly Tabs: TabModel[] = [
        {
            name: 'Specialty',
            type: 'Specialty',
            index: 0,
        },
        {
            name: 'Lilly Specialty Group',
            type: 'LillySpecialtyGroup',
            index: 1,
        },
        {
            name: 'BI Specialty Group',
            type: 'BISpecialtyGroup',
            index: 2,
        },
        {
            name: 'Credential',
            type: 'Credential',
            index: 3,
        }
    ];

    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            isLoading: true,
            showForm: 'None',
            specialtyFormDTO: {} as SpecialtyDTO,
            specialties: [],
            filterSpecialties: [],
            specialtyFilter: new SpecialtyFilterModel(),
            filterCredentials: [],
            credentialFilter: new CredentialFilterModel(),
            credentialFormDTO: {} as CredentialDTO,
            credentials: [],
            lillyrateFormDTO: {} as LillyRateDTO,
            lillyrates: [],
            filterLillyRates: [],
            lillyRateFilter: new LillyRateFilterModel(),
            birateFormDTO: {} as BIRateDTO,
            birates: [],
            filterBIRates: [],
            biRateFilter: new BIRateFilterModel(),
            currentTab: this.Tabs[0],
            hcpTypeList: [],
            specialtyList: [],
        };
    }

    async componentDidMount() {
        CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);

        const specialties = await SpecialtyService.getAll();
        const credentials = await CredentialService.getAll();
        const lillyrates = await LillyRateService.getAll();
        const birates = await BIRateService.getAll();
        const specialtyList = await SpecialtyService.getAllForSelect();
        const hcpTypeList = await HCPTypeService.getAllForSelect();

        this.setState({
            specialties, filterSpecialties: specialties,
            credentials, filterCredentials: credentials,
            lillyrates, filterLillyRates: lillyrates,
            birates, filterBIRates: birates,
            hcpTypeList, specialtyList,
            isLoading: false
        }, () => {
            CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
        });
    }

    render() {
        const {
            showForm, currentTab,
            specialtyFormDTO, filterSpecialties,
            credentialFormDTO, filterCredentials,
            lillyrateFormDTO, filterLillyRates,
            birateFormDTO, filterBIRates,
            specialtyFilter, credentialFilter, lillyRateFilter, biRateFilter,
            hcpTypeList, specialtyList,
        } = this.state;

        return (
            <Box m={2}>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                >
                    <PageHeader label="Specialty / Credential Management" />
                    <Alert />
                    <RedButton id={currentTab.type} startIcon={<FontAwesomeIcon icon={faPlus} />} label={`New ${currentTab.name}`} onClick={this.handleAddClick} />
                </Grid>

                <Divider orientation="horizontal" style={{ marginTop: 10, marginBottom: 10 }} />
                {showForm === this.Tabs[0].type ?
                    <SpecialtyForm specialty={specialtyFormDTO} onCancelClick={this.handleCancelClick} onSubmit={this.handleSpecialtyFormSubmit} />
                    : <></>}

                {showForm === this.Tabs[1].type ?
                    <LillyRateForm lillyrate={lillyrateFormDTO} onCancelClick={this.handleCancelClick} onSubmit={this.handleLillyRateFormSubmit} />
                    : <></>}

                {showForm === this.Tabs[2].type ?
                    <BIRateForm birate={birateFormDTO} onCancelClick={this.handleCancelClick} onSubmit={this.handleBIRateFormSubmit} />
                    : <></>}

                {showForm === this.Tabs[3].type ?
                    <CredentialForm credential={credentialFormDTO} onCancelClick={this.handleCancelClick} onSubmit={this.handleCredentialFormSubmit} />
                    : <></>}

                {showForm !== 'None' ?
                    <Divider orientation="horizontal" style={{ marginTop: 10, marginBottom: 10 }} />
                    : <></>}

                <TabBar
                    currentTab={currentTab} tabs={this.Tabs} onTabChange={this.handleTabChange}
                    hcpTypeList={hcpTypeList} specialtyList={specialtyList}
                    specialtyFilter={specialtyFilter} credentialFilter={credentialFilter} lillyRateFilter={lillyRateFilter} biRateFilter={biRateFilter}
                    specialties={filterSpecialties} onSpecialtyEditClick={this.onSpecialtyEditClick} onSpecialtyDeleteClick={this.onSpecialtyDeleteClick} onSpecialtyFilterChange={this.onSpecialtyFilterChange}
                    lillyrates={filterLillyRates} onLillyRateEditClick={this.onLillyRateEditClick} onLillyRateDeleteClick={this.onLillyRateDeleteClick} onLillyRateFilterChange={this.onLillyRateFilterChange}
                    birates={filterBIRates} onBIRateEditClick={this.onBIRateEditClick} onBIRateDeleteClick={this.onBIRateDeleteClick} onBIRateFilterChange={this.onBIRateFilterChange}
                    credentials={filterCredentials} onCredentialEditClick={this.onCredentialEditClick} onCredentialDeleteClick={this.onCredentialDeleteClick} onCredentialFilterChange={this.onCredentialFilterChange} />
            </Box>
        );
    }

    handleAddClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        const selectedTab = this.Tabs.find(p => p.type === event.currentTarget.id);

        if (selectedTab) {
            this.setState({
                showForm: selectedTab.type,
                specialtyFormDTO: {} as SpecialtyDTO,
                credentialFormDTO: {} as CredentialDTO,
                lillyrateFormDTO: {} as LillyRateDTO,
                birateFormDTO: {} as BIRateDTO
            });
        }
    }

    handleCancelClick = (event: React.MouseEvent<HTMLButtonElement> | undefined) => {
        this.setState({ showForm: 'None' });
    }

    handleTabChange = (selectedTab: TabModel) => {
        this.setState({ currentTab: selectedTab, showForm: 'None' });
    }

    handleSpecialtyFormSubmit = (specialty: SpecialtyDTO) => {
        this.setState({ isLoading: true }, async () => {
            if (specialty.specialtyid !== undefined) {
                const existingSpecialty = await SpecialtyService.edit(specialty);

                const { specialties, filterSpecialties } = this.state;

                if (existingSpecialty) {
                    const index = specialties.findIndex(p => p.specialtyid === specialty.specialtyid);
                    specialties[index] = specialty;

                    const filterIndex = filterSpecialties.findIndex(p => p.specialtyid === specialty.specialtyid);
                    filterSpecialties[filterIndex] = specialty;
                }

                this.setState({ specialties, filterSpecialties, specialtyFormDTO: specialty, isLoading: false });
            }
            else {
                const newSpecialty = await SpecialtyService.add(specialty);

                if (newSpecialty) {
                    this.setState(prevState => ({
                        specialties: [...prevState.specialties, newSpecialty],
                        filterSpecialties: [...prevState.filterSpecialties, newSpecialty],
                        specialtyFormDTO: {} as SpecialtyDTO,
                        isLoading: false
                    }));
                }
            }
        });
    }

    onSpecialtyEditClick = (specialty: SpecialtyDTO) => {
        this.setState({ showForm: 'None' }, () => {
            this.setState({ showForm: 'Specialty', specialtyFormDTO: specialty });
        });
    }

    onSpecialtyDeleteClick = (specialty: SpecialtyDTO) => {
        this.setState({ showForm: 'None', specialtyFormDTO: {} as SpecialtyDTO, isLoading: true }, async () => {
            const isSuccess = await SpecialtyService.delete(specialty);

            const { specialties, filterSpecialties } = this.state;

            if (isSuccess) {
                const index = specialties.findIndex(p => p.specialtyid === specialty.specialtyid);
                specialties.splice(index, 1);

                const filterIndex = filterSpecialties.findIndex(p => p.specialtyid === specialty.specialtyid);
                filterSpecialties.splice(filterIndex, 1);
            }

            this.setState({ specialties, filterSpecialties, isLoading: false });
        });
    }

    onSpecialtyFilterChange = (searchFilter: SearchFilterModel) => {
        const { specialtyFilter, specialties } = this.state;

        const filterSpecialties = specialties.filter(specialty => {
            if (specialty.specialty.toUpperCase().includes(searchFilter.searchText.toUpperCase())) {
                return true;
            }

            return false;
        });

        specialtyFilter.searchText = searchFilter.searchText;

        this.setState({ specialtyFilter, filterSpecialties });
    }

    handleLillyRateFormSubmit = (lillyrate: LillyRateDTO) => {
        this.setState({ isLoading: true }, async () => {
            if (lillyrate.lillyrateid !== undefined) {
                const lillyTiers = lillyrate.lillytier ? lillyrate.lillytier.toString().split(',') : [];
                let editLillyRate: LillyRateDTO | null = {} as LillyRateDTO;

                if (lillyTiers.length > 1) {
                    editLillyRate = await LillyRateService.editAllGroups(lillyrate);
                }
                else {
                    editLillyRate = await LillyRateService.edit(lillyrate);
                }

                let { lillyrates, filterLillyRates } = this.state;

                if (editLillyRate) {
                    editLillyRate.lillycurrentyear = new Date().getFullYear();
                    editLillyRate.lillyfutureyear = new Date().getFullYear() + 1;

                    const index = lillyrates.findIndex(p => p.lillyrateid === editLillyRate?.lillyrateid);
                    lillyrates[index] = editLillyRate;

                    const filterIndex = filterLillyRates.findIndex(p => p.lillyrateid === editLillyRate?.lillyrateid);
                    filterLillyRates[filterIndex] = editLillyRate;
                }

                if (lillyTiers.length > 1) {
                    lillyrates = await LillyRateService.getAll();
                    filterLillyRates = lillyrates.filter(lillyrate => filterLillyRates.some(
                        filterBIRate => filterBIRate.lillyrateid === lillyrate.lillyrateid));
                }

                this.setState({ lillyrates, filterLillyRates, lillyrateFormDTO: lillyrate, showForm: 'None', isLoading: false });
            }
            else {
                const newLillyRate = await LillyRateService.add(lillyrate);

                if (newLillyRate) {
                    newLillyRate.lillycurrentyear = new Date().getFullYear();
                    newLillyRate.lillyfutureyear = new Date().getFullYear() + 1;

                    this.setState(prevState => ({
                        lillyrates: [...prevState.lillyrates, newLillyRate],
                        filterLillyRates: [...prevState.filterLillyRates, newLillyRate],
                        lillyrateFormDTO: {} as LillyRateDTO,
                        showForm: 'None',
                        isLoading: false
                    }));
                }
            }
        });
    }

    onLillyRateEditClick = (lillyrate: LillyRateDTO) => {
        this.setState({ showForm: 'None' }, () => {
            this.setState({ showForm: 'LillySpecialtyGroup', lillyrateFormDTO: lillyrate });
        });
    }

    onLillyRateDeleteClick = (lillyrate: LillyRateDTO) => {
        this.setState({ showForm: 'None', lillyrateFormDTO: {} as LillyRateDTO, isLoading: true }, async () => {
            const isSuccess = await LillyRateService.delete(lillyrate);

            const { lillyrates, filterLillyRates } = this.state;

            if (isSuccess) {
                const index = lillyrates.findIndex(p => p.lillyrateid === lillyrate.lillyrateid);
                lillyrates.splice(index, 1);

                const filterIndex = filterLillyRates.findIndex(p => p.lillyrateid === lillyrate.lillyrateid);
                filterLillyRates.splice(filterIndex, 1);
            }

            this.setState({ lillyrates, filterLillyRates, isLoading: false });
        });
    }

    onLillyRateFilterChange = (id: string, value: any) => {
        const { lillyRateFilter } = this.state;

        let filterData: any = Object.assign({}, lillyRateFilter);
        filterData[id] = value;

        this.setState({ lillyRateFilter: filterData }, () => {
            const { lillyRateFilter, lillyrates } = this.state;

            const { searchText, hcptype, specialty } = lillyRateFilter;

            let filterLillyRates = lillyrates.filter(lillyRate => {
                const conditions: boolean[] = [];

                if (searchText) {
                    const uppercaseValue = searchText.toUpperCase();
                    const searchCondition = lillyRate.specialty.toUpperCase().includes(uppercaseValue)
                        || lillyRate.hcptype.toUpperCase().includes(uppercaseValue);
                    conditions.push(searchCondition);
                }

                if (hcptype) {
                    const hcpTypeCondition = lillyRate.hcptype.includes(hcptype);
                    conditions.push(hcpTypeCondition);
                }

                if (specialty) {
                    const specialtyCondition = lillyRate.specialty.includes(specialty);
                    conditions.push(specialtyCondition);
                }

                let finalCondition: boolean | null = null;

                conditions.forEach(condition => {
                    if (finalCondition === null) {
                        finalCondition = condition;
                    } else {
                        finalCondition = finalCondition && condition;
                    }
                });

                return finalCondition === null ? false : finalCondition;
            });

            if (!(searchText || hcptype || specialty)) {
                filterLillyRates = lillyrates;
            }

            this.setState({ filterLillyRates });
        });
    };

    handleBIRateFormSubmit = (birate: BIRateDTO) => {
        this.setState({ isLoading: true }, async () => {
            if (birate.birateid !== undefined) {
                const biTiers = birate.bitier ? birate.bitier.toString().split(',') : [];

                let editBIRate: BIRateDTO | null = {} as BIRateDTO;

                if (biTiers.length > 1) {
                    editBIRate = await BIRateService.editAllGroups(birate);
                }
                else {
                    editBIRate = await BIRateService.edit(birate);
                }

                let { birates, filterBIRates } = this.state;

                if (editBIRate) {
                    editBIRate.bicurrentyear = new Date().getFullYear();
                    editBIRate.bifutureyear = new Date().getFullYear() + 1;

                    const index = birates.findIndex(p => p.birateid === editBIRate?.birateid);
                    birates[index] = editBIRate;

                    const filterIndex = filterBIRates.findIndex(p => p.birateid === editBIRate?.birateid);
                    filterBIRates[filterIndex] = editBIRate;
                }

                if (biTiers.length > 1) {
                    birates = await BIRateService.getAll();
                    filterBIRates = birates.filter(birate => filterBIRates.some(
                        filterBIRate => filterBIRate.birateid === birate.birateid));
                }

                this.setState({ birates, filterBIRates, birateFormDTO: birate, showForm: 'None', isLoading: false });
            }
            else {
                const newBIRate = await BIRateService.add(birate);

                if (newBIRate) {
                    newBIRate.bicurrentyear = new Date().getFullYear();
                    newBIRate.bifutureyear = new Date().getFullYear() + 1;

                    this.setState(prevState => ({
                        birates: [...prevState.birates, newBIRate],
                        filterBIRates: [...prevState.filterBIRates, newBIRate],
                        birateFormDTO: {} as BIRateDTO,
                        showForm: 'None',
                        isLoading: false
                    }));
                }
            }
        });
    }

    onBIRateEditClick = (birate: BIRateDTO) => {
        this.setState({ showForm: 'None' }, () => {
            this.setState({ showForm: 'BISpecialtyGroup', birateFormDTO: birate });
        });
    }

    onBIRateDeleteClick = (birate: BIRateDTO) => {
        this.setState({ showForm: 'None', birateFormDTO: {} as BIRateDTO, isLoading: true }, async () => {
            const isSuccess = await BIRateService.delete(birate);

            const { birates, filterBIRates } = this.state;

            if (isSuccess) {
                const index = birates.findIndex(p => p.birateid === birate.birateid);
                birates.splice(index, 1);

                const filterIndex = filterBIRates.findIndex(p => p.birateid === birate.birateid);
                filterBIRates.splice(filterIndex, 1);
            }

            this.setState({ birates, filterBIRates, isLoading: false });
        });
    }

    onBIRateFilterChange = (id: string, value: any) => {
        const { biRateFilter } = this.state;

        let filterData: any = Object.assign({}, biRateFilter);
        filterData[id] = value;

        this.setState({ biRateFilter: filterData }, () => {
            const { biRateFilter, birates } = this.state;

            const { searchText, hcptype, specialty } = biRateFilter;

            let filterBIRates = birates.filter(biRate => {
                const conditions: boolean[] = [];

                if (searchText) {
                    const uppercaseValue = searchText.toUpperCase();
                    const searchCondition = biRate.specialty.toUpperCase().includes(uppercaseValue)
                        || biRate.hcptype.toUpperCase().includes(uppercaseValue);
                    conditions.push(searchCondition);
                }

                if (hcptype) {
                    const hcpTypeCondition = biRate.hcptype.includes(hcptype);
                    conditions.push(hcpTypeCondition);
                }

                if (specialty) {
                    const specialtyCondition = biRate.specialty.includes(specialty);
                    conditions.push(specialtyCondition);
                }

                let finalCondition: boolean | null = null;

                conditions.forEach(condition => {
                    if (finalCondition === null) {
                        finalCondition = condition;
                    } else {
                        finalCondition = finalCondition && condition;
                    }
                });

                return finalCondition === null ? false : finalCondition;
            });

            if (!(searchText || hcptype || specialty)) {
                filterBIRates = birates;
            }

            this.setState({ filterBIRates });
        });
    };

    handleCredentialFormSubmit = (credential: CredentialDTO) => {
        this.setState({ isLoading: true }, async () => {
            if (credential.credentialid !== undefined) {
                const existingCredential = await CredentialService.edit(credential);

                const { credentials, filterCredentials } = this.state;

                if (existingCredential) {
                    const index = credentials.findIndex(p => p.credentialid === credential.credentialid);
                    credentials[index] = credential;

                    const filterIndex = filterCredentials.findIndex(p => p.credentialid === credential.credentialid);
                    filterCredentials[filterIndex] = credential;
                }

                this.setState({ credentials, filterCredentials, credentialFormDTO: credential, isLoading: false });
            }
            else {
                const newCredential = await CredentialService.add(credential);

                if (newCredential) {
                    this.setState(prevState => ({
                        credentials: [...prevState.credentials, newCredential],
                        filterCredentials: [...prevState.credentials, newCredential],
                        credentialFormDTO: {} as CredentialDTO,
                        isLoading: false
                    }));
                }
            }
        });
    }

    onCredentialEditClick = (credential: CredentialDTO) => {
        this.setState({ showForm: 'None' }, () => {
            this.setState({ showForm: 'Credential', credentialFormDTO: credential });
        });
    }

    onCredentialDeleteClick = (credential: CredentialDTO) => {
        this.setState({ showForm: 'None', credentialFormDTO: {} as CredentialDTO, isLoading: true }, async () => {
            const isSuccess = await CredentialService.delete(credential);

            const { credentials, filterCredentials } = this.state;

            if (isSuccess) {
                const index = credentials.findIndex(p => p.credentialid === credential.credentialid);
                credentials.splice(index, 1);

                const filterIndex = filterCredentials.findIndex(p => p.credentialid === credential.credentialid);
                filterCredentials.splice(filterIndex, 1);
            }

            this.setState({ credentials, filterCredentials, isLoading: false });
        });
    }

    onCredentialFilterChange = (searchFilter: SearchFilterModel) => {
        const { credentialFilter, credentials } = this.state;

        const filterCredentials = credentials.filter(credential => {
            if (credential.credential.toUpperCase().includes(searchFilter.searchText.toUpperCase())) {
                return true;
            }

            return false;
        });

        credentialFilter.searchText = searchFilter.searchText;

        this.setState({ credentialFilter, filterCredentials });
    }
}

export default SpecialtyCredentialManagement;

