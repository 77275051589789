import React from "react";
import { Box, Grid, Tab, Tabs, Typography } from "@material-ui/core";

import TabPanel from "../../Components/Tab/TabPanel";
import { TabModel } from "./TabModel";
import { FacultyPPQDataDTO, AppConfigDTO, BulkPPQResponseDTO } from "../../Models";
import PPQExceptionTable from "./PPQException/PPQExceptionTable";
import PPQExceptionFilter from "./PPQException/PPQExceptionFilter";
import AppConfigCard from "./AppConfig/AppConfigCard";
import PPQExceptionFilterModel from "./PPQException/PPQExceptionFilterModel";
import PPQBulkUploadTable from "./PPQBulkUpload/PPQBulkUploadTable";
import PPQBulkUploadFilter from "./PPQBulkUpload/PPQBulkUploadFilter";

const TabLabelComponent: React.FC<{ tab: TabModel, isSelected: boolean }> = ({ tab, isSelected }) => {
    return (
        <Grid container alignItems="center" justify="center">
            <Grid item>
                <Typography style={{ fontSize: 16, fontWeight: 500, color: isSelected ? '#FFFFFF' : '#383838', padding: 5 }}>
                    {tab.name}
                </Typography>
            </Grid>
        </Grid>
    );
};

interface Props {
    currentTab: TabModel;
    tabs: TabModel[];
    ppqExceptions: FacultyPPQDataDTO[];
    appConfigs: AppConfigDTO[];
    ppqBulkUploadResponses: BulkPPQResponseDTO[];
    ppqExceptionFilter: PPQExceptionFilterModel;
    ppqBulkUploadRefresh: boolean;
    onStartBatch: (() => void);
    onBulkUploadRefreshClick: (() => void);
    onAppConfigEditClick: ((appconfig: AppConfigDTO) => void);
    onExceptionFilterChange: ((ppqExceptionFilter: PPQExceptionFilterModel) => void);
    onTabChange: ((selectedTab: TabModel) => void);
}
interface State { }
class TabBar extends React.Component<Props, State> {
    render() {
        const {
            tabs, currentTab,
            ppqExceptions,
            appConfigs, onAppConfigEditClick,
            ppqBulkUploadResponses, ppqBulkUploadRefresh, onBulkUploadRefreshClick, onStartBatch,
            ppqExceptionFilter, onExceptionFilterChange,
        } = this.props;

        return (
            <Box mt={2}>
                <Tabs
                    value={currentTab.index}
                    onChange={this.handleTabChange}
                    aria-label="Action Item Tabs"
                    style={{
                        background: '#FFFFFF 0% 0% no-repeat padding-box',
                        boxShadow: 'none',
                    }}
                    TabIndicatorProps={{
                        style: {
                            display: 'none',
                        },
                    }}
                >
                    {tabs.map((tab, i) => {
                        const tabProps = {
                            id: `full-width-tab-${i}`,
                            'aria-controls': `full-width-tabpanel-${i}`,
                        };

                        const isSelected = currentTab.index === i;

                        const tabStyle: React.CSSProperties = {
                            backgroundColor: '#F4F4F4',
                            textTransform: 'none',
                            marginRight: 10,
                            border: '1px solid #D5D5D5',
                            borderRadius: '6px 6px 0px 0px',
                        };

                        if (isSelected) {
                            tabStyle.backgroundColor = '#EE3A29';
                            tabStyle.border = 'none';
                        }

                        return (
                            <Tab {...tabProps} key={tabProps.id}
                                label={
                                    <TabLabelComponent
                                        tab={tab}
                                        isSelected={isSelected}
                                    />
                                }
                                style={tabStyle} />
                        );
                    })}
                </Tabs>
                <TabPanel value={currentTab.index} index={0}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item md={12}>
                            <PPQExceptionFilter ppqExceptions={ppqExceptions}
                                ppqExceptionFilter={ppqExceptionFilter} onExceptionFilterChange={onExceptionFilterChange} />
                        </Grid>
                        <Grid item md={12}>
                            <PPQExceptionTable ppqExceptions={ppqExceptions} />
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={currentTab.index} index={1}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item md={12}>
                            <AppConfigCard appConfigs={appConfigs} onEdit={onAppConfigEditClick} />
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value={currentTab.index} index={2}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item md={12}>
                            <PPQBulkUploadFilter currentTab={currentTab} ppqBulkUploadRefresh={ppqBulkUploadRefresh}
                                ppqBulkUploadResponses={ppqBulkUploadResponses}
                                onStartBatch={onStartBatch} onBulkUploadRefreshClick={onBulkUploadRefreshClick} />
                        </Grid>
                        <Grid item md={12}>
                            <PPQBulkUploadTable ppqBulkUploadResponses={ppqBulkUploadResponses} />
                        </Grid>
                    </Grid>
                </TabPanel>
            </Box>
        )
    }

    handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        const { tabs } = this.props;
        const selectedTab = tabs.find(p => p.index === newValue);

        if (selectedTab) {
            this.props.onTabChange(selectedTab);
        }
    }
}

export default TabBar;