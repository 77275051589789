import React from 'react';
import { Card, CardContent, Typography, Grid, Avatar } from '@material-ui/core';

import IconButton from '../../Components/Button/IconButton';
import { UserDTO } from "../../Models";

interface Props {
    users: UserDTO[],
    onEdit: ((user: UserDTO) => void);
    onDelete: ((user: UserDTO) => void);
}
interface State { }
class UserCard extends React.Component<Props, State> {
    render() {
        const { users } = this.props;

        return (
            <Grid container spacing={2}>
                {users.map((user, i) => {
                    return (
                        <Grid key={`AUC-${i}`} item>
                            <Card>
                                <CardContent>
                                    <Grid
                                        spacing={2}
                                        container
                                        direction="row">
                                        <Grid item>
                                            <Avatar style={{ height: '51px', width: '51px' }} />
                                        </Grid>
                                        <Grid item>
                                            <Grid
                                                container
                                                direction="column">
                                                <Grid item>
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        alignItems="flex-start"
                                                        justify="space-between"
                                                    >
                                                        <Grid item>
                                                            <Typography style={{ fontSize: 16, fontWeight: 500, color: '#383838' }}>{user.firstname} {user.lastname}</Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid
                                                                spacing={2}
                                                                container>
                                                                <Grid item>
                                                                    <IconButton iconType="Edit" onClick={() => this.onEditClick(user)} />
                                                                </Grid>
                                                                <Grid item>
                                                                    <IconButton iconType="Delete" onClick={() => this.onDeleteClick(user)} />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Typography style={{ fontSize: 14, fontWeight: 400, color: '#383838' }}>{user.userid}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography style={{ fontSize: 14, fontWeight: 400, color: '#747474' }}>{user.email}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                })}
            </Grid>
        );
    }

    onEditClick(user: UserDTO) {
        this.props.onEdit(user);
    }

    onDeleteClick(user: UserDTO) {
        this.props.onDelete(user);
    }
}

export default UserCard;