import React from 'react';
import { Typography } from "@material-ui/core";

import { CustomEventType } from '../../Common/Enums';
import { CustomEventHandlerService } from '../../Services';

interface Props { }
interface State { }
class AccessDenied extends React.Component<Props, State> {
    componentDidMount() {
        CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
    }

    render() {
        return (
            <Typography style={{ fontSize: 20, fontWeight: 500, padding: 20 }}>
                User is not authorized to access the ProQuest application, Please contact application owner.
            </Typography>
        );
    }
}

export default AccessDenied;