import React from "react";
import { Grid } from "@material-ui/core";

import { FacultyDTO, FacultyQuestDTO } from "../../../Models";

import SearchFilter from "../../../Components/Search/SearchFilter";
import SearchFilterModel from "../../../Components/Search/SearchFilterModel";
import ExportToExcel from "../../../Components/File/ExportToExcel";
import PendingTieringReportFilterModel from "./PendingTieringReportFilterModel";
import RadioButton from "../../../Components/RadioButton/RadioButton";
import RadioModel from "../../../Components/RadioButton/RadioModel";
import { QuestionnaryStatus } from "../../../Common/Enums";
import Utils from "../../../Common/Utils";

interface Props {
    pendingTieringReportData: FacultyDTO[];
    signOfAnswers: FacultyQuestDTO[];
    pendingTieringReportFilter: PendingTieringReportFilterModel;
    onFilterChange: ((pendingTieringReportFilter: PendingTieringReportFilterModel) => void);
}
interface State { }
class PendingTieringReportFilter extends React.Component<Props, State> {
    readonly Statuses: RadioModel[] = [
        {
            text: QuestionnaryStatus.PENDING,
            value: QuestionnaryStatus[QuestionnaryStatus.PENDING],
        },
        {
            text: QuestionnaryStatus.EXCEPTION,
            value: QuestionnaryStatus[QuestionnaryStatus.EXCEPTION],
        },
    ];

    render() {
        const {
            pendingTieringReportData, pendingTieringReportFilter, signOfAnswers,
        } = this.props;

        const dataToExport = pendingTieringReportData.map(pendingTieringData => {
            let signOff = 'Pending';
            const signOffAnswer = signOfAnswers.find(p => p.facultyid === pendingTieringData.facultyid);

            if (signOffAnswer) {
                signOff = 'Completed';
            }

            return {
                facultyid: pendingTieringData.facultyid,
                firstname: pendingTieringData.firstname,
                lastname: pendingTieringData.lastname,
                modifieddate: Utils.getFormattedDateTime(pendingTieringData.updatedon),
                status: pendingTieringData.questionairystatus,
                signoff: signOff,
            }
        });

        return (
            <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between">
                <Grid item md={11}>
                    <Grid container alignItems="center" spacing={3}>
                        <Grid item md={4}>
                            <SearchFilter label="Search by Faculty ID or Name" value={pendingTieringReportFilter.searchText}
                                onChange={this.handleSearchFilterChange} />
                        </Grid>
                        <Grid item>
                            <RadioButton values={this.Statuses} selectedValue={pendingTieringReportFilter.status?.value}
                                onChange={this.handleStatusChange} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <ExportToExcel fileName="PendingTieringReport" data={dataToExport} />
                </Grid>
            </Grid>
        );
    }

    handleSearchFilterChange = (searchFilter: SearchFilterModel) => {
        const { pendingTieringReportFilter, onFilterChange } = this.props;

        pendingTieringReportFilter.searchText = searchFilter.searchText;
        onFilterChange(pendingTieringReportFilter);
    }

    handleStatusChange = (selected: RadioModel) => {
        const { pendingTieringReportFilter, onFilterChange } = this.props;

        pendingTieringReportFilter.status = selected;
        onFilterChange(pendingTieringReportFilter);
    }
}

export default PendingTieringReportFilter;