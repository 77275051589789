import React from 'react';
import { Button, Grid } from '@material-ui/core';

import { LillyRateDTO } from "../../../Models";
import { SpecialtyService, HCPTypeService, GroupService, TierService } from "../../../Services";
import { ValidationType } from '../../../Common/Enums';

import SelectModel from '../../../Components/Select/SelectModel';
import TextInputField from '../../../Components/TextInput/TextInputField';
import ModalDialog from '../../../Components/Modal/ModalDialog';
import SingleAutoComplete from '../../../Components/Select/SingleAutoComplete';
import MultiAutoComplete from '../../../Components/Select/MultiAutoComplete';

interface Props {
    lillyrate?: LillyRateDTO;
    onCancelClick: ((event: React.MouseEvent<HTMLButtonElement> | undefined) => void);
    onSubmit: ((lillyrate: LillyRateDTO) => void);
}
interface State {
    lillyrate: LillyRateDTO;
    specialties: SelectModel[];
    hcpTypes: SelectModel[];
    groups: SelectModel[];
    tiers: SelectModel[];
    formValidationState: { name: string, isValid: boolean }[];
}
class LillyRateForm extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);

        let isValid = false;
        let lillyrate = {} as LillyRateDTO;

        if (props.lillyrate && props.lillyrate.lillyrateid) {
            isValid = true;
            lillyrate = props.lillyrate
        }

        this.state = {
            lillyrate,
            specialties: [],
            hcpTypes: [],
            groups: [],
            tiers: [],
            formValidationState: [
                { name: 'hcptype', isValid },
                { name: 'specialty', isValid },
                { name: 'lillygroup', isValid },
                { name: 'lillyfuturegroup', isValid },
                { name: 'lillycurrentrate', isValid },
                { name: 'lillyfuturerate', isValid },
                { name: 'lillytier', isValid },
            ],
        };
    }

    async componentDidMount() {
        const specialties = await SpecialtyService.getAllForSelect();
        const hcpTypes = await HCPTypeService.getAllForSelect();
        const groups = await GroupService.getAllForSelect();
        const tiers = await TierService.getAllForSelect();

        this.setState({ specialties, hcpTypes, groups, tiers });
    }

    render() {
        const { lillyrate, specialties, hcpTypes, groups, tiers, formValidationState } = this.state;
        const { onCancelClick } = this.props;

        const lillyTiers = lillyrate.lillytier ? lillyrate.lillytier.toString().split(',') : [];
        const isUpdateForm = lillyrate.lillyrateid !== undefined;
        const isFormNotValid = formValidationState.some(p => p.isValid === false);

        const lillyTierComponent = isUpdateForm ?
            <MultiAutoComplete id="lillytier" label="Lilly Tier"
                selectedValues={lillyTiers} values={tiers} onChange={this.handleMultiSelectChange} /> :
            <SingleAutoComplete id="lillytier" label="Lilly Tier"
                selectedValue={lillyrate.lillytier} values={tiers} onChange={this.handleSelectChange} />;

        return (
            <ModalDialog title={`${isUpdateForm ? 'Update' : 'Add'} Lilly Specialty Group`}
                isOpen={true} onClose={() => onCancelClick(undefined)}
                dialogAction={
                    <Grid container
                        spacing={2}
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                        style={{ marginRight: 30 }}>
                        <Grid item>
                            <Button size="medium" variant="outlined" style={{ borderColor: '#707070', color: '#555555', fontWeight: 400, fontSize: 16, textTransform: 'none' }}
                                onClick={onCancelClick}>Cancel</Button>
                        </Grid>
                        <Grid item>
                            {isFormNotValid ?
                                <Button disabled={true} size="medium" variant="outlined" style={{ fontWeight: 500, fontSize: 16, textTransform: 'none' }}
                                    onClick={this.handleSubmit}>{isUpdateForm ? 'Update' : 'Add'}</Button> :
                                <Button size="medium" variant="outlined" style={{ backgroundColor: '#C6C6C6', color: '#5D5D5D', fontWeight: 500, fontSize: 16, textTransform: 'none' }}
                                    onClick={this.handleSubmit}>{isUpdateForm ? 'Update' : 'Add'}</Button>}
                        </Grid>
                    </Grid>
                }>
                <form noValidate autoComplete="off">
                    <div>
                        <Grid container spacing={5}>
                            <Grid item xs={6}>
                                <SingleAutoComplete id="hcptype" label="HCP Type"
                                    selectedValue={lillyrate.hcptype} values={hcpTypes} onChange={this.handleSelectChange} />
                            </Grid>
                            <Grid item xs={6}>
                                <SingleAutoComplete id="specialty" label="Specialty"
                                    selectedValue={lillyrate.specialty} values={specialties} onChange={this.handleSelectChange} />
                            </Grid>
                            <Grid item xs={6}>
                                <SingleAutoComplete id="lillygroup" label="Lilly Current Year Group"
                                    selectedValue={lillyrate.lillygroup} values={groups} onChange={this.handleSelectChange} />
                            </Grid>
                            <Grid item xs={6}>
                                <SingleAutoComplete id="lillyfuturegroup" label="Lilly Future Year Group"
                                    selectedValue={lillyrate.lillyfuturegroup} values={groups} onChange={this.handleSelectChange} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInputField id="lillycurrentrate" label="Lilly Current Year Rate"
                                    inputType="number" validationType={ValidationType.RATE}
                                    isDisabled={lillyTiers.length > 1} value={lillyrate.lillycurrentrate} onChange={this.handleChange} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInputField id="lillyfuturerate" label="Lilly Future Year Rate"
                                    inputType="number" validationType={ValidationType.RATE}
                                    isDisabled={lillyTiers.length > 1} value={lillyrate.lillyfuturerate} onChange={this.handleChange} />
                            </Grid>
                            <Grid item xs={6}>
                                {lillyTierComponent}
                            </Grid>
                        </Grid>
                    </div>
                </form>
            </ModalDialog>
        );
    }

    handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined, isValid: boolean) => {
        if (event) {
            this.setState(prevState => {
                const { id, value } = event.target;
                let lillyrate: any = Object.assign({}, prevState.lillyrate);
                lillyrate[id] = value;

                let formValidationState = prevState.formValidationState;
                const index = formValidationState.findIndex(p => p.name === id);
                formValidationState[index] = { name: id, isValid: isValid };

                return { lillyrate, formValidationState };
            });
        }
    }

    handleSelectChange = (selected: SelectModel, targetId?: string, isValid?: boolean) => {
        if (targetId) {
            this.setState(prevState => {
                let lillyrate: any = Object.assign({}, prevState.lillyrate);
                lillyrate[targetId] = selected.value;

                let formValidationState = prevState.formValidationState;
                const index = formValidationState.findIndex(p => p.name === targetId);
                formValidationState[index] = { name: targetId, isValid: isValid ? isValid : false };

                return { lillyrate, formValidationState };
            });
        }
    }

    handleMultiSelectChange = (selected: SelectModel[], targetId?: string, isValid?: boolean) => {
        if (targetId) {
            const values = selected.map(p => p.value).join(',');

            this.setState(prevState => {
                let lillyrate: any = Object.assign({}, prevState.lillyrate);
                lillyrate[targetId] = values;

                let formValidationState = prevState.formValidationState;
                const index = formValidationState.findIndex(p => p.name === targetId);
                formValidationState[index] = { name: targetId, isValid: isValid ? isValid : false };

                return { lillyrate, formValidationState };
            });
        }
    }

    handleSubmit = () => {
        const { lillyrate } = this.state;
        this.props.onSubmit(lillyrate);
    }
}

export default LillyRateForm;
