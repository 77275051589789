import React from "react";
import { Grid, Box, Divider } from "@material-ui/core";
import moment from "moment";

import PageHeader from "../../Components/Text/PageHeader";
import SelectModel from "../../Components/Select/SelectModel";
import { SpecialtyService, HCPTypeService, CredentialService, ReportService, CustomEventHandlerService, ADMINQuestionnaireService, ExcelService, ContentfulService } from "../../Services";
import { ContentfulAnswer, FacultyDTO, FacultyPPQDataDTO, FacultyQuestDTO, PPQResponseReportModel } from "../../Models";
import Utils from "../../Common/Utils";

import { TabModel } from "./TabModel";
import TabBar from "./TabBar";
import PPQSummaryReportFilterModel from "./PPQSummaryReport/PPQSummaryReportFilterModel";
import ExceptionRateReportFilterModel from "./ExceptionRateReport/ExceptionRateReportFilterModel";
import PayerReportFilterModel from "./PayerReport/PayerReportFilterModel";
import SubmissionDateReportFilterModel from "./SubmissionDateReport/SubmissionDateReportFilterModel";
import CredentialReportFilterModel from "./CredentialReport/CredentialReportFilterModel";
import PPQResponseReportFilterModel from "./PPQResponseReport/PPQResponseReportFilterModel";
import { CustomEventType } from "../../Common/Enums";
import PendingTieringReportFilterModel from "./PendingTieringReport/PendingTieringReportFilterModel";

interface Props { }
interface State {
    isLoading: boolean;
    currentTab: TabModel;
    hcptypes: SelectModel[];
    specialties: SelectModel[];
    credentials: SelectModel[];
    ppqSummaryReportData: FacultyPPQDataDTO[];
    filterPPQSummaryReportData: FacultyPPQDataDTO[];
    ppqSummaryReportFilter: PPQSummaryReportFilterModel;
    exceptionRateReportData: FacultyPPQDataDTO[];
    filterExceptionRateReportData: FacultyPPQDataDTO[];
    exceptionRateReportFilter: ExceptionRateReportFilterModel;
    payerReportData: FacultyPPQDataDTO[];
    filterPayerReportData: FacultyPPQDataDTO[];
    payerReportFilter: PayerReportFilterModel;
    submissionDateReportData: FacultyPPQDataDTO[];
    filterSubmissionDateReportData: FacultyPPQDataDTO[];
    submissionDateReportFilter: SubmissionDateReportFilterModel;
    credentialReportData: FacultyPPQDataDTO[];
    filterCredentialReportData: FacultyPPQDataDTO[];
    credentialReportFilter: CredentialReportFilterModel;
    ppqResponseReportData: PPQResponseReportModel[];
    filterPPQResponseReportData: PPQResponseReportModel[];
    ppqResponseReportFilter: PPQResponseReportFilterModel;
    pendingTieringReportData: FacultyDTO[];
    filterPendingTieringReportData: FacultyDTO[];
    pendingTieringReportFilter: PendingTieringReportFilterModel;
    signOfAnswers: FacultyQuestDTO[];
    page: number;
    pageSize: number;
    totalCount: number;
    contentfulAnswers: ContentfulAnswer[];
}
class Report extends React.Component<Props, State> {
    readonly Tabs: TabModel[] = [
        {
            name: 'PPQ Summary Report',
            index: 0,
        },
        {
            name: 'Rate Update Exceptions',
            index: 1,
        },
        {
            name: 'Payer Report',
            index: 2,
        },
        {
            name: 'Submission Date Report',
            index: 3,
        },
        {
            name: 'Credentials Report',
            index: 4,
        },
        {
            name: 'All PPQ Responses Report',
            index: 5,
        },
        {
            name: 'Pending Tiering',
            index: 6,
        },
    ];

    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            isLoading: true,
            currentTab: this.Tabs[0],
            hcptypes: [],
            specialties: [],
            credentials: [],
            ppqSummaryReportData: [],
            filterPPQSummaryReportData: [],
            ppqSummaryReportFilter: new PPQSummaryReportFilterModel(),
            exceptionRateReportData: [],
            filterExceptionRateReportData: [],
            exceptionRateReportFilter: new ExceptionRateReportFilterModel(),
            payerReportData: [],
            filterPayerReportData: [],
            payerReportFilter: new PayerReportFilterModel(),
            submissionDateReportData: [],
            filterSubmissionDateReportData: [],
            submissionDateReportFilter: new SubmissionDateReportFilterModel(),
            credentialReportData: [],
            filterCredentialReportData: [],
            credentialReportFilter: new CredentialReportFilterModel(),
            ppqResponseReportData: [],
            filterPPQResponseReportData: [],
            ppqResponseReportFilter: new PPQResponseReportFilterModel(),
            pendingTieringReportData: [],
            filterPendingTieringReportData: [],
            pendingTieringReportFilter: new PendingTieringReportFilterModel(),
            signOfAnswers: [],
            page: 1,
            pageSize: 10,
            totalCount: 0,
            contentfulAnswers: [],
        };
    }

    async componentDidMount() {
        CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);
        const { page, pageSize } = this.state;

        const contentfulAnswers = await ContentfulService.getAnswers();
        const ppqSummaryReportData = await ReportService.getPPQSummaryReport(contentfulAnswers);
        const exceptionRateReportData = await ReportService.getPPQRateExceptions(contentfulAnswers);
        const payerReportData = await ReportService.getAllPayerActionItems(contentfulAnswers);
        const submissionDateReportData = ppqSummaryReportData;
        const credentialReportData = ppqSummaryReportData;
        const specialties = await SpecialtyService.getAllForSelect();
        const hcptypes = await HCPTypeService.getAllForSelect();
        const credentials = await CredentialService.getAllForSelect();
        const ppqResponseReportData = await ReportService.getAllPPQResponses(page, pageSize, '', '', '', contentfulAnswers);
        const pendingTieringReportData = await ReportService.getPPQPendingTieringReport();
        const signOfAnswers = await ADMINQuestionnaireService.getAllSignOfAnswers();

        this.setState({
            hcptypes, specialties, credentials,
            ppqSummaryReportData, filterPPQSummaryReportData: ppqSummaryReportData,
            exceptionRateReportData, filterExceptionRateReportData: exceptionRateReportData,
            payerReportData, filterPayerReportData: payerReportData,
            submissionDateReportData, filterSubmissionDateReportData: submissionDateReportData,
            credentialReportData, filterCredentialReportData: credentialReportData,
            ppqResponseReportData: ppqResponseReportData.ppqResponseReports, filterPPQResponseReportData: ppqResponseReportData.ppqResponseReports,
            pendingTieringReportData, filterPendingTieringReportData: pendingTieringReportData,
            signOfAnswers,
            isLoading: false,
            totalCount: ppqResponseReportData.count,
            contentfulAnswers,
        }, () => {
            CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
        });
    }

    render() {
        const {
            currentTab,
            page, pageSize, totalCount,
            hcptypes, specialties, credentials,
            filterPPQSummaryReportData, ppqSummaryReportFilter,
            filterExceptionRateReportData, exceptionRateReportFilter,
            filterPayerReportData, payerReportFilter,
            filterSubmissionDateReportData, submissionDateReportFilter,
            filterCredentialReportData, credentialReportFilter,
            filterPPQResponseReportData, ppqResponseReportFilter,
            filterPendingTieringReportData, pendingTieringReportFilter,
            signOfAnswers,
        } = this.state;

        return (
            <Box m={2}>
                <Grid
                    container
                    direction="row"
                    alignItems="flex-start"
                >
                    <Grid item>
                        <PageHeader label="Reports" />
                    </Grid>
                </Grid>

                <Divider orientation="horizontal" style={{ marginTop: 10, marginBottom: 10 }} />

                <TabBar
                    currentTab={currentTab} tabs={this.Tabs} onTabChange={this.handleTabChange}
                    page={page} pageSize={pageSize} totalCount={totalCount} onPageChange={this.handlePageChange}
                    hcptypes={hcptypes} specialties={specialties} credentials={credentials}
                    ppqSummaryReportData={filterPPQSummaryReportData} ppqSummaryReportFilter={ppqSummaryReportFilter} onPPQSummaryFilterChange={this.handlePPQSummaryFilterChange}
                    exceptionRateReportData={filterExceptionRateReportData} exceptionRateReportFilter={exceptionRateReportFilter} onExceptionRateFilterChange={this.handleExceptionRateFilterChange}
                    payerReportData={filterPayerReportData} payerReportFilter={payerReportFilter} onPayerFilterChange={this.handlePayerFilterChange}
                    submissionDateReportData={filterSubmissionDateReportData} submissionDateReportFilter={submissionDateReportFilter} onSubmissionDateFilterChange={this.handleSubmissionDateFilterChange}
                    credentialReportData={filterCredentialReportData} credentialReportFilter={credentialReportFilter} onCredentialFilterChange={this.handleCredentialFilterChange}
                    ppqResponseReportData={filterPPQResponseReportData} ppqResponseReportFilter={ppqResponseReportFilter} onPPQResponseFilterChange={this.handlePPQResponseFilterChange}
                    pendingTieringReportData={filterPendingTieringReportData} pendingTieringReportFilter={pendingTieringReportFilter} onPendingTieringFilterChange={this.handlePendingTieringFilterChange}
                    signOfAnswers={signOfAnswers} onExport={this.handleExport} />
            </Box>
        );
    }

    handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        this.setState({ page: value }, async () => {
            CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);

            const { page, pageSize, ppqResponseReportFilter, contentfulAnswers } = this.state;
            const { count, ppqResponseReports } = await ReportService.getAllPPQResponses(page, pageSize, ppqResponseReportFilter.hcptype, ppqResponseReportFilter.searchText, '', contentfulAnswers);
            this.setState({
                ppqResponseReportData: ppqResponseReports,
                filterPPQResponseReportData: ppqResponseReports,
                totalCount: count,
            }, () => {
                CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
            });
        });
    }

    handleTabChange = (selectedTab: TabModel) => {
        this.setState({ currentTab: selectedTab });
    }

    handlePPQSummaryFilterChange = (ppqSummaryReportFilter: PPQSummaryReportFilterModel) => {
        this.setState({ ppqSummaryReportFilter }, () => {
            const { ppqSummaryReportFilter, ppqSummaryReportData } = this.state;

            const { searchText, hcptype, specialty, date } = ppqSummaryReportFilter;

            let filterPPQSummaryReportData = ppqSummaryReportData.filter(faculty => {
                const conditions: boolean[] = [];

                if (searchText) {
                    const uppercaseValue = searchText.toUpperCase();
                    const searchCondition = (faculty.hcptype && faculty.hcptype.toUpperCase().includes(uppercaseValue))
                        || faculty.specialty.toUpperCase().includes(uppercaseValue);
                    conditions.push(searchCondition);
                }

                if (date) {
                    const dateCondition = faculty.ppqsubmissiondate !== null ?
                        Utils.isEqualDates(faculty.ppqsubmissiondate, date) : false;
                    conditions.push(dateCondition);
                }

                if (hcptype) {
                    const hcpTypeCondition = faculty.hcptype !== null ?
                        faculty.hcptype.includes(hcptype) : false;
                    conditions.push(hcpTypeCondition);
                }

                if (specialty) {
                    const specialtyCondition = faculty.specialty.includes(specialty);
                    conditions.push(specialtyCondition);
                }

                let finalCondition: boolean | null = null;

                conditions.forEach(condition => {
                    if (finalCondition === null) {
                        finalCondition = condition;
                    } else {
                        finalCondition = finalCondition && condition;
                    }
                });

                return finalCondition === null ? false : finalCondition;
            });

            if (!(searchText || hcptype || specialty || date)) {
                filterPPQSummaryReportData = ppqSummaryReportData;
            }

            this.setState({ filterPPQSummaryReportData });
        });
    }

    handleExceptionRateFilterChange = (exceptionRateReportFilter: ExceptionRateReportFilterModel) => {
        this.setState({ exceptionRateReportFilter }, () => {
            const { exceptionRateReportFilter, exceptionRateReportData } = this.state;

            const { searchText } = exceptionRateReportFilter;

            let filterExceptionRateReportData = exceptionRateReportData.filter(faculty => {
                const conditions = [];

                if (searchText) {
                    const uppercaseValue = searchText.toUpperCase();
                    const searchCondition = (faculty.hcptype && faculty.hcptype.toUpperCase().includes(uppercaseValue))
                        || faculty.specialty.toUpperCase().includes(uppercaseValue);
                    conditions.push(searchCondition);
                }

                return conditions.some(p => p === true);
            });

            if (!(searchText)) {
                filterExceptionRateReportData = exceptionRateReportData;
            }

            this.setState({ filterExceptionRateReportData });
        });
    }

    handlePayerFilterChange = (payerReportFilter: PayerReportFilterModel) => {
        this.setState({ payerReportFilter }, () => {
            const { payerReportFilter, payerReportData } = this.state;

            const { searchText } = payerReportFilter;

            let filterPayerReportData = payerReportData.filter(faculty => {
                const conditions = [];

                if (searchText) {
                    const uppercaseValue = searchText.toUpperCase();
                    const searchCondition = (faculty.hcptype && faculty.hcptype.toUpperCase().includes(uppercaseValue));
                    conditions.push(searchCondition);
                }

                return conditions.some(p => p === true);
            });

            if (!(searchText)) {
                filterPayerReportData = payerReportData;
            }

            this.setState({ filterPayerReportData });
        });
    }

    handleSubmissionDateFilterChange = (submissionDateReportFilter: SubmissionDateReportFilterModel) => {
        this.setState({ submissionDateReportFilter }, () => {
            const { submissionDateReportFilter, submissionDateReportData } = this.state;

            const { searchText, startDate, endDate } = submissionDateReportFilter;

            let filterSubmissionDateReportData = submissionDateReportData.filter(faculty => {
                const conditions: boolean[] = [];

                if (searchText) {
                    const uppercaseValue = searchText.toUpperCase();
                    const searchCondition = (faculty.hcptype && faculty.hcptype.toUpperCase().includes(uppercaseValue))
                        || faculty.specialty.toUpperCase().includes(uppercaseValue);
                    conditions.push(searchCondition);
                }

                if (startDate && endDate) {
                    const dateCondition = moment(faculty.ppqsubmissiondate).isBetween(startDate, endDate, 'days', '[]');
                    conditions.push(dateCondition);
                }

                let finalCondition: boolean | null = null;

                conditions.forEach(condition => {
                    if (finalCondition === null) {
                        finalCondition = condition;
                    } else {
                        finalCondition = finalCondition && condition;
                    }
                });

                return finalCondition === null ? false : finalCondition;
            });

            if (!(searchText || (startDate && endDate))) {
                filterSubmissionDateReportData = submissionDateReportData;
            }

            this.setState({ filterSubmissionDateReportData });
        });
    }

    handleCredentialFilterChange = (credentialReportFilter: CredentialReportFilterModel) => {
        this.setState({ credentialReportFilter }, () => {
            const { credentialReportFilter, credentialReportData } = this.state;

            const { searchText, hcptype, credentials } = credentialReportFilter;

            let filterCredentialReportData = credentialReportData.filter(faculty => {
                const conditions: boolean[] = [];

                if (searchText) {
                    const uppercaseValue = searchText.toUpperCase();
                    const searchCondition = (faculty.hcptype && faculty.hcptype.toUpperCase().includes(uppercaseValue))
                        || faculty.specialty.toUpperCase().includes(uppercaseValue);
                    conditions.push(searchCondition);
                }

                if (hcptype) {
                    const hcpTypeCondition = faculty.hcptype !== null ?
                        faculty.hcptype.includes(hcptype) : false;
                    conditions.push(hcpTypeCondition);
                }

                if (credentials.length > 0) {
                    if (faculty.credentials) {
                        let credentialCondition = false;
                        const facultyCredentials = faculty.credentials.split(',');

                        facultyCredentials.forEach(facultyCredential => {
                            credentials.forEach(credential => {
                                if (facultyCredential === credential) {
                                    credentialCondition = true;
                                }
                            });
                        });

                        conditions.push(credentialCondition);
                    }
                }

                let finalCondition: boolean | null = null;

                conditions.forEach(condition => {
                    if (finalCondition === null) {
                        finalCondition = condition;
                    } else {
                        finalCondition = finalCondition && condition;
                    }
                });

                return finalCondition === null ? false : finalCondition;
            });

            if (!(searchText || hcptype || (credentials.length > 0))) {
                filterCredentialReportData = credentialReportData;
            }

            this.setState({ filterCredentialReportData });
        });
    }

    handlePPQResponseFilterChange = (ppqResponseReportFilter: PPQResponseReportFilterModel) => {
        this.setState({ ppqResponseReportFilter, page: 1, pageSize: 10 }, async () => {
            CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);

            const { page, pageSize, ppqResponseReportFilter, contentfulAnswers } = this.state;
            const { count, ppqResponseReports } = await ReportService.getAllPPQResponses(page, pageSize, ppqResponseReportFilter.hcptype, ppqResponseReportFilter.searchText, '', contentfulAnswers);
            this.setState({
                ppqResponseReportData: ppqResponseReports,
                filterPPQResponseReportData: ppqResponseReports,
                totalCount: count,
            }, () => {
                CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
            });
        });
    }

    handlePendingTieringFilterChange = (pendingTieringReportFilter: PendingTieringReportFilterModel) => {
        this.setState({ pendingTieringReportFilter }, () => {
            const { pendingTieringReportFilter, pendingTieringReportData } = this.state;

            const { searchText, status } = pendingTieringReportFilter;

            let filterPendingTieringReportData = pendingTieringReportData.filter(faculty => {
                const statusCondition = faculty.questionairystatus === status?.value as string;

                if (searchText) {
                    const uppercaseValue = searchText.toUpperCase();
                    const searchCondition = faculty.firstname.toUpperCase().includes(uppercaseValue)
                        || faculty.lastname.toUpperCase().includes(uppercaseValue)
                        || faculty.facultyid.toString().toUpperCase().includes(uppercaseValue)
                        || (faculty.firstname.toUpperCase() + ' ' + faculty.lastname.toUpperCase()).includes(uppercaseValue);

                    if (status) {
                        return searchCondition && statusCondition;
                    }

                    return searchCondition;
                }

                if (status) {
                    return statusCondition;
                }

                return false;
            });

            if (!(searchText || status)) {
                filterPendingTieringReportData = pendingTieringReportData;
            }

            this.setState({ filterPendingTieringReportData });
        });
    }

    handleExport = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);
        const { page, pageSize, ppqResponseReportFilter, contentfulAnswers } = this.state;
        const { searchText, hcptype } = ppqResponseReportFilter;
        const { currentTarget } = event;

        if (currentTarget.id === 'PPQResponseReport') {
            //create ppqresponse report
            const data = await ReportService.downloadPPQResponseReport(page, pageSize, hcptype, searchText, contentfulAnswers);
            data.forEach(p => p.ppqsubmissiondate = moment(p.ppqsubmissiondate).format('MM-DD-YYYY'));
            ExcelService.createFromArray(currentTarget.id, data);
        }

        CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
    }
}

export default Report;