import React from "react";
import { Grid } from "@material-ui/core";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SearchFilter from "../../../Components/Search/SearchFilter";
import SearchFilterModel from "../../../Components/Search/SearchFilterModel";
import PPQResponseReportFilterModel from "./PPQResponseReportFilterModel";
import RedButton from "../../../Components/Button/RedButton";
import SingleAutoComplete from "../../../Components/Select/SingleAutoComplete";
import SelectModel from "../../../Components/Select/SelectModel";

interface Props {
    hcptypes: SelectModel[];
    ppqResponseReportFilter: PPQResponseReportFilterModel;
    onFilterChange: ((ppqResponseReportFilter: PPQResponseReportFilterModel) => void);
    onExport: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void);
}
interface State { }
class PPQResponseReportFilter extends React.Component<Props, State> {
    render() {
        const { ppqResponseReportFilter, hcptypes, onExport } = this.props;

        return (
            <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between">
                <Grid item md={11}>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justify="flex-start"
                        spacing={3}>
                        <Grid item md={4}>
                            <SearchFilter label="Search by Name or ID" value={ppqResponseReportFilter.searchText} useDelay={true}
                                onChange={this.handleSearchFilterChange} />
                        </Grid>
                        <Grid item md={2}>
                            <SingleAutoComplete id="hcptype" label="Select HCP Type" values={hcptypes} selectedValue={ppqResponseReportFilter.hcptype}
                                isRounded isRequired={false} onChange={this.handleSelectFilterChange} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={1}>
                    <RedButton id="PPQResponseReport" startIcon={<FontAwesomeIcon icon={faDownload} />} label={'Download'} onClick={onExport} />
                </Grid>
            </Grid>
        );
    }

    handleSearchFilterChange = (searchFilter: SearchFilterModel) => {
        const { ppqResponseReportFilter, onFilterChange } = this.props;

        let filterData: any = Object.assign({}, ppqResponseReportFilter);
        filterData['searchText'] = searchFilter.searchText;
        onFilterChange(filterData);
    }

    handleSelectFilterChange = (selected: SelectModel, targetId?: string) => {
        const { ppqResponseReportFilter, onFilterChange } = this.props;

        if (targetId) {
            let filterData: any = Object.assign({}, ppqResponseReportFilter);
            filterData[targetId] = selected.value;
            onFilterChange(filterData);
        }
    }
}

export default PPQResponseReportFilter;