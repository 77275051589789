import React from "react";
import { Checkbox, FormControl, FormControlLabel, Grid, IconButton } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { FacultyQuestDTO, ContentfulQuestion, ContentfulAnswer } from "../../../Models";
import { EventType } from "../../../Common/Enums";
import LightTooltip from "../../Tooltip/LightTooltip";

interface Props {
    question: ContentfulQuestion,
    answers: ContentfulAnswer[];
    selectedAnswers: FacultyQuestDTO[];
    disabled: boolean;
    isFR: boolean;
    onChange: ((selected: FacultyQuestDTO, eventType: EventType) => void);
}
interface State { }
class MultiCheckbox extends React.Component<Props, State> {
    public static defaultProps = {
        disabled: false
    };

    render() {
        const { answers, selectedAnswers, isFR } = this.props;

        let infoIconStyle: React.CSSProperties = { color: '#FFA49D' };
        let checkboxStyle: React.CSSProperties = { color: '#EE3A29' };

        if (isFR) {
            infoIconStyle.color = '#669CDD';
            checkboxStyle.color = '#004693';
        }

        return (
            <FormControl>
                <Grid container direction="row" alignItems="center" spacing={2}>
                    {answers.map((answer, i) => {
                        const selectedAnswer = selectedAnswers.find(p => p.answer === answer.answerOptions);
                        const label = answer.answerText ? answer.answerText : answer.answerOptions;

                        return (
                            <Grid item key={`MSC-${i}`}>
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <FormControlLabel control={
                                            <Checkbox
                                                value={answer.answerOptions}
                                                checked={selectedAnswer?.answer === answer.answerOptions}
                                                onChange={this.handleChange}
                                                style={checkboxStyle}
                                            />}
                                            label={label}
                                        />
                                    </Grid>
                                    <Grid item data-html2canvas-ignore>
                                        {answer.helpText ? <LightTooltip title={answer.helpText}>
                                            <IconButton>
                                                <FontAwesomeIcon icon={faInfoCircle} size="xs" style={infoIconStyle} />
                                            </IconButton>
                                        </LightTooltip> : <></>}
                                    </Grid>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </FormControl>
        );
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const { question, answers, selectedAnswers } = this.props;
        const value = (event.target as HTMLInputElement).value;

        if (checked) {
            const selectedAnswer = answers.find(p => p.answerOptions === value);

            if (selectedAnswer) {
                const answerModel: FacultyQuestDTO = {
                    id: 0,
                    facultyid: 0,
                    questionno: selectedAnswer.qid,
                    question: question.question,
                    answer: selectedAnswer.answerOptions,
                    answerid: selectedAnswer.id,
                    points: selectedAnswer.point,
                    createdby: '',
                    createdon: '',
                };

                this.props.onChange(answerModel, EventType.ADD);
            }
        } else {
            const selectedAnswer = selectedAnswers.find(p => p.answer === value);

            if (selectedAnswer) {
                this.props.onChange(selectedAnswer, EventType.REMOVE);
            }
        }
    }
}

export default MultiCheckbox;