import React from "react";
import { Grid, Box, Divider } from "@material-ui/core";

import PageHeader from "../../Components/Text/PageHeader";
import Alert from "../../Components/Alert/Alert";

import { FacultyPPQDataDTO, AppConfigDTO, BulkPPQResponseDTO } from "../../Models";
import { FacultyPPQDataService, AppConfigService, CustomEventHandlerService } from "../../Services";
import { FormType, TabModel } from "./TabModel";
import TabBar from "./TabBar";
import AppConfigForm from "./AppConfig/AppConfigForm";
import PPQExceptionFilterModel from "./PPQException/PPQExceptionFilterModel";
import { CustomEventType } from "../../Common/Enums";

interface Props { }
interface State {
    isLoading: boolean;
    showForm: FormType;
    currentTab: TabModel;
    ppqExceptions: FacultyPPQDataDTO[];
    filterPPQExceptions: FacultyPPQDataDTO[];
    appConfigs: AppConfigDTO[];
    appConfigFormDTO: AppConfigDTO;
    ppqExceptionFilter: PPQExceptionFilterModel;
    ppqBulkUploadResponses: BulkPPQResponseDTO[];
    ppqBulkUploadRefresh: boolean;
}
class ActionItem extends React.Component<Props, State> {
    readonly Tabs: TabModel[] = [
        {
            name: 'PPQ Exceptions',
            type: 'PPQExceptions',
            index: 0,
        },
        {
            name: 'Application Config Setup',
            type: 'AppConfigSetup',
            index: 1,
        },
        {
            name: 'PPQ Bulk Upload',
            type: 'PPQBulkUpload',
            index: 2,
        },
    ];

    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            isLoading: true,
            showForm: 'None',
            currentTab: this.Tabs[0],
            ppqExceptions: [],
            ppqBulkUploadResponses: [],
            filterPPQExceptions: [],
            appConfigs: [],
            appConfigFormDTO: {} as AppConfigDTO,
            ppqExceptionFilter: {
                searchText: '',
            },
            ppqBulkUploadRefresh: false,
        };
    }

    async componentDidMount() {
        CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);

        const ppqExceptions = await FacultyPPQDataService.getAllActionItems();
        const appConfigs = await AppConfigService.getAll();
        const ppqBulkUploadResponses = await FacultyPPQDataService.getPPQBulkUploads();

        this.setState({
            ppqExceptions, filterPPQExceptions: ppqExceptions,
            appConfigs, ppqBulkUploadResponses,
            isLoading: false
        }, () => {
            CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
        });
    }

    render() {
        const {
            currentTab, showForm,
            filterPPQExceptions,
            appConfigs, appConfigFormDTO, ppqBulkUploadResponses,
            ppqExceptionFilter, ppqBulkUploadRefresh,
        } = this.state;

        return (
            <Box m={2}>
                <Grid
                    container
                    direction="row"
                    alignItems="flex-start"
                >
                    <Grid item md={4}>
                        <PageHeader label="Manage PPQ Alerts" />
                    </Grid>
                    <Grid item>
                        <Alert />
                    </Grid>
                </Grid>

                <Divider orientation="horizontal" style={{ marginTop: 10, marginBottom: 10 }} />

                {showForm === this.Tabs[1].type ?
                    <AppConfigForm
                        appConfig={appConfigFormDTO} onCancelClick={this.handleCancelClick} onSubmit={this.handleAppConfigFormSubmit} />
                    : <></>}

                {showForm !== 'None' ?
                    <Divider orientation="horizontal" style={{ marginTop: 10, marginBottom: 10 }} />
                    : <></>}

                <TabBar
                    currentTab={currentTab} tabs={this.Tabs} onTabChange={this.handleTabChange}
                    ppqExceptions={filterPPQExceptions}
                    ppqBulkUploadResponses={ppqBulkUploadResponses} ppqBulkUploadRefresh={ppqBulkUploadRefresh}
                    onBulkUploadRefreshClick={this.handleBulkUploadRefreshClick} onStartBatch={this.handleStartBatch}
                    appConfigs={appConfigs} onAppConfigEditClick={this.handleAppConfigEditClick}
                    ppqExceptionFilter={ppqExceptionFilter} onExceptionFilterChange={this.handleExceptionFilterChange} />
            </Box>
        );
    }

    handleTabChange = (selectedTab: TabModel) => {
        this.setState({ currentTab: selectedTab, showForm: 'None' });
    }

    handleCancelClick = (event: React.MouseEvent<HTMLButtonElement> | undefined) => {
        this.setState({ showForm: 'None' });
    }

    handleAppConfigFormSubmit = (appConfig: AppConfigDTO) => {
        this.setState({ isLoading: true }, async () => {
            const existingAppConfig = await AppConfigService.edit(appConfig);

            const { appConfigs } = this.state;

            if (existingAppConfig) {
                const index = appConfigs.findIndex(p => p.id === appConfig.id);
                appConfigs[index] = appConfig;
            }

            this.setState({ appConfigs, appConfigFormDTO: appConfig, isLoading: false });
        });
    }

    handleAppConfigEditClick = (appConfig: AppConfigDTO) => {
        this.setState({ showForm: 'None' }, () => {
            this.setState({ showForm: 'AppConfigSetup', appConfigFormDTO: appConfig });
        });
    }

    handleExceptionFilterChange = (ppqExceptionFilter: PPQExceptionFilterModel) => {
        this.setState({ ppqExceptionFilter }, () => {
            const { ppqExceptionFilter, ppqExceptions } = this.state;
            const { searchText } = ppqExceptionFilter;

            let filterPPQExceptions = ppqExceptions.filter(ppqException => {
                if (searchText) {
                    const uppercaseValue = searchText.toUpperCase();
                    const searchCondition = ppqException.firstname.toUpperCase().includes(uppercaseValue)
                        || ppqException.lastname.toUpperCase().includes(uppercaseValue)
                        || ppqException.hcptype.toString().toUpperCase().includes(uppercaseValue)
                        || ppqException.specialty.toString().toUpperCase().includes(uppercaseValue)
                        || (ppqException.firstname.toUpperCase() + ' ' + ppqException.lastname.toUpperCase()).includes(uppercaseValue);

                    return searchCondition;
                }

                return false;
            });

            if (!(searchText)) {
                filterPPQExceptions = ppqExceptions;
            }

            this.setState({ filterPPQExceptions });
        });
    }

    handleStartBatch = async () => {
        await FacultyPPQDataService.startPPQBulkUpload();
        this.setState({ ppqBulkUploadRefresh: true });
    }

    handleBulkUploadRefreshClick = async () => {
        const ppqBulkUploadResponses = await FacultyPPQDataService.getPPQBulkUploads(true);
        this.setState({ ppqBulkUploadResponses });
    }
}

export default ActionItem;