import React from 'react';
import { Box, Card, CardContent } from '@material-ui/core';

import Header from './Header';
import Footer from './Footer';
import BackdropLoader from '../Loader/BackdropLoader';
import { AuthProviderService, CustomEventHandlerService } from '../../Services';
import { CustomEventType, UserRole } from '../../Common/Enums';
import Utils from '../../Common/Utils';

interface Props {
    showMenu: boolean;
}
interface State {
    showMenu: boolean;
    isLoading: boolean;
}
class Layout extends React.Component<Props, State> {
    public static defaultProps = {
        showMenu: false,
    };

    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            isLoading: true,
            showMenu: props.showMenu,
        };
    }

    async componentDidMount() {
        CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);
        await this.checkAdminUser();
    }

    async componentDidUpdate() {
        await this.checkAdminUser();
    }

    render() {
        const { showMenu } = this.state;

        return (
            <div style={{ backgroundColor: '#F5F5F5' }}>
                <Header showMenu={showMenu} />
                <Box>
                    <Card style={{ margin: 20 }}>
                        <CardContent>
                            <BackdropLoader />
                            {this.props.children}
                        </CardContent>
                    </Card>
                    <Footer />
                </Box>
            </div>
        )
    }

    checkAdminUser = async () => {
        const isLoggedIn = await AuthProviderService.isLoggedIn();

        if (isLoggedIn) {
            const isAdminUser = await AuthProviderService.isAdminUser();
            const authModel = await AuthProviderService.getAccessToken();
            const accessToken = Utils.decodeJWT<any>(authModel.accessToken);

            if (isAdminUser) {
                Utils.setUserRole(UserRole.LILLY_ADMIN);
            } else {
                Utils.setUserRole(UserRole.LILLY_USER);
            }

            Utils.setUserEmail(accessToken.preferred_username);

            if (this.state.isLoading) {
                // Pages responsible to stop loading
                // CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
                this.setState({ showMenu: true, isLoading: false });
            }
        }
    }
}

export default Layout;