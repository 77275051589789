import ProQuestAPIService from "./ProQuestAPIService";

import { HCPTypeDTO } from "../Models";
import SelectModel from "../Components/Select/SelectModel";

class HCPTypeService extends ProQuestAPIService {
    async getAll(): Promise<HCPTypeDTO[]> {
        const response = await this.GetAsync<HCPTypeDTO[]>('hcptype/getall');

        let hcptypes: HCPTypeDTO[] = [];

        if (response.isSuccess) {
            hcptypes = response.data;
        }

        return hcptypes;
    }

    async getAllForSelect(): Promise<SelectModel[]> {
        const hcpTypes = await this.getAll();

        return hcpTypes.map((hcpType) => {
            const selectModel = new SelectModel();
            selectModel.text = hcpType.hcptype;
            selectModel.value = hcpType.hcptype;
            return selectModel;
        });
    }
}

export default new HCPTypeService();