import ProQuestAPIService from "./ProQuestAPIService";
import CustomEventHandlerService from './CustomEventHandlerService';

import { SpecialtyDTO } from "../Models";
import SelectModel from '../Components/Select/SelectModel';
import { CustomEventType } from '../Common/Enums';

class SpecialtyService extends ProQuestAPIService {
    async getAll(): Promise<SpecialtyDTO[]> {
        const response = await this.GetAsync<SpecialtyDTO[]>('specialty/getall');

        let specialties: SpecialtyDTO[] = [];

        if (response.isSuccess) {
            specialties = response.data;
        }

        return specialties;
    }

    async add(specialty: SpecialtyDTO): Promise<SpecialtyDTO | null> {
        const response = await this.PostAsync<SpecialtyDTO>('specialty/create', specialty);

        if (response.isSuccess) {
            CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, response.message);
            return response.data;
        }
        else {
            let message = response.message;

            CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, message);
            return null;
        }
    }

    async edit(specialty: SpecialtyDTO): Promise<SpecialtyDTO | null> {
        const response = await this.PutAsync<SpecialtyDTO>(`specialty/update/${specialty.specialtyid}`, specialty);

        if (response.isSuccess) {
            CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, response.message);
            return response.data;
        }
        else {
            let message = response.message;

            CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, message);
            return null;
        }
    }

    async delete(specialty: SpecialtyDTO): Promise<boolean> {
        const response = await this.DeleteAsync<null>(`specialty/delete/${specialty.specialtyid}`);

        if (response.isSuccess) {
            CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, 'The Specialty has been deleted successfully.');
        }
        else {
            let message = response.message;

            CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, message);
        }

        return response.isSuccess;
    }

    async getAllForSelect(): Promise<SelectModel[]> {
        const specialties = await this.getAll();

        return specialties.map((specialty) => {
            const selectModel = new SelectModel();
            selectModel.text = specialty.specialty;
            selectModel.value = specialty.specialty;
            return selectModel;
        });
    }
}

export default new SpecialtyService();