import React from 'react';
import { AppBar, Toolbar } from '@material-ui/core';

import FRLogo from '../../Assets/FR_Logo.png';

interface Props { }
interface State { }
class Header extends React.Component<Props, State> {
    render() {
        return (
            <AppBar position="static"
                style={{
                    backgroundColor: '#FFFFFF', boxShadow: 'none',
                    borderBottom: '1px solid #DEDEDE'
                }}>
                <Toolbar>
                    <img src={FRLogo} alt="logo" style={{ margin: 10 }} />
                </Toolbar>
            </AppBar>
        )
    }
}

export default Header;