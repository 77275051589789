import React from "react";
import { Grid } from "@material-ui/core";

import { FacultyPPQDataDTO } from "../../../Models";

import SearchFilter from "../../../Components/Search/SearchFilter";
import SearchFilterModel from "../../../Components/Search/SearchFilterModel";
import ExportToExcel from "../../../Components/File/ExportToExcel";
import InlineDatePicker from "../../../Components/Date/InlineDatePicker";
import SubmissionDateReportFilterModel from "./SubmissionDateReportFilterModel";

interface Props {
    submissionDateReportData: FacultyPPQDataDTO[];
    submissionDateReportFilter: SubmissionDateReportFilterModel;
    onFilterChange: ((submissionDateReportFilter: SubmissionDateReportFilterModel) => void);
}
interface State { }
class SubmissionDateReportFilter extends React.Component<Props, State> {
    render() {
        const {
            submissionDateReportData, submissionDateReportFilter,
        } = this.props;

        const dataToExport = submissionDateReportData.map(submissionDateData => ({
            facultyid: submissionDateData.facultyid,
            ppqsubmissiondate: submissionDateData.ppqsubmissiondate,
            firstname: submissionDateData.firstname,
            lastname: submissionDateData.lastname,
            hcptype: submissionDateData.hcptype,
            specialty: submissionDateData.specialty,
            points: submissionDateData.points,
            lillygroup: submissionDateData.lillygroup,
            lillyrate: submissionDateData.lillyrate,
            lillytier: submissionDateData.lillytier,
            bigroup: submissionDateData.bigroup,
            birate: submissionDateData.birate,
            bitier: submissionDateData.bitier,
        }));

        return (
            <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between">
                <Grid item md={11}>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justify="flex-start"
                        spacing={3}>
                        <Grid item md={4}>
                            <SearchFilter label="Search by HCP Type or Specialty Name" value={submissionDateReportFilter.searchText}
                                onChange={this.handleSearchFilterChange} />
                        </Grid>
                        <Grid item md={2}>
                            <InlineDatePicker id="startDate" label="Start Date" selectedDate={submissionDateReportFilter.startDate}
                                onChange={this.handleDateFilterChange} />
                        </Grid>
                        <Grid item md={2}>
                            <InlineDatePicker id="endDate" label="End Date" selectedDate={submissionDateReportFilter.endDate}
                                onChange={this.handleDateFilterChange} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={1}>
                    <ExportToExcel fileName="SubmissionDateReport" data={dataToExport} />
                </Grid>
            </Grid>
        );
    }

    handleSearchFilterChange = (searchFilter: SearchFilterModel) => {
        const { submissionDateReportFilter, onFilterChange } = this.props;

        submissionDateReportFilter.searchText = searchFilter.searchText;
        onFilterChange(submissionDateReportFilter);
    }

    handleDateFilterChange = (selectedDate: Date | null, targetId?: string) => {
        const { submissionDateReportFilter, onFilterChange } = this.props;

        if (targetId) {
            let filterData: any = Object.assign({}, submissionDateReportFilter);
            filterData[targetId] = selectedDate;
            onFilterChange(filterData);
        }
    }
}

export default SubmissionDateReportFilter;