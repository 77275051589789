import React from "react";
import { Box, Grid } from "@material-ui/core";

import { DashboardService } from "../../Services";
import { DashboardModel } from "../../Models";
import PageHeader from "../../Components/Text/PageHeader";
import Doughnut from "../../Components/Chart/Doughnut";
import DashboardCard from "./DashboardCard";
import DashboardTable from "./DashboardTable";

interface Props { }
interface State {
    dashboardData: DashboardModel;
}
class Dashboard extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            dashboardData: {
                charts: [],
                groupTierHCPData: [],
            },
        };
    }

    componentDidMount() {
        this.loadData();
    }

    render() {
        const { dashboardData } = this.state;

        return (
            <Box m={2}>
                <Grid
                    container
                    direction="row"
                    alignItems="flex-start"
                    spacing={2}
                >
                    <Grid item md={12}>
                        <PageHeader label="Dashboard" />
                    </Grid>
                    <Grid item md={12}>
                        <Grid
                            container
                            direction="row"
                            spacing={2}
                        >
                            {dashboardData.charts.map((data) => {
                                return (
                                    <Grid item md={3} key={`DC-${data.id}`}>
                                        <DashboardCard title={data.title}>
                                            <Doughnut id={data.id} data={data.data} />
                                        </DashboardCard>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                    <Grid item md={12}>
                        <Grid
                            container
                            direction="row"
                        >
                            <Grid item md={12}>
                                {dashboardData.groupTierHCPData.length > 0 ? <DashboardCard title="Number of HCPs by Group and Tier">
                                    <div style={{ paddingTop: 10 }}>
                                        <DashboardTable groupTierData={dashboardData.groupTierHCPData} />
                                    </div>
                                </DashboardCard> : null}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    loadData = async () => {
        const dashboardData = await DashboardService.getDashboardData();
        this.setState({ dashboardData });
    }
}

export default Dashboard;