import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

import RedButton from '../Button/RedButton';
import { ExcelService } from "../../Services";

interface Props {
    id?: string;
    fileName: string;
    data: any
}
interface State { }
class ExportToExcel extends React.Component<Props, State> {
    render() {
        const { id } = this.props;

        return (
            <RedButton id={id} startIcon={<FontAwesomeIcon icon={faDownload} />} label={'Download'} onClick={this.handleExport} />
        );
    }

    handleExport = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { fileName, data } = this.props;
        ExcelService.createFromArray(fileName, data);
    }
}

export default ExportToExcel;