import jwtDecode from "jwt-decode";
import moment from "moment";

import config from '../config.json';
import { FileType, DocumentType, UserRole } from "./Enums";

class Utils {
    static getDaysBetweenDates(dateOne: Date, dateTwo: Date): number {
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const diffDays = Math.round((dateTwo.getTime() - dateOne.getTime()) / oneDay);

        return diffDays;
    }

    static isEqualDates(dateOne?: Date | null, dateTwo?: Date | null): boolean {
        if (dateOne && dateTwo) {
            return dateOne.getFullYear() === dateTwo.getFullYear()
                && dateOne.getDate() === dateTwo.getDate()
                && dateOne.getMonth() === dateTwo.getMonth();
        }

        return false;
    }

    static getCurrentEnv(): any {
        if (process.env.APP_ENV) return process.env.APP_ENV;
        // Production
        if (
            window.location.hostname.includes('prod')
            || window.location.hostname === 'd11972xz21tzpt.cloudfront.net'
            || window.location.hostname === 'proquest.lilly.com'
        ) {
            return 'prod';
        }
        // Staging
        if (
            window.location.hostname.includes('qa')
            || window.location.hostname === 'dv6ywwb4cyy2o.cloudfront.net'
            || window.location.hostname === 'qa.proquest.lilly.com'
        ) {
            return 'qa';
        }
        // Develop
        return 'dev';
    }

    static getEnvVars(): any {
        const env = Utils.getCurrentEnv();
        const envVariables: any = Object.assign({}, config.Environment);
        return envVariables[env];
    }

    static isInt(value: string): boolean {
        return !isNaN(parseInt(value));
    }

    static getQueryParam(querySearchString: string, param: string): string | null {
        const query = new URLSearchParams(querySearchString);
        return query.get(param);
    }

    static decodeJWT<T>(token: string | null): T | null {
        if (token) {
            const decoded = jwtDecode<T>(token);
            return decoded;
        }

        return null;
    }

    static getEnumKeyByEnumValue(myEnum: any, enumValue: number | string): string {
        let keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
        return keys.length > 0 ? keys[0] : '';
    }

    static getFileTypeNumber(fileType: FileType): number {
        switch (fileType) {
            case FileType.PDF:
                return 0;
            case FileType.DOCX:
                return 1;
            case FileType.DOC:
                return 2;
            default:
                return - 1;
        }
    }

    static paginate<T>(array: T[], pageSize: number, pageNumber: number): T[] {
        return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    }

    static validateFileSize(sizeInMB: number, documentType: DocumentType): boolean {
        switch (documentType) {
            case DocumentType.CV:
            case DocumentType.OTHER:
                return sizeInMB < 10;
            case DocumentType.PPQPDF:
                return true;
            default:
                return false;
        }
    }

    static validateFileType(fileType: FileType, documentType: DocumentType): boolean {
        switch (documentType) {
            case DocumentType.CV:
            case DocumentType.OTHER:
                return Object.values(FileType).some(type => type === fileType);
            case DocumentType.PPQPDF:
                return true;
            default:
                return false;
        }
    }

    static getFormattedDateTime(dateTime: Date | string | null | undefined) {
        if (dateTime) {
            try {
                return moment(dateTime).format('MM-DD-YYYY h:mm a');
            } catch { }
        }

        return "";
    }

    static isIE() {
        const isEdge = window.navigator.userAgent.indexOf('Edge') !== -1;
        const isIE = window.navigator.userAgent.indexOf('Trident') !== -1 && !isEdge;
        return isIE;
    }

    static setUserRole(role: UserRole) {
        localStorage.setItem('UserRole', UserRole[role]);
    }

    static getUserRole(): UserRole | null {
        const userRole = localStorage.getItem('UserRole');

        if (userRole) {
            return UserRole[userRole as keyof typeof UserRole];
        }

        return null;
    }

    static setUserEmail(email: string) {
        localStorage.setItem('UserEmail', email);
    }

    static getUserEmail(): string | null {
        const userEmail = localStorage.getItem('UserEmail');

        if (userEmail) {
            return userEmail;
        }

        return null;
    }

    static getDate(dateTime: Date | string | null | undefined, format: string) {
        if (dateTime) {
            try {
                return moment(dateTime).format(format);
            } catch { }
        }

        return "";
    }

    static openUrlParent(url?: string | null) {
        if (url) {
            window.open(url, "_blank");
        }
    }

    static isIOS() {
        return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
    }
}

export default Utils;