import { TableCell, Typography, Grid } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";

function TableSortHeaderCell(props: any) {
  const { minWidth, label, sortId, isActive, sortType, onSort } = props;

  const defaultSortIconColor = "#959595";
  const activeSortIconColor = "#000000";

  let faArrowUpColor = defaultSortIconColor;
  let faArrowDownColor = defaultSortIconColor;

  if (isActive) {
    if (sortType === "asc") {
      faArrowUpColor = activeSortIconColor;
    } else {
      faArrowDownColor = activeSortIconColor;
    }
  }

  return (
    <TableCell style={{ minWidth: minWidth ?? 125 }}>
      <Grid container spacing={2} style={{ marginRight: 50 }}>
        <Grid item>
          <Typography
            style={{ fontSize: 16, fontWeight: 700, color: "#181818" }}
          >
            {label}
          </Typography>
        </Grid>
        {onSort ? (
          <Grid item>
            <Grid container style={{ paddingTop: 3, cursor: "pointer" }}>
              <Grid item>
                <FontAwesomeIcon
                  onClick={() => onSort(sortId)}
                  icon={faArrowUp}
                  color={faArrowUpColor}
                  size="xs"
                  style={{ marginBottom: 7 }}
                />
              </Grid>
              <Grid item>
                <FontAwesomeIcon
                  onClick={() => onSort(sortId)}
                  icon={faArrowDown}
                  color={faArrowDownColor}
                  size="xs"
                  style={{ marginTop: 7 }}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </TableCell>
  );
}

export default TableSortHeaderCell;