import SelectModel from "../Components/Select/SelectModel";
import CheckboxModel from "../Components/Checkbox/CheckboxModel";
import RadioModel from "../Components/RadioButton/RadioModel";

export const FunctionRequestingExceptionData: SelectModel[] = [
  {
    text: "Commercial Advising",
    value: "Commercial Advising",
  },
  {
    text: "Medical",
    value: "Medical",
  },
  {
    text: "Research",
    value: "Research",
  },
];

export const BusinessUnitRequestingExceptionData: SelectModel[] = [
  {
    text: "Neuroscience",
    value: "Neuroscience",
  },
  {
    text: "Diabetes",
    value: "Diabetes",
  },
  {
    text: "Immunology",
    value: "Immunology",
  },
  {
    text: "Lilly Research Laboratories",
    value: "Lilly Research Laboratories",
  },
  {
    text: "Oncology",
    value: "Oncology",
  },
  {
    text: "Research",
    value: "Research",
  },
  {
    text: "Other",
    value: "Other",
  },
];

export const LillyExceptionSoughtData: CheckboxModel[] = [
  {
    text: "Lilly",
    value: "lilly",
  },
];

export const BIExceptionSoughtData: CheckboxModel[] = [
  {
    text: "BI",
    value: "bi",
  },
];

export const RRByExternalPartyData: SelectModel[] = [
  {
    text: "Email",
    value: "Email",
  },
  {
    text: "Verbal Conversation",
    value: "Verbal Conversation",
  },
  {
    text: "Contract Rejected",
    value: "Contract Rejected",
  },
  {
    text: "Previous Exception Expired",
    value: "Previous Exception Expired",
  },
  {
    text: "Other",
    value: "Other",
  },
];

export const LeaderEngagementPlanData: CheckboxModel[] = [
  {
    text: "Yes",
    value: "yes",
  },
  {
    text: "No",
    value: "no",
  },
];

export const AssociatedWithActivityData: RadioModel[] = [
  {
    text: "Yes",
    value: "yes",
  },
  {
    text: "No",
    value: "no",
  },
];

export const ThirdPartyContractData: CheckboxModel[] = [
  {
    text: "Yes",
    value: "yes",
  },
  {
    text: "No",
    value: "no",
  },
];

export const BusinessBrandData: SelectModel[] = [
  {
    text: "Emgality",
    value: "Emgality",
  },
  {
    text: "Glyxambi",
    value: "Glyxambi",
  },
  {
    text: "Insulin Lispro",
    value: "Insulin Lispro",
  },
  {
    text: "Lyumjev",
    value: "Lyumjev",
  },
  {
    text: "Olumiant",
    value: "Olumiant",
  },
  {
    text: "Mounjaro",
    value: "Mounjaro",
  },
  {
    text: "Retevmo",
    value: "Retevmo",
  },
  {
    text: "Reyvow",
    value: "Reyvow",
  },
  {
    text: "Trulicity",
    value: "Trulicity",
  },
  {
    text: "Taltz",
    value: "Taltz",
  },
  {
    text: "Verzenio",
    value: "Verzenio",
  },
  {
    text: "Pirtobrutinib",
    value: "Pirtobrutinib",
  },
  {
    text: "Donanemab",
    value: "Donanemab",
  },
  {
    text: "Mirikizumab",
    value: "Mirikizumab",
  },
  {
    text: "Lebrikizumab",
    value: "Lebrikizumab",
  },
  {
    text: "Bebtelovimab",
    value: "Bebtelovimab",
  },
  {
    text: "Imlunestrant",
    value: "Imlunestrant",
  },
  {
    text: "Bamlanivimab and Etesevimab",
    value: "Bamlanivimab and Etesevimab",
  },
  {
    text: "Other",
    value: "Other",
  },
];

export const statusData: SelectModel[] = [
  {
    text: "Pending",
    value: "Pending",
  },
  {
    text: "FMV Admin Requested More Information",
    value: "FMV Admin Requested More Information",
  },
  {
    text: "Reviewed By FMV Admin",
    value: "Reviewed By FMV Admin",
  },
  {
    text: "Exception Approved By Committee Member",
    value: "Exception Approved By Committee Member",
  },
  {
    text: "Exception Denied By Committee Member",
    value: "Exception Denied By Committee Member",
  },
  {
    text: "Exception Approved-Quorum Decision",
    value: "Exception Approved-Quorum Decision",
  },
  {
    text: "Exception Cancelled By Committee Member",
    value: "Exception Cancelled By Committee Member",
  },
  {
    text: "Approved By VP",
    value: "Approved By VP",
  },
  {
    text: "Denied By VP",
    value: "Denied By VP",
  },
  {
    text: "VP Requested More Information",
    value: "VP Requested More Information",
  },
  {
    text: "Approved By Committee Member",
    value: "Approved By Committee Member",
  },
  {
    text: "Denied By Committee Member",
    value: "Denied By Committee Member",
  },
  {
    text: "Committee Member Requested More Information",
    value: "Committee Member Requested More Information",
  },
];