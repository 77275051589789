import { ValidationType } from "./Enums";

export default class ValidationUtils {
    static validate(validationType: ValidationType, value: any): { isValid: boolean, message: string } {
        switch (validationType) {
            case ValidationType.NONE:
                return { isValid: true, message: '' };
            case ValidationType.REQUIRED:
                return { isValid: value && value.length > 0, message: 'The value is required' };
            case ValidationType.RATE:
                let isValid = true;
                let message = '';

                const dotSeparatedValues = value.split('.');
                const floatValue = parseFloat(value);
                const regexp = /^\d+(\.\d{1,2})?$/;

                const isValidDecimalPlaces = regexp.test(value);
                const isNAN = isNaN(floatValue);
                const isValidInteger = dotSeparatedValues[0].length <= 5;

                if (isNAN) {
                    message = 'Input value is not a valid number';
                    isValid = false;
                } else if (!isValidDecimalPlaces) {
                    message = 'Only two decimal places are allowed';
                    isValid = false;
                } else if (!isValidInteger) {
                    message = 'Integer length should not be more than 5 number';
                    isValid = false;
                }

                return { isValid, message };
            default:
                return { isValid: false, message: 'Unknown validation type' };
        }
    }
}