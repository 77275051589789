import MenuModel from "./MenuModel";
import {
  faHome,
  faListAlt,
  faPoll,
  faDollarSign,
  faGraduationCap,
  faClipboardList,
  faFileInvoiceDollar,
  faFileMedical,
  faFileLines,
} from "@fortawesome/free-solid-svg-icons";
import { UserRole } from "../../../Common/Enums";
import Utils from "../../../Common/Utils";

const Menus: MenuModel[] = [
  {
    name: "Dashboard",
    path: "/dashboard",
    icon: faHome,
    userRoles: [UserRole.LILLY_ADMIN],
    subMenus: [],
  },
  {
    name: "Action Item",
    path: "/actionitem",
    icon: faListAlt,
    userRoles: [UserRole.LILLY_ADMIN],
    subMenus: [],
  },
  {
    name: "Questionnaire Management",
    path: "/questionnairemanagement",
    icon: faPoll,
    userRoles: [UserRole.LILLY_ADMIN],
    subMenus: [],
  },
  {
    name: "Exception Management",
    path: "/rateupdateexceptions",
    icon: faFileInvoiceDollar,
    userRoles: [UserRole.LILLY_ADMIN],
    subMenus: [],
  },
  {
    name: "Specialty / Credential Management",
    path: "/specialtycredentialmanagement",
    icon: faGraduationCap,
    userRoles: [UserRole.LILLY_ADMIN],
    subMenus: [],
  },
  {
    name: "Reports",
    path: "/reports",
    icon: faClipboardList,
    userRoles: [UserRole.LILLY_ADMIN],
    subMenus: [],
  },
  {
    name: "FMV Rate Exception Tool",
    path: "",
    icon: faDollarSign,
    userRoles: [UserRole.LILLY_ADMIN, UserRole.LILLY_USER],
    subMenus: [
      {
        name: "Submit a New Request",
        path: "/fmvform",
        icon: faFileMedical,
        userRoles: [UserRole.LILLY_ADMIN, UserRole.LILLY_USER],
        subMenus: [],
      },
      {
        name: "View My Requests",
        path: `/myrequest/${Utils.getUserEmail()}`,
        icon: faFileLines,
        userRoles: [UserRole.LILLY_ADMIN, UserRole.LILLY_USER],
        subMenus: [],
      }
    ],
  },
];

export default Menus;