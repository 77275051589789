import React from "react";
import { Box, Card, CardContent, Divider, Grid } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { FacultyPPQDataDTO } from "../../../Models";
import RateExceptionForm from "./RateExceptionForm";
import RateExceptionCard from "./RateExceptionCard";
import { CustomEventHandlerService, FacultyPPQDataService } from "../../../Services";

import Alert from "../../../Components/Alert/Alert";
import RedButton from "../../../Components/Button/RedButton";
import PageHeader from "../../../Components/Text/PageHeader";
import SearchFilter from "../../../Components/Search/SearchFilter";
import SearchFilterModel from "../../../Components/Search/SearchFilterModel";
import RateExceptionFilterModel from "./RateExceptionFilterModel";
import { CustomEventType } from "../../../Common/Enums";

interface Props { }
interface State {
    isLoading: boolean;
    showForm: boolean;
    rateExceptionFormDTO: FacultyPPQDataDTO;
    rateExceptions: FacultyPPQDataDTO[];
    filterRateExceptions: FacultyPPQDataDTO[];
    rateExceptionFilter: RateExceptionFilterModel;
}
class RateUpdateException extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            isLoading: true,
            showForm: false,
            rateExceptionFormDTO: {} as FacultyPPQDataDTO,
            rateExceptions: [],
            filterRateExceptions: [],
            rateExceptionFilter: new RateExceptionFilterModel(),
        };
    }

    async componentDidMount() {
        CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);

        const rateExceptions = await FacultyPPQDataService.getAllExceptions();
        this.setState({ rateExceptions, filterRateExceptions: rateExceptions, isLoading: false }, () => {
            CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
        });
    }

    render() {
        const { showForm, rateExceptionFormDTO, rateExceptionFilter, filterRateExceptions } = this.state;

        return (
            <Box m={2}>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                >
                    <PageHeader label="Rate Update Exceptions" />
                    <Alert />
                    <RedButton id={'Add'} startIcon={<FontAwesomeIcon icon={faPlus} />} label={'New Rate Exception'}
                        onClick={this.handleAddClick} />
                </Grid>

                <Card style={{ marginTop: 10 }}>
                    <CardContent>
                        {showForm ? <>
                            <RateExceptionForm rateException={rateExceptionFormDTO}
                                onCancelClick={this.handleCancelClick} onSubmit={this.handleRateExceptionFormSubmit} />
                            <Divider orientation="horizontal" style={{ marginTop: 15, marginBottom: 15 }} />
                        </> : <></>}

                        <Grid container style={{ marginBottom: 15 }}>
                            <Grid item xs={3}>
                                <SearchFilter label="Search by Name or ID" value={rateExceptionFilter.searchText}
                                    onChange={this.onRateExceptionFilterChange} />
                            </Grid>
                        </Grid>

                        <RateExceptionCard rateExceptions={filterRateExceptions}
                            onEdit={this.onRateExceptionEditClick} />
                    </CardContent>
                </Card>
            </Box>
        );
    }

    handleAddClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ showForm: false }, () => {
            this.setState({ showForm: true, rateExceptionFormDTO: {} as FacultyPPQDataDTO });
        });
    }

    handleCancelClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ showForm: false });
    }

    handleRateExceptionFormSubmit = (rateException: FacultyPPQDataDTO) => {
        this.setState({ isLoading: true }, async () => {
            if (rateException.id) {
                const existingUser = await FacultyPPQDataService.edit(rateException);

                const { rateExceptions } = this.state;

                if (existingUser) {
                    const index = rateExceptions.findIndex(p => p.id === rateException.id);

                    if (index === -1) {
                        rateExceptions.push(rateException);
                    } else {
                        rateExceptions[index] = rateException;
                    }
                }

                this.setState({ rateExceptions, rateExceptionFormDTO: rateException, isLoading: false });
            } else {
                const newUser = await FacultyPPQDataService.edit(rateException);

                if (newUser) {
                    this.setState(prevState => ({
                        rateExceptions: [...prevState.rateExceptions, newUser],
                        rateExceptionFormDTO: {} as FacultyPPQDataDTO,
                        isLoading: false
                    }));
                }
            }
        });
    }

    onRateExceptionEditClick = (rateException: FacultyPPQDataDTO) => {
        this.setState({ showForm: false }, () => {
            this.setState({ showForm: true, rateExceptionFormDTO: rateException });
        });
    }

    onRateExceptionFilterChange = (searchFilterModel: SearchFilterModel) => {
        const { rateExceptionFilter, rateExceptions } = this.state;

        const searchTextUpper = searchFilterModel.searchText.toUpperCase();

        const filterRateExceptions = rateExceptions.filter(rateException => {
            const fullName = `${rateException.firstname} ${rateException.lastname}`;
            if (
                rateException.firstname.toUpperCase().includes(searchTextUpper) ||
                rateException.lastname.toUpperCase().includes(searchTextUpper) ||
                fullName.toUpperCase().includes(searchTextUpper) ||
                rateException.facultyid.toString().includes(searchTextUpper)
            ) {
                return true;
            }

            return false;
        });

        rateExceptionFilter.searchText = searchFilterModel.searchText;

        this.setState({ rateExceptionFilter, filterRateExceptions });
    }
}

export default RateUpdateException;