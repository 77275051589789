import React from "react";
import { Grid, Typography, Card, CardContent } from "@material-ui/core";

import ModalDialog from "./ModalDialog";
import BlueButton from "../Button/BlueButton";
import { ContentfulAnswer } from "../../Models";
import RedButton from "../Button/RedButton";
import WhiteButton from "../Button/WhiteButton";

interface Props {
    isOpen: boolean;
    title: string;
    selected: ContentfulAnswer[];
    answers: ContentfulAnswer[];
    isFR: boolean;
    onSave: ((selectedAnswers: ContentfulAnswer[]) => void);
    onClose?: (() => void);
}
interface State {
    selectedAnswers: ContentfulAnswer[];
}
class BulkModalDialog extends React.Component<Props, State> {
    public static defaultProps = {
        isOpen: false,
    };

    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            selectedAnswers: props.selected,
        };
    }

    render() {
        const { title, isFR, answers, isOpen } = this.props;
        const { selectedAnswers } = this.state;

        let cardStyle: React.CSSProperties = {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            boxShadow: '0px 2px 6px #BCBCBC29',
            border: '1px solid #E5E5E5',
            cursor: 'pointer',
        };
        let selectedCardStyle: React.CSSProperties = {
            background: '#EBEBEB 0% 0% no-repeat padding-box',
            boxShadow: '0px 2px 6px #BCBCBC29',
            border: '1px solid #FFA49D',
            cursor: 'pointer',
        };

        if (isFR) {
            selectedCardStyle.border = '1px solid #004693';
        }

        return (
            <>
                <ModalDialog title={title} dialogWidth="xl"
                    isOpen={isOpen} onClose={this.handleClose}
                    dialogAction={
                        <Grid container
                            spacing={2}
                            direction="row"
                            justify="flex-end"
                            alignItems="center"
                            style={{ marginRight: 30 }}>
                            <Grid item>
                                {isFR ? <BlueButton label="Save and Close" onClick={this.handleSave} /> :
                                    <RedButton label="Save and Close" onClick={this.handleSave} />}
                            </Grid>
                            <Grid item>
                                <WhiteButton label="Cancel" onClick={this.handleClose} />
                            </Grid>
                        </Grid>
                    }>
                    <Grid container spacing={2} alignItems="center">
                        {answers.map((answer, i) => {
                            const contentfulAnswer = selectedAnswers.find(p => p.answerOptions === answer.answerOptions);
                            let answerCardStyle = cardStyle;

                            if (contentfulAnswer) {
                                answerCardStyle = selectedCardStyle;
                            }

                            return (
                                <Grid key={`MACCC-${i}`} item xs={4}>
                                    <Card style={answerCardStyle} onClick={() => this.handleCardClick(answer)}>
                                        <CardContent>
                                            <Grid
                                                container
                                                direction="row"
                                                alignItems="center"
                                            >
                                                <Grid item xs>
                                                    <Typography style={{ fontSize: 16, fontWeight: 400, color: '#383838' }}>{answer.answerText ? answer.answerText : answer.answerOptions}</Typography>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            );
                        })}
                    </Grid>
                </ModalDialog>
            </>
        );
    }

    handleClose = () => {
        const { onClose } = this.props;

        if (onClose) {
            onClose();
        }
    }

    handleSave = () => {
        this.handleClose();
        const { selectedAnswers } = this.state;
        this.props.onSave(selectedAnswers);
    }

    handleCardClick = (selectedValue: ContentfulAnswer) => {
        const { selectedAnswers } = this.state;
        const isAlreadySelected = selectedAnswers.find(p => p.id === selectedValue.id);

        if (isAlreadySelected) {
            this.setState(prevState => ({
                selectedAnswers: prevState.selectedAnswers.filter(p => p.id !== selectedValue.id),
            }));
        } else {
            this.setState(prevState => ({
                selectedAnswers: [...prevState.selectedAnswers, selectedValue],
            }));
        }
    }
}

export default BulkModalDialog;