import React from "react";
import { Chip, TextField, WithStyles, withStyles, Theme, Grid } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronDown as ExpandMoreIcon,
    faTimes as CloseIcon,
    faTimesCircle as CancelIcon,
} from '@fortawesome/free-solid-svg-icons';

import { FacultyQuestDTO, ContentfulQuestion, ContentfulAnswer } from "../../../Models";
import { EventType } from "../../../Common/Enums";
import BulkModalDialog from "../../Modal/BulkModalDialog";

const styles = (theme: Theme) => ({
    notchedOutline: {
        borderWidth: '1px',
        borderColor: '#FFA49D !important'
    },
    notchedOutlineFR: {
        borderWidth: '1px',
        borderColor: '#004693 !important'
    },
});

interface Props extends WithStyles<typeof styles> {
    question: ContentfulQuestion;
    answers: ContentfulAnswer[];
    selectedAnswers: FacultyQuestDTO[];
    disabled: boolean;
    isFR: boolean;
    onChange: ((selected: FacultyQuestDTO, eventType: EventType) => void);
}
interface State {
    openBulkModalDialog: boolean;
}
class MultiAutoComplete extends React.Component<Props, State> {
    public static defaultProps = {
        disabled: false,
    };

    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            openBulkModalDialog: false,
        };
    }

    render() {
        const { selectedAnswers, answers, question, isFR, classes } = this.props;
        let enableSelectModal = false;

        if (question.config) {
            if ('enableSelectModal' in question.config) {
                enableSelectModal = question.config.enableSelectModal as boolean;
            }
        }

        let values: ContentfulAnswer[] = [];

        if (selectedAnswers.length > 0) {
            selectedAnswers.map(selectedAnswer => {
                const contentfulAnswer = answers.find(p => p.answerOptions === selectedAnswer.answer);

                if (contentfulAnswer) {
                    values.push(contentfulAnswer);
                }

                return false;
            });
        }

        let infoIconStyle: React.CSSProperties = { color: '#FFA49D' };
        let checkboxStyle: React.CSSProperties = { color: '#EE3A29', marginRight: 8 };
        let arrowDropDownIconStyle: React.CSSProperties = { color: '#FFA49D' };
        let closeIconStyle: React.CSSProperties = { color: '#FFA49D' };
        let cancelIconStyle: React.CSSProperties = { color: '#FFA49D' };
        let chipStyle: React.CSSProperties = { borderColor: '#FFA49D' };
        let textFieldNotchOutline = classes.notchedOutline;

        if (isFR) {
            infoIconStyle.color = '#669CDD';
            checkboxStyle.color = '#004693';
            arrowDropDownIconStyle.color = '#004693';
            closeIconStyle.color = '#004693';
            cancelIconStyle.color = '#004693';
            chipStyle.borderColor = '#004693';
            textFieldNotchOutline = classes.notchedOutlineFR;
        }

        return (
            <Grid container>
                <Grid item md={4}>
                    <Autocomplete
                        open={enableSelectModal ? false : undefined}
                        multiple
                        limitTags={-1}
                        id={question.id.toString()}
                        options={answers}
                        getOptionLabel={(option) => option.answerText ? option.answerText : option.answerOptions}
                        value={values}
                        renderTags={(value: ContentfulAnswer[], getTagProps) =>
                            value.map((option: ContentfulAnswer, index: number) => (
                                <Chip variant="outlined" label={option.answerText ? option.answerText : option.answerOptions} {...getTagProps({ index })}
                                    style={chipStyle}
                                    deleteIcon={<FontAwesomeIcon icon={CancelIcon} size="lg" style={cancelIconStyle} data-html2canvas-ignore />} />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" placeholder={question.placeholder}
                                InputProps={
                                    {
                                        ...params.InputProps,
                                        ...{
                                            classes: {
                                                notchedOutline: textFieldNotchOutline,
                                            },
                                        }
                                    }}
                            />
                        )}
                        onChange={this.handleChange}
                        popupIcon={<FontAwesomeIcon icon={ExpandMoreIcon} size="xs" style={arrowDropDownIconStyle} data-html2canvas-ignore />}
                        closeIcon={<FontAwesomeIcon icon={CloseIcon} size="xs" style={closeIconStyle} data-html2canvas-ignore />}
                        style={{ maxWidth: '600px' }}
                        onOpen={(event: React.ChangeEvent<{}>) => {
                            if (enableSelectModal) {
                                this.handleOpen();
                            }
                        }}
                    />
                </Grid>
                {enableSelectModal && this.state.openBulkModalDialog ? <Grid item md>
                    <BulkModalDialog isFR={isFR} title={question.question}
                        selected={values} answers={answers} isOpen={this.state.openBulkModalDialog}
                        onSave={this.handleBulkSave} onClose={this.handleClose} />
                </Grid> : null}
            </Grid>
        );
    }

    handleChange = (event: React.ChangeEvent<{}> | null, value: ContentfulAnswer[]) => {
        const { question, selectedAnswers, answers } = this.props;
        const values = value;

        const answerToAdd = values.filter((value) => {
            return selectedAnswers.every((selectedAnswer) => {
                if (value.answerOptions === selectedAnswer.answer) {
                    return false;
                }

                return true;
            });
        });

        const answerToRemove = selectedAnswers.filter((selectedAnswer) => {
            const value = values.find(p => p.answerOptions === selectedAnswer.answer);

            if (value) {
                return false;
            }

            return true;
        });

        if (answerToAdd.length > 0) {
            const selectedAnswer = answers.find(p => p.answerOptions === answerToAdd[0].answerOptions);

            if (selectedAnswer) {
                const answerModel: FacultyQuestDTO = {
                    id: 0,
                    facultyid: 0,
                    questionno: selectedAnswer.qid,
                    question: question.question,
                    answer: selectedAnswer.answerOptions,
                    answerid: selectedAnswer.id,
                    points: selectedAnswer.point,
                    createdby: '',
                    createdon: '',
                };

                this.props.onChange(answerModel, EventType.ADD);
            }
        }

        if (answerToRemove.length > 0) {
            const answer = selectedAnswers.find(p => p.id === answerToRemove[0].id);

            if (answer) {
                this.props.onChange(answer, EventType.REMOVE);
            }
        }
    }

    handleBulkSave = (selectedValues: ContentfulAnswer[]) => {
        const { question, selectedAnswers, answers } = this.props;

        const answersToAdd = selectedValues.filter((value) => {
            return selectedAnswers.every((selectedAnswer) => {
                if (value.answerOptions === selectedAnswer.answer) {
                    return false;
                }

                return true;
            });
        });

        const answersToRemove = selectedAnswers.filter((selectedAnswer) => {
            const value = selectedValues.find(p => p.answerOptions === selectedAnswer.answer);

            if (value) {
                return false;
            }

            return true;
        });

        answersToAdd.map(ans => {
            const selectedAnswer = answers.find(p => p.answerOptions === ans.answerOptions);

            if (selectedAnswer) {
                const answerModel: FacultyQuestDTO = {
                    id: 0,
                    facultyid: 0,
                    questionno: selectedAnswer.qid,
                    question: question.question,
                    answer: selectedAnswer.answerOptions,
                    answerid: selectedAnswer.id,
                    points: selectedAnswer.point,
                    createdby: '',
                    createdon: '',
                };

                this.props.onChange(answerModel, EventType.ADD);
            }

            return null;
        });

        answersToRemove.map(ans => {
            const answer = selectedAnswers.find(p => p.id === ans.id);

            if (answer) {
                this.props.onChange(answer, EventType.REMOVE);
            }

            return null;
        });
    }

    handleOpen = () => {
        this.setState({ openBulkModalDialog: true });
    }

    handleClose = () => {
        this.setState({ openBulkModalDialog: false });
    }
}

export default withStyles(styles)(MultiAutoComplete);