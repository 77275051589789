import React from "react";
import { Button } from "@material-ui/core";
import { PDFService } from "../../Services";

interface Props {
    id: string;
    label: string;
    fileName: string;
    dataId: string;
}
interface State { }
class ExportToPDF extends React.Component<Props, State> {
    public static defaultProps = {
        id: 'exportToPDF'
    };

    render() {
        const { id, label } = this.props;

        return (
            <Button id={id} size="medium" variant="outlined"
                style={{ fontSize: 16, fontWeight: 400, color: '#EE3A29', borderColor: '#EE3A29', borderWidth: '1px', textTransform: 'none' }}
                onClick={this.handleExport}>{label}</Button>
        );
    }

    handleExport = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { dataId, fileName } = this.props;
        const input = document.getElementById(dataId);

        if (input) {
            PDFService.download(input, fileName);
        }
    }
}

export default ExportToPDF;