import React from "react";
import { Grid, Typography } from "@material-ui/core";

interface Props {
    isFR: boolean;
    label: string;
    value?: string | number;
}
interface State { }
class GridItem extends React.Component<Props, State> {
    public static defaultProps = {
        isFR: false,
    };

    render() {
        const { isFR, label, value } = this.props;

        return (
            <Grid item>
                <Typography style={{ fontSize: 16, fontWeight: 500, color: isFR ? '#004693' : '#5D5D5D' }}>
                    {label}
                </Typography>
                <Typography style={{ fontSize: 16, fontWeight: 400, color: isFR ? '#1A1A1A' : '#1A1A1A' }}>
                    {value}
                </Typography>
            </Grid>
        );
    }
}

export default GridItem;