import React from "react";
import { Grid } from "@material-ui/core";

import { FacultyPPQDataDTO } from "../../../Models";
import GridItem from "../../../Components/Grid/GridItem";

interface Props {
    facultyPPQData?: FacultyPPQDataDTO | null;
    totalPoints: number;
}
interface State { }
class FacultyFMVInfo extends React.Component<Props, State> {
    render() {
        const { facultyPPQData, totalPoints } = this.props;

        if (facultyPPQData) {
            return (
                <div style={{ margin: 20 }}>
                    <Grid container justify="space-between">
                        <GridItem label="Faculty ID" value={facultyPPQData.facultyid} />
                        <GridItem label="Lilly Group Tier" value={`${facultyPPQData.lillytier}${facultyPPQData.lillygroup}`} />
                        <GridItem label="BI Group Tier" value={`${facultyPPQData.bitier}${facultyPPQData.bigroup}`} />
                        <GridItem label="PPQ Points" value={totalPoints} />
                    </Grid>
                </div>
            );
        }

        return null;
    }
}

export default FacultyFMVInfo;