import React from "react";
import { Grid } from "@material-ui/core";

import { FacultyPPQDataDTO } from "../../../Models";

import SearchFilter from "../../../Components/Search/SearchFilter";
import SearchFilterModel from "../../../Components/Search/SearchFilterModel";
import ExportToExcel from "../../../Components/File/ExportToExcel";
import PayerReportFilterModel from "./PayerReportFilterModel";

interface Props {
    payerReportData: FacultyPPQDataDTO[];
    payerReportFilter: PayerReportFilterModel;
    onFilterChange: ((payerReportFilter: PayerReportFilterModel) => void);
}
interface State { }
class PayerReportFilter extends React.Component<Props, State> {
    render() {
        const {
            payerReportData, payerReportFilter,
        } = this.props;

        const dataToExport = payerReportData.map(payerData => ({
            facultyid: payerData.facultyid,
            ppqsubmissiondate: payerData.ppqsubmissiondate,
            firstname: payerData.firstname,
            lastname: payerData.lastname,
            hcptype: payerData.hcptype,
            specialty: payerData.specialty,
            points: payerData.points,
            lillygroup: payerData.lillygroup,
            lillyrate: payerData.lillyrate,
            lillytier: payerData.lillytier,
            bigroup: payerData.bigroup,
            birate: payerData.birate,
            bitier: payerData.bitier,
        }));

        return (
            <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between">
                <Grid item md={11}>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justify="flex-start"
                        spacing={3}>
                        <Grid item md={4}>
                            <SearchFilter label="Search by HCP Type or Specialty Name" value={payerReportFilter.searchText}
                                onChange={this.handleSearchFilterChange} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={1}>
                    <ExportToExcel fileName="PayerReport" data={dataToExport} />
                </Grid>
            </Grid>
        );
    }

    handleSearchFilterChange = (searchFilter: SearchFilterModel) => {
        const { payerReportFilter, onFilterChange } = this.props;

        payerReportFilter.searchText = searchFilter.searchText;
        onFilterChange(payerReportFilter);
    }
}

export default PayerReportFilter;