import axios from 'axios';

import { AuthProviderService, HCPTokenService } from '../Services';
import config from '../config.json';
import Utils from '../Common/Utils';

class AxiosMiddleware {
    registerInterceptors() {
        const env = Utils.getEnvVars();

        if (!config.MockConfig.Enable) {
            axios.interceptors.request.use(async (config) => {
                if (config.baseURL === env.API_URL) {
                    const authTokenModel = await AuthProviderService.getAccessToken();
                    config.headers.Authorization = `Bearer ${authTokenModel.accessToken}`;
                } else if (config.baseURL === env.API_URL_HCP) {
                    const token = HCPTokenService.getToken();
                    config.headers.Authorization = `Basic ${token}`;
                }

                return config;
            });
        }
    }
}

export default new AxiosMiddleware();