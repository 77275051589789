import React from "react";
import { Grid, IconButton, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { EventType, FieldType } from "../../Common/Enums";
import { FacultyQuestDTO, ContentfulQuestion, QuestionnaireDTO } from "../../Models";

import LightTooltip from "../Tooltip/LightTooltip";
import SingleAutoComplete from "../Select/Questionnaire/SingleAutoComplete";
import MultiAutoComplete from "../Select/Questionnaire/MultiAutoComplete";
import SingleCheckbox from "../Checkbox/Questionnaire/SingleCheckbox";
import MultiCheckbox from "../Checkbox/Questionnaire/MultiCheckbox";
import RadioButton from "../RadioButton/Questionnaire/RadioButton";
import TextInputField from "../TextInput/Questionnaire/TextInputField";
import ModalDialog from "../Modal/ModalDialog";
import BlueButton from "../Button/BlueButton";
import RedButton from "../Button/RedButton";
import WhiteButton from "../Button/WhiteButton";
import QuestionnaireUtils from "../../Common/QuestionnaireUtils";

interface Props {
    questionnaire?: QuestionnaireDTO;
    isFR: boolean;
    question: ContentfulQuestion;
    answers: FacultyQuestDTO[];
    onAnswerChange: ((answer: FacultyQuestDTO, eventType: EventType) => void);
}
interface State {
    isDialogOpen: boolean;
    answer?: FacultyQuestDTO;
    eventType?: EventType;
}
class QuestionnaireFormItem extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            isDialogOpen: false,
            answer: undefined,
            eventType: undefined,
        };
    }

    renderComponent(onAnswerChange: ((answer: FacultyQuestDTO, eventType: EventType) => void)) {
        const { isFR, question, answers } = this.props;
        let answerComponent = null;

        switch (question.responseType) {
            case FieldType[FieldType.SingleCheckbox]:
                answerComponent = <SingleCheckbox
                    isFR={isFR}
                    selectedAnswer={answers[0]}
                    question={question}
                    answers={question.questionAnswers}
                    onChange={onAnswerChange} />;
                break;
            case FieldType[FieldType.MultiCheckbox]:
                answerComponent = <MultiCheckbox
                    isFR={isFR}
                    selectedAnswers={answers}
                    question={question}
                    answers={question.questionAnswers}
                    onChange={onAnswerChange} />;
                break;
            case FieldType[FieldType.SingleDropdown]:
                answerComponent = <SingleAutoComplete
                    isFR={isFR}
                    selectedAnswer={answers[0]}
                    question={question}
                    answers={question.questionAnswers}
                    onChange={onAnswerChange} />;
                break;
            case FieldType[FieldType.MultiDropdown]:
                answerComponent = <MultiAutoComplete
                    isFR={isFR}
                    selectedAnswers={answers}
                    question={question}
                    answers={question.questionAnswers}
                    onChange={onAnswerChange} />;
                break;
            case FieldType[FieldType.Radiobutton]:
                answerComponent = <RadioButton
                    isFR={isFR}
                    selectedAnswer={answers[0]}
                    question={question}
                    answers={question.questionAnswers}
                    onChange={onAnswerChange} />;
                break;
            case FieldType[FieldType.Textbox]:
                answerComponent = <TextInputField
                    isFR={isFR}
                    selectedAnswer={answers[0]}
                    question={question}
                    onChange={onAnswerChange} />;
                break;
            default:
                answerComponent = <Typography style={{ fontSize: 16, fontWeight: 400, color: '#1A1A1A' }}>
                    {answers[0]?.answer}
                </Typography>;
                break;
        }

        return answerComponent;
    }

    render() {
        const { isFR, question } = this.props;
        const { isDialogOpen } = this.state;

        let qStyle: React.CSSProperties = { fontSize: 16, fontWeight: 500, color: '#5D5D5D' };
        let infoIconStyle: React.CSSProperties = { color: '#FFA49D' };
        let ignorePDF = false;

        if (isFR) {
            qStyle.color = '#004693';
            infoIconStyle.color = '#669CDD';
        }

        if (question.config) {
            if ('ignorePDF' in question.config) {
                ignorePDF = question.config.ignorePDF as boolean;
            }
        }

        return (
            <Grid container direction="column" spacing={2}  {...(ignorePDF && { 'data-html2canvas-ignore': true })}>
                <Grid item xs>
                    <Grid container direction="row" alignItems="center">
                        <Grid item>
                            <Typography style={qStyle}>
                                {question.question}
                            </Typography>
                        </Grid>
                        <Grid item data-html2canvas-ignore>
                            {question.helpText ? <LightTooltip title={question.helpText}>
                                <IconButton>
                                    <FontAwesomeIcon icon={faInfoCircle} size="xs" style={infoIconStyle} />
                                </IconButton>
                            </LightTooltip> : <></>}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs>
                    <ModalDialog title={`Confirm Action`} dialogWidth="sm"
                        isOpen={isDialogOpen} onClose={this.handleCancel}
                        dialogAction={
                            <Grid container
                                spacing={2}
                                direction="row"
                                justify="flex-end"
                                alignItems="center"
                                style={{ marginRight: 30 }}>
                                <Grid item>
                                    <WhiteButton label="Cancel" onClick={this.handleCancel} />
                                </Grid>
                                <Grid item>
                                    {isFR ? <BlueButton label="Confirm" onClick={this.onConfirmClick} />
                                        : <RedButton label="Confirm" onClick={this.onConfirmClick} />}
                                </Grid>
                            </Grid>
                        }>
                        <Typography style={{ fontSize: 16, fontWeight: 400, color: '#383838' }}>
                            Are you sure you want to change ? All dependent questions will be reset.
                        </Typography>
                    </ModalDialog>
                    {this.renderComponent(this.handleAnswerChange)}
                </Grid>
            </Grid>
        );
    }

    onConfirmClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const { answer, eventType } = this.state;

        if (answer !== undefined && eventType !== undefined) {
            this.handleCancel();
            this.props.onAnswerChange(answer, eventType);
        }
    }

    handleAnswerChange = (answer: FacultyQuestDTO, eventType: EventType) => {
        const { question, questionnaire } = this.props;

        if (questionnaire) {
            const showDialog = QuestionnaireUtils.showConfirmation(question, answer, questionnaire);

            if (showDialog) {
                this.setState({ isDialogOpen: true, answer, eventType });
            } else {
                this.props.onAnswerChange(answer, eventType);
            }
        }
    }

    handleCancel = () => {
        this.setState({ isDialogOpen: false });
    }
}

export default QuestionnaireFormItem;