import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { AuthProviderService } from '../../Services';

interface Props extends RouteComponentProps<any> { }
interface State { }
class AuthCallback extends React.Component<Props, State> {
    componentDidMount() {
        AuthProviderService.handlerPromise(this.processLogin);
    }

    render() {
        return null;
    }

    processLogin = () => {
        const path = AuthProviderService.getCallbackPath();
        this.props.history.push(path);
    }
}

export default withRouter(AuthCallback);