import moment from "moment";

import ProQuestAPIService from "./ProQuestAPIService";
import S3FileService from "./S3FileService";
import CustomEventHandlerService from './CustomEventHandlerService';
import PDFService from "./PDFService";

import FileUploadModel from "../Components/File/FileUploadModel";
import Utils from "../Common/Utils";
import { DocumentType, FileType, CustomEventType } from "../Common/Enums";
import { FacultyFileDTO, FileDTO, FileMetaDataDTO } from "../Models";

class ADMINFileService extends ProQuestAPIService {
    async getAll(facultyid: number, documentType: DocumentType): Promise<FacultyFileDTO[]> {
        CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);

        const response = await this.GetAsync<FacultyFileDTO[]>(`/facultyfile/getbyid/${facultyid}`);

        if (response.isSuccess) {
            let facultyFiles = response.data;

            facultyFiles = await Promise.all(facultyFiles.filter(file => file.documenttype === documentType).map(async (facultyFile) => {
                const metaData = await this.getMetaData(facultyFile.s3filekey);

                if (metaData != null) {
                    facultyFile.fileSizeInKB = Math.round(metaData.ContentLength / 1024);
                }

                return facultyFile;
            }));

            CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);

            return facultyFiles;
        }

        CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
        return [];
    }

    async getFile(key: string, fileType: string): Promise<string | null> {
        const fileObj = {
            key,
            fileType,
        };

        const filePreSignedResponse = await this.PostAsync<FileDTO>('s3download', fileObj);

        if (filePreSignedResponse.isSuccess) {
            return filePreSignedResponse.data.presigned_url;
        }

        return null;
    }

    async uploadFile(file: FileUploadModel, documentType: DocumentType): Promise<FacultyFileDTO | null> {
        CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);

        const fileNameWithExt = file.name;

        const fileObj = {
            key: `${file.facultyId}/${documentType}/${fileNameWithExt}`,
            fileType: file.type,
        };

        const filePreSignedResponse = await this.PostAsync<FileDTO>('s3upload', fileObj);

        if (filePreSignedResponse.isSuccess) {
            // put with presigned URL
            try {
                if (file.file) {
                    const { presigned_url } = filePreSignedResponse.data;

                    const isFileUploadSuccess = await S3FileService.putAsync(presigned_url, file.type.toString(), file.file);

                    if (isFileUploadSuccess) {
                        // add entry in table
                        let facultyFileDTO: FacultyFileDTO = {
                            id: 0,
                            facultyid: file.facultyId,
                            filename: fileNameWithExt,
                            filetype: Utils.getFileTypeNumber(file.type),
                            documenttype: documentType,
                            s3filekey: fileObj.key,
                        };

                        const facultyFileResponse = await this.PostAsync<FacultyFileDTO>('facultyfile/create', facultyFileDTO);

                        if (facultyFileResponse.isSuccess) {
                            facultyFileDTO = facultyFileResponse.data;

                            CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
                            return facultyFileDTO;
                        } else {
                            throw new Error(facultyFileResponse.message);
                        }
                    } else {
                        throw new Error('Failed to upload file');
                    }
                } else {
                    throw new Error('No file to upload');
                }
            } catch (error) {
                console.log('upload-error', error);
            }
        } else {
            console.log('filePreSignedResponse', filePreSignedResponse);
        }

        CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
        return null;
    }

    async deleteFile(file: FileUploadModel, documentType: DocumentType): Promise<boolean> {
        CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);

        const fileObj = {
            key: `${file.facultyId}/${documentType}/${file.name}`,
            fileType: file.type,
        };

        const filePreSignedResponse = await this.PostAsync<FileDTO>('s3delete', fileObj);

        if (filePreSignedResponse.isSuccess) {
            try {
                const { presigned_url } = filePreSignedResponse.data;

                const isFileDeleteSuccess = await S3FileService.deleteAsync(presigned_url);

                if (isFileDeleteSuccess) {
                    const facultyFileResponse = await this.DeleteAsync<null>(`facultyfile/delete/${file.id}`);

                    if (facultyFileResponse.isSuccess) {
                        CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
                        return true;
                    } else {
                        console.log('facultyFileResponse', facultyFileResponse);
                    }
                }
            } catch (error) {
                console.log('upload-error', error);
            }
        }

        CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);
        return false;
    }

    async getMetaData(key: string): Promise<FileMetaDataDTO | null> {
        const fileObj = {
            key,
        };

        const response = await this.PostAsync<FileMetaDataDTO>('s3metadata', fileObj);

        if (response.isSuccess) {
            return response.data;
        }

        return null;
    }

    async uploadPPQPDF(facultyId: number): Promise<boolean> {
        const fileName = `${facultyId}_${moment().format()}_ppqpdf`;
        const fileNameWithExt = `${fileName}.pdf`;

        const input = document.getElementById('printPPQPDF');

        if (input) {
            const blob = await PDFService.getBlob(input);

            const file = new File([blob], fileNameWithExt);

            const fileObj = {
                key: `${facultyId}/${DocumentType.PPQPDF}/${fileNameWithExt}`,
                fileType: FileType.PDF,
            };

            const filePreSignedResponse = await this.PostAsync<FileDTO>('s3upload', fileObj);

            if (filePreSignedResponse.isSuccess) {
                const { presigned_url } = filePreSignedResponse.data;
                const isFileUploadSuccess = await S3FileService.putAsync(presigned_url, FileType.PDF.toString(), file);

                if (isFileUploadSuccess) {
                    // add entry in table
                    let facultyFileDTO: FacultyFileDTO = {
                        id: 0,
                        facultyid: facultyId,
                        filename: fileNameWithExt,
                        filetype: Utils.getFileTypeNumber(FileType.PDF),
                        documenttype: DocumentType.PPQPDF,
                        s3filekey: fileObj.key,
                    };

                    const facultyFileResponse = await this.PostAsync<FacultyFileDTO>('facultyfile/create', facultyFileDTO);

                    if (facultyFileResponse.isSuccess) {
                        return true;
                    } else {
                        console.log('uploadPPQPDF:facultyFileResponse', facultyFileResponse);
                    }
                }
            }
        }

        return false;
    }
}

export default new ADMINFileService();