import React from "react";
import { TextField, WithStyles, withStyles, Theme } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronDown as ExpandMoreIcon,
    faTimes as CloseIcon,
} from '@fortawesome/free-solid-svg-icons';

import { FacultyQuestDTO, ContentfulQuestion, ContentfulAnswer } from "../../../Models";
import { EventType } from "../../../Common/Enums";

const styles = (theme: Theme) => ({
    notchedOutline: {
        borderWidth: '1px',
        borderColor: '#FFA49D !important'
    },
    notchedOutlineFR: {
        borderWidth: '1px',
        borderColor: '#004693 !important'
    },
});

interface Props extends WithStyles<typeof styles> {
    question: ContentfulQuestion,
    answers: ContentfulAnswer[];
    selectedAnswer?: FacultyQuestDTO;
    disabled: boolean;
    isFR: boolean;
    onChange: ((selected: FacultyQuestDTO, eventType: EventType) => void);
}
interface State { }
class SingleAutoComplete extends React.Component<Props, State> {
    public static defaultProps = {
        disabled: false
    };

    render() {
        const { selectedAnswer, answers, question, isFR, classes } = this.props;

        let value: ContentfulAnswer | null = null;

        if (selectedAnswer) {
            const contentfulAnswer = answers.find(p => p.answerOptions === selectedAnswer.answer);

            if (contentfulAnswer) {
                value = contentfulAnswer;
            }
        }

        let infoIconStyle: React.CSSProperties = { color: '#FFA49D' };
        let checkboxStyle: React.CSSProperties = { color: '#EE3A29', marginRight: 8 };
        let arrowDropDownIconStyle: React.CSSProperties = { color: '#FFA49D' };
        let closeIconStyle: React.CSSProperties = { color: '#FFA49D' };
        let cancelIconStyle: React.CSSProperties = { color: '#FFA49D' };
        let textFieldNotchOutline = classes.notchedOutline;

        if (isFR) {
            infoIconStyle.color = '#669CDD';
            checkboxStyle.color = '#004693';
            arrowDropDownIconStyle.color = '#004693';
            closeIconStyle.color = '#004693';
            cancelIconStyle.color = '#004693';
            textFieldNotchOutline = classes.notchedOutlineFR;
        }

        return (
            <Autocomplete
                limitTags={2}
                id={question.id.toString()}
                options={answers}
                getOptionLabel={(option) => option.answerText ? option.answerText : option.answerOptions}
                value={value}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" placeholder={question.placeholder}
                        InputProps={
                            {
                                ...params.InputProps,
                                ...{
                                    classes: {
                                        notchedOutline: textFieldNotchOutline,
                                    },
                                }
                            }}
                    />
                )}
                onChange={this.handleChange}
                popupIcon={<FontAwesomeIcon icon={ExpandMoreIcon} size="xs" style={arrowDropDownIconStyle} data-html2canvas-ignore />}
                closeIcon={<FontAwesomeIcon icon={CloseIcon} size="xs" style={closeIconStyle} data-html2canvas-ignore />}
                style={{ maxWidth: '600px' }}
            />
        );
    }

    handleChange = (event: React.ChangeEvent<{}>, value: ContentfulAnswer | null) => {
        const { question } = this.props;


        if (value) {
            const selectedAnswer = value;

            if (selectedAnswer) {
                const answerModel: FacultyQuestDTO = {
                    id: 0,
                    facultyid: 0,
                    questionno: selectedAnswer.qid,
                    question: question.question,
                    answer: selectedAnswer.answerOptions,
                    answerid: selectedAnswer.id,
                    points: selectedAnswer.point,
                    createdby: '',
                    createdon: '',
                };

                this.props.onChange(answerModel, EventType.ADD);
            }
        }
    }
}

export default withStyles(styles)(SingleAutoComplete);