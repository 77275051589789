import React from "react";
import { Card, CardContent } from "@material-ui/core";

interface Props {
    children?: React.ReactNode;
    index: number;
    value: number;
    style?: React.CSSProperties;
}
interface State { }
class TabPanel extends React.Component<Props, State> {
    render() {
        const { children, value, index, ...other } = this.props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`tabpanel-${index}`}
                aria-labelledby={`tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Card style={{
                        background: '#FFFFFF 0% 0% no-repeat padding-box',
                        border: '1px solid #E5E5E5',
                    }}>
                        <CardContent>
                            {children}
                        </CardContent>
                    </Card>
                )}
            </div>
        )
    }
}

export default TabPanel;