import React from 'react';
import { Typography, Grid } from '@material-ui/core';

import ManagementCard from '../ManagementCard';
import IconButton from '../../../Components/Button/IconButton';
import { BIRateDTO } from "../../../Models";

interface Props {
    birates: BIRateDTO[],
    onEdit: ((birate: BIRateDTO) => void);
    onDelete: ((birate: BIRateDTO) => void);
}
interface State { }
class BIRateCard extends React.Component<Props, State> {
    render() {
        const { birates } = this.props;

        return (
            <Grid container spacing={2}>
                {birates.map((birate, i) => {
                    return (
                        <Grid key={`BIRC-${i}`} item xs={4}>
                            <ManagementCard>
                                <Grid
                                    container
                                    direction="row"
                                    justify="space-between"
                                    alignItems="center"
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="center"
                                        justify="space-between">
                                        <Grid item>
                                            <Typography style={{ fontSize: 18, fontWeight: 500, color: '#181818' }}>{birate.hcptype}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <IconButton iconType="Edit" onClick={() => this.onEditClick(birate)} />
                                            <IconButton iconType="DeleteDialog" onClick={() => this.onDeleteClick(birate)} />
                                        </Grid>
                                    </Grid>
                                    <Typography style={{ fontSize: 16, fontWeight: 500, color: '#606060', marginTop: 15, marginBottom: 15 }}>{birate.specialty}</Typography>
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="center"
                                        style={{ marginTop: 15, marginBottom: 5 }}>
                                        <Typography
                                            style={{ fontSize: 16, fontWeight: 400, color: '#f27266' }}>BI Current Group : {birate.bigroup}</Typography>
                                        <Typography style={{ marginLeft: 10, marginRight: 10 }}>|</Typography>
                                        <Typography
                                            style={{ fontSize: 16, fontWeight: 400, color: '#f27266' }}>BI Future Group : {birate.bifuturegroup}</Typography>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="center"
                                        style={{ marginBottom: 15 }}>
                                        <Typography
                                            style={{ fontSize: 16, fontWeight: 400, color: '#f27266' }}>Lilly Tier : {birate.bitier}</Typography>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="center">
                                        <Typography style={{ fontSize: 14, fontWeight: 400, color: '#595959' }}>Current Year : {birate.bicurrentyear}</Typography>
                                        <Typography variant="subtitle1" style={{ marginLeft: 10, marginRight: 10 }}>|</Typography>
                                        <Typography style={{ fontSize: 14, fontWeight: 400, color: '#595959' }}>Current Year Rate : ${birate.bicurrentrate}</Typography>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="center">
                                        <Typography style={{ fontSize: 14, fontWeight: 400, color: '#595959' }}>Future Year : {birate.bifutureyear}</Typography>
                                        <Typography variant="subtitle1" style={{ marginLeft: 10, marginRight: 10 }}>|</Typography>
                                        <Typography style={{ fontSize: 14, fontWeight: 400, color: '#595959' }}>Future Year Rate : ${birate.bifuturerate}</Typography>
                                    </Grid>
                                </Grid>
                            </ManagementCard>
                        </Grid>
                    );
                })}
            </Grid>
        );
    }

    onEditClick(birate: BIRateDTO) {
        this.props.onEdit(birate);
    }

    onDeleteClick(birate: BIRateDTO) {
        this.props.onDelete(birate);
    }
}

export default BIRateCard;
