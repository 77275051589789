import React from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@material-ui/core";

import TableHeaderCell from "../../../Components/Table/TableHeaderCell";
import TableBodyCell from "../../../Components/Table/TableBodyCell";
import StyledTableRow from "../../../Components/Table/StyledTableRow";

interface Props {
  groupTierData: any;
}
interface State {}
class DashboardBrandTable extends React.Component<Props, State> {
  render() {
    const { groupTierData } = this.props;

    return (
      <TableContainer component={Paper} style={{height: 350}}>
        <Table>
          <TableHead style={{ backgroundColor: "#EEEEEE" }}>
            <TableRow>
              <TableHeaderCell label="Brands" />
              <TableHeaderCell label="Brand Count" />
            </TableRow>
          </TableHead>
          <TableBody >
            {groupTierData.map((row: any, i: any) => (
              <StyledTableRow key={i}>
                <TableBodyCell label={row.name} />
                <TableBodyCell label={row.value} />
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

export default DashboardBrandTable;