/* eslint-disable no-restricted-globals */
import html2canvas, { Options } from 'html2canvas';
import jspdf from 'jspdf';

import Utils from '../Common/Utils';

class PDFService {
    private async createPDF(input: HTMLElement) {
        const isIOS = Utils.isIOS();
        let HTML_Height = input.offsetHeight;
        let customCanvasHeight = HTML_Height;

        if (screen.width < 1024) {
            const viewport = document.getElementsByName('viewport')[0];
            if (viewport) {
                viewport.setAttribute("content", "width=1280, initial-scale=1");
            }
        }

        if (!isIOS) {
            HTML_Height = input.offsetHeight;
        }

        const HTML_Width = input.offsetWidth;

        const top_left_margin = 0;
        const PDF_Width = HTML_Width;//+(top_left_margin*2);
        const PDF_Height = HTML_Height;//900;//(PDF_Width*1.5);//+(top_left_margin*2);
        const canvas_image_width = HTML_Width;
        const canvas_image_height = HTML_Height;

        const totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

        const options: Partial<Options> = {
            scale: 2,
            allowTaint: true,
            scrollX: 0,
            scrollY: -window.scrollY,
            onclone: (document: Document, element: HTMLElement) => {
                const ppqDateTimeStampElement = document.getElementById('ppqPDFDateTimeStamp');

                if (ppqDateTimeStampElement) {
                    ppqDateTimeStampElement.style.display = 'block';
                }
            },
        };

        if (isIOS) {
            options.height = customCanvasHeight;
            options.windowHeight = customCanvasHeight;
        }

        const canvas = await html2canvas(input, options);
        canvas.getContext('2d');

        const imgData = canvas.toDataURL("image/jpeg", 1.0);

        // download image
        // let downloadLink = document.createElement('a');
        // downloadLink.setAttribute('download', 'CanvasAsImage.png');
        // canvas.toBlob(function (blob) {
        //     if (blob) {
        //         let url = URL.createObjectURL(blob);
        //         downloadLink.setAttribute('href', url);
        //         downloadLink.click();
        //     }
        // });

        const pdf = new jspdf('p', 'pt', [PDF_Width, PDF_Height]);
        pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);

        for (var i = 1; i <= totalPDFPages; i++) {
            pdf.addPage([PDF_Width, PDF_Height]);
            pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
        }

        if (screen.width < 1024) {
            const viewport = document.getElementsByName('viewport')[0];
            if (viewport) {
                viewport.setAttribute("content", "width=device-width, initial-scale=1");
            }
        }

        return pdf;
    }

    async download(input: HTMLElement, fileName: string) {
        const pdf = await this.createPDF(input);
        pdf.save(fileName);
    }

    async getBlob(input: HTMLElement) {
        const pdf = await this.createPDF(input);
        return pdf.output('blob');
    }
}

export default new PDFService();