import PublicAPIService from "./PublicAPIService";

class S3FileService {
    async putAsync(preSignedURL: string, contentType: string, fileData: any) {
        try {
            const publicAPIService = new PublicAPIService();
            const response = await publicAPIService.PutAsync(preSignedURL,
                { 'Content-Type': contentType }, fileData);

            if (response.status === 200) {
                return true;
            }
        } catch (error) {
            console.log('S3FileService:putAsync:error', error);
        }

        return false;
    }

    async deleteAsync(preSignedURL: string) {
        try {
            const publicAPIService = new PublicAPIService();
            const response = await publicAPIService.DeleteAsync(preSignedURL);

            if (response.status === 204) {
                return true;
            }
        } catch (error) {
            console.log('S3FileService:deleteAsync:error', error);
        }

        return false;
    }
}

export default new S3FileService();