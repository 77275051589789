import styled from "styled-components";
import { Grid, Typography } from "@material-ui/core";

export const Header= styled(Typography)`
  background-color: #ee3a29;
  color:white;
  padding:10px 10px 10px 10px;
  font-family: "Times New Roman";
  display:flex;
  align-content: center;
  justify-content: center;
`;

export const StyleMenu = styled.span`
  color: purple;
`;

export const StyleGrid = styled(Grid)`
  
  margin-top: 5px;
`;