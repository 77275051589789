import React from 'react';
import { Typography, Grid } from '@material-ui/core';

import ManagementCard from '../ManagementCard';
import IconButton from '../../../Components/Button/IconButton';
import { SpecialtyDTO } from "../../../Models";

interface Props {
    specialties: SpecialtyDTO[],
    onEdit: ((specialty: SpecialtyDTO) => void);
    onDelete: ((specialty: SpecialtyDTO) => void);
}
interface State { }
class SpecialtyCard extends React.Component<Props, State> {
    render() {
        const { specialties } = this.props;

        return (
            <Grid container spacing={2}>
                {specialties.map((specialty, i) => {
                    return (
                        <Grid key={`SC-${i}`} item xs={4}>
                            <ManagementCard>
                                <Grid
                                    container
                                    direction="row"
                                    justify="space-between"
                                    alignItems="center"
                                >
                                    <Grid item xs>
                                        <Typography style={{ fontSize: 16, fontWeight: 400, color: '#383838' }}>{specialty.specialty}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <IconButton iconType="Edit" onClick={() => this.onEditClick(specialty)} />
                                        <IconButton iconType="DeleteDialog" onClick={() => this.onDeleteClick(specialty)} />
                                    </Grid>
                                </Grid>
                            </ManagementCard>
                        </Grid>
                    );
                })}
            </Grid>
        );
    }

    onEditClick(specialty: SpecialtyDTO) {
        this.props.onEdit(specialty);
    }

    onDeleteClick(specialty: SpecialtyDTO) {
        this.props.onDelete(specialty);
    }
}

export default SpecialtyCard;
