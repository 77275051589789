import ProQuestAPIService from "./ProQuestAPIService";
import CustomEventHandlerService from './CustomEventHandlerService';

import { AppConfigDTO } from "../Models";
import SelectModel from '../Components/Select/SelectModel';
import { CustomEventType } from '../Common/Enums';

class AppConfigService extends ProQuestAPIService {
    async getAll(): Promise<AppConfigDTO[]> {
        const response = await this.GetAsync<AppConfigDTO[]>('appconfig/getall');

        let appconfigs: AppConfigDTO[] = [];

        if (response.isSuccess) {
            appconfigs = response.data;
        }

        return appconfigs;
    }

    async edit(appconfig: AppConfigDTO): Promise<AppConfigDTO | null> {
        const response = await this.PutAsync<AppConfigDTO>(`/appconfig/update/${appconfig.appconfigname}`, appconfig);

        if (response.isSuccess) {
            CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, response.message);
            return response.data;
        }
        else {
            let message = response.message;

            CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, message);
            return null;
        }
    }

    async getAllForSelect(): Promise<SelectModel[]> {
        const appconfigs = await this.getAll();

        return appconfigs.map((appconfig) => {
            const selectModel = new SelectModel();
            selectModel.text = appconfig.appconfigname;
            selectModel.value = appconfig.appconfigvalue;
            return selectModel;
        });
    }

    getYesNoForSelect(): SelectModel[] {
        const arr: SelectModel[] = [];

        arr.push({ text: 'No', value: 'No' });
        arr.push({ text: 'Yes', value: 'Yes' });

        return arr;
    }
}

export default new AppConfigService();