import React from 'react';

import PageHeader from "../../../../Components/Text/PageHeader";
import { Box, Grid, Divider } from '@material-ui/core';

import SimpleCard from "../../../../Components/Card/SimpleCard";
import CommitteeMemberManagementForm from './CommitteeMemberManagementForm';
import { CommitteeMemberManagementDTO } from "../../../../Models"

import { CommitteeMemberManagementService, CustomEventHandlerService } from "../../../../Services";


import { CustomEventType } from '../../../../Common/Enums';
import Alert from '../../../../Components/Alert/Alert';



interface Props {
    CommitteeMembersData: CommitteeMemberManagementDTO[];
    CommitteeMember: CommitteeMemberManagementDTO;
}

interface State {
    committeemember: CommitteeMemberManagementDTO;
    CommitteeDTO: CommitteeMemberManagementDTO;
    isFormOpen: boolean;
    servicesData: CommitteeMemberManagementDTO[];
    committeeData: CommitteeMemberManagementDTO[];
    Datacommittee: CommitteeMemberManagementDTO[];
}

class CommitteeMember extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            committeemember: props.CommitteeMember,
            CommitteeDTO: {} as CommitteeMemberManagementDTO,
            isFormOpen: false,
            servicesData: [] ,
            committeeData: [],
            Datacommittee: [],
        }
    };

    async componentDidMount() {
        const servicesData = await CommitteeMemberManagementService.getAll()
        const ToData = servicesData.filter((data) => {
            return data.iscommitteemember === true
        })

        const CCData = servicesData.filter((data) => {
            return data.iscommitteemember === false
        })

        this.setState({servicesData, committeeData: ToData, Datacommittee: CCData })

    }


    render() {
        const { isFormOpen, committeeData, Datacommittee, CommitteeDTO, } = this.state;
        return(
            <Box >
                <Alert id='deleted'/>
                <Alert id='updated'/>
                <Alert id='required'/>
                <Grid>
                    <PageHeader label='Committee Member Management'/>
                </Grid>
                <Divider orientation="horizontal" style={{ marginTop: 10, marginBottom: 10 }} />
                <Grid container direction='row' alignItems='flex-start' justify='space-around' style={{marginLeft: -20}}>
                    <Grid item>
                        <SimpleCard id='to-currentMember' title={"To: Current Members"} value={committeeData} onAdd={this.handleToAddClick} onEdit={this.handleEdit} onDelete={this.handleToDeleteClick}  />
                    </Grid>
                    <Grid item>
                        <SimpleCard id='cc-currentMember' title={"CC: Current Members"} value={Datacommittee} onAdd={this.handleCCAddClick} onEdit={this.handleEdit} onDelete={this.handleCCDeleteClick}/>
                    </Grid>
                </Grid>
                {isFormOpen? <CommitteeMemberManagementForm CommitteeMember={CommitteeDTO} onCancelClick={this.handleCancelClick} onSubmit={this.handleEditSubmitClick}/> : <></>}
            </Box>
        )
    }

    handleToAddClick = () => {
        this.setState({isFormOpen:false}, () => {  
            this.setState({isFormOpen: true, CommitteeDTO: {iscommitteemember: true} as CommitteeMemberManagementDTO});            
        });
    }

    handleCCAddClick = () => {
        this.setState({isFormOpen:false}, () => {
        this.setState({isFormOpen: true, CommitteeDTO: {iscommitteemember: false} as CommitteeMemberManagementDTO});
        });
    }


    handleEdit = (committee: CommitteeMemberManagementDTO) => {
        this.setState({isFormOpen: false}, () => {
            this.setState({isFormOpen: true, CommitteeDTO: committee})
        })
    }

    handleEditSubmitClick = (committee: CommitteeMemberManagementDTO) => {

        this.setState({}, async () => {
            if(committee.id !== undefined) {
                const existingCommitteeMember = await CommitteeMemberManagementService.edit(committee, committee.id);
                const { servicesData, committeeData, Datacommittee } = this.state;
                if(existingCommitteeMember) {
                    const index = servicesData.findIndex(p => p.id === committee.id);
                    servicesData[index] = committee;

                    const filterIndex = committeeData.findIndex(p => p.id === committee.id);
                    committeeData[filterIndex] = committee;

                    const filterindex = Datacommittee.findIndex(p => p.id === committee.id);
                    Datacommittee[filterindex] = committee;
                };
                console.log('Datacommittee', Datacommittee);
                this.setState({ servicesData, committeeData, Datacommittee, CommitteeDTO: committee, isFormOpen: false })

                
            }
            else {
                const newCommitteeMember = await CommitteeMemberManagementService.add(committee);
                if(committee.firstname === undefined || committee.lastname === undefined || committee.email === undefined || committee.iscommitteemember === undefined) {
                    this.setState({isFormOpen: true});

                }
                else{
                if (newCommitteeMember ) {
                    if (committee.iscommitteemember === true) {
            
                    this.setState(prevState => ({
                        servicesData: [...prevState.servicesData, newCommitteeMember],
                        committeeData: [...prevState.committeeData, newCommitteeMember],
                        CommitteeDTO: {} as CommitteeMemberManagementDTO,
                        isFormOpen: false,
                    }));
                }
                else {
                    this.setState(prevState => ({
                        servicesData: [...prevState.servicesData, newCommitteeMember],
                        Datacommittee: [...prevState.Datacommittee, newCommitteeMember],
                        CommitteeDTO: {} as CommitteeMemberManagementDTO,
                        isFormOpen: false,
                    }));
                }
            }
                
            }

        }
        });
    }      
        

    handleToDeleteClick = (committee: CommitteeMemberManagementDTO) => {
        this.setState({CommitteeDTO: {} as CommitteeMemberManagementDTO}, async () => {
            const isSuccess = await CommitteeMemberManagementService.delete(committee, committee.id);

            const { servicesData, committeeData } = this.state;
            if (isSuccess) {
                const index = servicesData.findIndex(p => p.id === committee.id);
                servicesData.splice(index, 1);

                // const FilterIndex = Datacommittee.findIndex(p => p.id === committee.id);
                // Datacommittee.splice(FilterIndex, 1);

                const filterIndex = committeeData.findIndex(p => p.id === committee.id);
                committeeData.splice(filterIndex, 1);


                CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, {
                    alertId: "deleted",
                    message: 'The Committee Member has been deleted successfully.',
                } );
            }

            this.setState({ servicesData, committeeData });
        })
    }


    handleCCDeleteClick = (committee: CommitteeMemberManagementDTO) => {
        this.setState({CommitteeDTO: {} as CommitteeMemberManagementDTO}, async () => {
            const isSuccess = await CommitteeMemberManagementService.delete(committee, committee.id);

            const { servicesData, Datacommittee } = this.state;
            if (isSuccess) {
                const index = servicesData.findIndex(p => p.id === committee.id);
                servicesData.splice(index, 1);

                const FilterIndex = Datacommittee.findIndex(p => p.id === committee.id);
                Datacommittee.splice(FilterIndex, 1);

                // const filterIndex = committeeData.findIndex(p => p.id === committee.id);
                // committeeData.splice(filterIndex, 1);


                CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, {
                    alertId: "deleted",
                    message: 'The Committee Member has been deleted successfully.',
                } );
            }

            this.setState({ servicesData, Datacommittee });
        })
    }


    handleCancelClick = () => {
        this.setState({isFormOpen: false});
    }

}

export default CommitteeMember;