import React from "react";
import {
  FormControl,
  FormControlLabel,
  Grid,
  Checkbox,
} from "@material-ui/core";

import CheckboxModel from "./CheckboxModel";

interface Props {
  values: CheckboxModel[];
  id: string;
  selectedValue?: string | number;
  disabled: boolean;
  isChecked?: boolean;

  onChange: (selected: CheckboxModel, targetId: string) => void;
}
interface State {
  ifChecked: boolean;
}
class CheckBox extends React.Component<Props, State> {
  public static defaultProps = {
    disabled: false,
    isChecked: false,
  };

  constructor (props: Props | Readonly<Props>) {
    super(props)
    this.state = {
      ifChecked: true,
    }
  }

  render() {
    const { id, values, selectedValue, isChecked } = this.props;
    const {ifChecked} = this.state;
    //console.log(id)
    const checkboxStyle: React.CSSProperties = { color: "#EE3A29" };

    return (
      <FormControl>
        <Grid container direction="row" alignItems="center" spacing={3}>
          {values.map((value, i) => {
            return (
              <Grid item key={`RB-${i}`}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id={id}
                      value={value.value}
                      checked={selectedValue === value.value ? ifChecked : isChecked}
                      onChange={this.handleChange}
                      style={checkboxStyle}
                    />
                  }
                  label={value.text}
                />
              </Grid>
            );
          })}
        </Grid>
      </FormControl>
    );
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { values, id } = this.props;
    // const {ifChecked} = this.state;
    // console.log('selectedValue', selectedValue);
    // this.setState({ifChecked: event.target.checked})
    const SelectedValue = values.find((p) => p.value === event.target.value);
    // console.log('checkedValue', event.target.checked);
    
    if (SelectedValue) {
      this.setState({ifChecked: event.target.checked})
      const value = event.target.checked ? SelectedValue : {text: "", value: ""};
      this.props.onChange(value, id );
    }
    
  };
}

export default CheckBox;