import React from "react";
import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableBody
} from "@material-ui/core";

import { DashboardTableModel } from "../../Models";
import TableHeaderCell from "../../Components/Table/TableHeaderCell";
import TableBodyCell from "../../Components/Table/TableBodyCell";
import StyledTableRow from "../../Components/Table//StyledTableRow";

interface Props {
    groupTierData: DashboardTableModel[];
}
interface State { }
class DashboardTable extends React.Component<Props, State> {
    render() {
        const { groupTierData } = this.props;

        return (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead style={{ backgroundColor: '#EEEEEE' }}>
                        <TableRow>
                            <TableHeaderCell label="Lilly Group" />
                            <TableHeaderCell label="Tier 1" />
                            <TableHeaderCell label="Tier 2" />
                            <TableHeaderCell label="Tier 3" />
                            <TableHeaderCell label="Tier 4" />
                            <TableHeaderCell label="Tier 5" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {groupTierData.map((row, i) => (
                            <StyledTableRow key={i}>
                                <TableBodyCell label={row.lillyGroup} />
                                <TableBodyCell label={row.tier1} />
                                <TableBodyCell label={row.tier2} />
                                <TableBodyCell label={row.tier3} />
                                <TableBodyCell label={row.tier4} />
                                <TableBodyCell label={row.tier5} />
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
}

export default DashboardTable;