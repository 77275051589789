import React from "react";
import { Box, Divider, Grid, Tab, Tabs, Typography } from "@material-ui/core";

import TabPanel from "../../Components/Tab/TabPanel";
import { TabModel } from "./TabModel";

import { SpecialtyDTO, CredentialDTO, LillyRateDTO, BIRateDTO } from "../../Models";

import SpecialtyCard from "./Specialty/SpecialtyCard";
import LillyRateCard from "./LillyRate/LillyRateCard";
import BIRateCard from "./BIRate/BIRateCard";
import CredentialCard from "./Credential/CredentialCard";

import SearchFilter from "../../Components/Search/SearchFilter";
import SearchFilterModel from "../../Components/Search/SearchFilterModel";
import SingleAutoComplete from "../../Components/Select/SingleAutoComplete";
import SelectModel from "../../Components/Select/SelectModel";
import LillyRateFilterModel from "./LillyRate/LillyRateFilterModel";
import BIRateFilterModel from "./BIRate/BIRateFilterModel";
import SpecialtyFilterModel from "./Specialty/SpecialtyFilterModel";
import CredentialFilterModel from "./Credential/CredentialFilterModel";

const SearchCardDivider = () =>
    <Divider orientation="horizontal" style={{ marginTop: 15, marginBottom: 15 }} />;

const TabLabelComponent: React.FC<{ tab: TabModel, isSelected: boolean }> = ({ tab, isSelected }) => {
    return (
        <Grid container alignItems="center" justify="center">
            <Grid item>
                <Typography style={{ fontSize: 16, fontWeight: 500, color: isSelected ? '#FFFFFF' : '#383838', padding: 5 }}>
                    {tab.name}
                </Typography>
            </Grid>
        </Grid>
    );
};

interface Props {
    currentTab: TabModel;
    tabs: TabModel[];
    onTabChange: ((selectedTab: TabModel) => void);
    specialties: SpecialtyDTO[];
    credentials: CredentialDTO[];
    lillyrates: LillyRateDTO[];
    birates: BIRateDTO[];
    specialtyFilter: SpecialtyFilterModel,
    credentialFilter: CredentialFilterModel,
    lillyRateFilter: LillyRateFilterModel;
    biRateFilter: BIRateFilterModel;
    hcpTypeList: SelectModel[];
    specialtyList: SelectModel[];
    onSpecialtyEditClick: ((specialty: SpecialtyDTO) => void);
    onSpecialtyDeleteClick: ((specialty: SpecialtyDTO) => void);
    onSpecialtyFilterChange: ((searchFilterModel: SearchFilterModel) => void);
    onCredentialEditClick: ((credential: CredentialDTO) => void);
    onCredentialDeleteClick: ((credential: CredentialDTO) => void);
    onCredentialFilterChange: ((searchFilterModel: SearchFilterModel) => void);
    onLillyRateEditClick: ((lillyrate: LillyRateDTO) => void);
    onLillyRateDeleteClick: ((lillyrate: LillyRateDTO) => void);
    onLillyRateFilterChange: ((id: string, value: any) => void);
    onBIRateEditClick: ((birate: BIRateDTO) => void);
    onBIRateDeleteClick: ((birate: BIRateDTO) => void);
    onBIRateFilterChange: ((id: string, value: any) => void);
}
interface State { }
class TabBar extends React.Component<Props, State> {
    render() {
        const {
            tabs, currentTab, specialties, lillyrates, birates, credentials,
            onSpecialtyEditClick, onSpecialtyDeleteClick, onSpecialtyFilterChange,
            onLillyRateEditClick, onLillyRateDeleteClick, onLillyRateFilterChange,
            onBIRateEditClick, onBIRateDeleteClick, onBIRateFilterChange,
            onCredentialEditClick, onCredentialDeleteClick, onCredentialFilterChange,
            specialtyFilter, credentialFilter, lillyRateFilter, biRateFilter,
            hcpTypeList, specialtyList
        } = this.props;

        return (
            <Box mt={2}>
                <Tabs
                    value={currentTab.index}
                    onChange={this.handleTabChange}
                    aria-label="Specialty Credential Management Tabs"
                    style={{
                        background: '#FFFFFF 0% 0% no-repeat padding-box',
                        boxShadow: 'none',
                    }}
                    TabIndicatorProps={{
                        style: {
                            display: 'none',
                        },
                    }}
                >
                    {tabs.map((tab, i) => {
                        const tabProps = {
                            id: `full-width-tab-${i}`,
                            'aria-controls': `full-width-tabpanel-${i}`,
                        };

                        const isSelected = currentTab.index === i;

                        const tabStyle: React.CSSProperties = {
                            backgroundColor: '#F4F4F4',
                            textTransform: 'none',
                            marginRight: 10,
                            border: '1px solid #D5D5D5',
                            borderRadius: '6px 6px 0px 0px',
                        };

                        if (isSelected) {
                            tabStyle.backgroundColor = '#EE3A29';
                            tabStyle.border = 'none';
                        }

                        return (
                            <Tab {...tabProps} key={tabProps.id}
                                label={
                                    <TabLabelComponent
                                        tab={tab}
                                        isSelected={isSelected}
                                    />
                                }
                                style={tabStyle} />
                        );
                    })}
                </Tabs>
                <TabPanel value={currentTab.index} index={0}>
                    <Grid container>
                        <Grid item xs={3}>
                            <SearchFilter label="Search by Name" value={specialtyFilter.searchText}
                                onChange={onSpecialtyFilterChange} />
                        </Grid>
                    </Grid>
                    <SearchCardDivider />
                    <SpecialtyCard specialties={specialties} onEdit={onSpecialtyEditClick} onDelete={onSpecialtyDeleteClick} />
                </TabPanel>
                <TabPanel value={currentTab.index} index={1}>
                    <Grid container alignItems="center" spacing={3} style={{ marginBottom: 5 }}>
                        <Grid item xs={4}>
                            <SearchFilter label="Search by HCP Type or Specialty Name" value={lillyRateFilter.searchText}
                                onChange={(selected) => onLillyRateFilterChange('searchText', selected.searchText)} />
                        </Grid>
                        <Grid item xs={2}>
                            <SingleAutoComplete id="hcptype" label="HCP Type" values={hcpTypeList} selectedValue={lillyRateFilter.hcptype}
                                isRounded isRequired={false} onChange={(selected) => onLillyRateFilterChange('hcptype', selected.value)} />
                        </Grid>
                        <Grid item xs={2}>
                            <SingleAutoComplete id="specialty" label="Specialty" values={specialtyList} selectedValue={lillyRateFilter.specialty}
                                isRounded isRequired={false} onChange={(selected) => onLillyRateFilterChange('specialty', selected.value)} />
                        </Grid>
                    </Grid>
                    <SearchCardDivider />
                    <LillyRateCard lillyrates={lillyrates} onEdit={onLillyRateEditClick} onDelete={onLillyRateDeleteClick} />
                </TabPanel>
                <TabPanel value={currentTab.index} index={2}>
                    <Grid container alignItems="center" spacing={3} style={{ marginBottom: 5 }}>
                        <Grid item xs={4}>
                            <SearchFilter label="Search by HCP Type or Specialty Name" value={biRateFilter.searchText}
                                onChange={(selected) => onBIRateFilterChange('searchText', selected.searchText)} />
                        </Grid>
                        <Grid item xs={2}>
                            <SingleAutoComplete id="hcptype" label="HCP Type" values={hcpTypeList} selectedValue={biRateFilter.hcptype}
                                isRounded isRequired={false} onChange={(selected) => onBIRateFilterChange('hcptype', selected.value)} />
                        </Grid>
                        <Grid item xs={2}>
                            <SingleAutoComplete id="specialty" label="Specialty" values={specialtyList} selectedValue={biRateFilter.specialty}
                                isRounded isRequired={false} onChange={(selected) => onBIRateFilterChange('specialty', selected.value)} />
                        </Grid>
                    </Grid>
                    <SearchCardDivider />
                    <BIRateCard birates={birates} onEdit={onBIRateEditClick} onDelete={onBIRateDeleteClick} />
                </TabPanel>
                <TabPanel value={currentTab.index} index={3}>
                    <Grid container>
                        <Grid item xs={3}>
                            <SearchFilter label="Search by Name" value={credentialFilter.searchText}
                                onChange={onCredentialFilterChange} />
                        </Grid>
                    </Grid>
                    <SearchCardDivider />
                    <CredentialCard credentials={credentials} onEdit={onCredentialEditClick} onDelete={onCredentialDeleteClick} />
                </TabPanel>
            </Box>
        )
    }

    handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        const { tabs } = this.props;
        const selectedTab = tabs.find(p => p.index === newValue);

        if (selectedTab) {
            this.props.onTabChange(selectedTab);
        }
    }
}

export default TabBar;