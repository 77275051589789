import { FileType } from "../../Common/Enums";

export default class FileUploadModel {
    id?: number;
    facultyId: number = 0;
    name: string = '';
    sizeInKB: number = 0;
    sizeInMB: number = 0;
    type!: FileType;
    ext: string = '';
    file: File | null = null;
    s3filekey: string = '';
}