import React from 'react';
import { Typography, Grid } from '@material-ui/core';

import ManagementCard from '../ManagementCard';
import IconButton from '../../../Components/Button/IconButton';
import { CredentialDTO } from "../../../Models";

interface Props {
    credentials: CredentialDTO[],
    onEdit: ((credential: CredentialDTO) => void);
    onDelete: ((credential: CredentialDTO) => void);
}
interface State { }
class CredentialCard extends React.Component<Props, State> {
    render() {
        const { credentials } = this.props;

        return (
            <Grid container spacing={2}>
                {credentials.map((credential, i) => {
                    return (
                        <Grid key={`CC-${i}`} item xs={4}>
                            <ManagementCard>
                                <Grid
                                    container
                                    direction="row"
                                    justify="space-between"
                                    alignItems="center"
                                >
                                    <Grid item xs>
                                        <Typography style={{ fontSize: 16, fontWeight: 400, color: '#383838' }}>{credential.credential}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <IconButton iconType="Edit" onClick={() => this.onEditClick(credential)} />
                                        <IconButton iconType="DeleteDialog" onClick={() => this.onDeleteClick(credential)} />
                                    </Grid>
                                </Grid>
                            </ManagementCard>
                        </Grid>
                    );
                })}
            </Grid>
        );
    }

    onEditClick(credential: CredentialDTO) {
        this.props.onEdit(credential);
    }

    onDeleteClick(credential: CredentialDTO) {
        this.props.onDelete(credential);
    }
}

export default CredentialCard;
