import React from "react";
import { Grid } from "@material-ui/core";

import FileUploadModel from "../../Components/File/FileUploadModel";
import FileUploadCard from "../../Components/File/FileUploadCard";
interface Props {
  files: FileUploadModel[];
  onDelete: (file: FileUploadModel) => void;
  onDownload: (file: FileUploadModel) => void;
}
interface State {}
class UploadedDoc extends React.Component<Props, State> {
  render() {
    const { files, onDelete, onDownload } = this.props;

    return (
      <Grid item md={12}>
        <FileUploadCard
          files={files}
          onDelete={onDelete}
          onDownload={onDownload}
        />
      </Grid>
    );
  }
}

export default UploadedDoc;