import { TableRow, createStyles, Theme, withStyles } from "@material-ui/core";

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            '&:nth-of-type(even)': {
                backgroundColor: '#F9F2F2',
            },
        },
    }),
)(TableRow);

export default StyledTableRow;