import EmeraldAuthProvider from '../Common/EmeraldAuthProvider';
import ProQuestAPIService from './ProQuestAPIService';
import { EmployeeDTO } from '../Models';

class EmeraldService extends ProQuestAPIService {
    async searchByName(name: string): Promise<EmployeeDTO[]> {
        const { accessToken } = await EmeraldAuthProvider.getAccessToken();
        const inputDTO: any = {
            searchText: name,
        };
        const response = await this.PostAsync<EmployeeDTO[]>('employees/search', inputDTO, { 'Access-Token': accessToken });

        let employees: EmployeeDTO[] = [];

        if (response.isSuccess) {
            employees = response.data;
        }

        return employees;
    }
}

export default new EmeraldService();