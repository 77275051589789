import React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
} from "@material-ui/core";

import { EventType } from "../../Common/Enums";
import CheckboxModel from "./CheckboxModel";

interface Props {
  id: string;
  values: CheckboxModel[];
  selectedValue?: string;
  disabled: boolean;
  isFR: boolean;
  onChange: (selected: CheckboxModel, eventType: EventType) => void;
  isRequired?: boolean;
}
interface State {}
class SingleCheckbox extends React.Component<Props, State> {
  public static defaultProps = {
    disabled: false,
    isFR: false,
    isRequired: false,
  };

  render() {
    const { id, values, selectedValue, isFR, isRequired } = this.props;

    let infoIconStyle: React.CSSProperties = { color: "#FFA49D" };
    let checkboxStyle: React.CSSProperties = { color: "#EE3A29" };

    if (isFR) {
      infoIconStyle.color = "#669CDD";
      checkboxStyle.color = "#004693";
    }

    return (
      <FormControl>
        <Grid container alignItems="center" spacing={2}>
          {values.map((value, i) => {
            const label = value.text;

            return (
              <Grid item key={`SC-${id}-${i}`}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          required={isRequired}
                          value={value.value}
                          checked={selectedValue === value.value}
                          onChange={this.handleChange}
                          style={checkboxStyle}
                        />
                      }
                      label={label}
                    />
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </FormControl>
    );
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { values } = this.props;
    const value = (event.target as HTMLInputElement).value;
    const selectedValue = values.find((p) => p.value === value);

    if (selectedValue) {
      if (this.props.selectedValue === value) {
        this.props.onChange(selectedValue, EventType.REMOVE);
      } else {
        this.props.onChange(selectedValue, EventType.ADD);
      }
    }
  };
}

export default SingleCheckbox;
