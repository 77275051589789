import React from 'react';
import { Card, CardContent } from '@material-ui/core';

interface Props { }
interface State { }
class ManagementCard extends React.Component<Props, State> {
    render() {
        const { children } = this.props;

        return (
            <Card style={{
                background: '#FFFFFF 0% 0% no-repeat padding-box',
                boxShadow: '0px 2px 6px #BCBCBC29',
                border: '1px solid #E5E5E5',
            }}>
                <CardContent>
                    {children}
                </CardContent>
            </Card>

        );
    }
}

export default ManagementCard;
