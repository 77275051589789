import { AuthProvider } from '@elilillyco/spa_auth';
import Utils from './Utils';

const env = Utils.getEnvVars();

const config = {
    clientId: env.clientId,
    scopes: [`api://emerald_workforce.lilly.com/.default`],
};

const emeraldAuthProvider = new AuthProvider(config);

export default emeraldAuthProvider;
