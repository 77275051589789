import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import MenuModel from "./MenuModel";
import MenuItem from "./MenuItem";
import Menus from "./Menus";
import Utils from "../../../Common/Utils";

interface Props extends RouteComponentProps<any> { }
interface State { }
class Menu extends React.Component<Props, State> {
  render() {
    const { location } = this.props;
    const userRole = Utils.getUserRole();

    if (userRole === null) {
      return null;
    }

    return Menus.filter((p) => {
      const role = p.userRoles.find((u) => u === userRole);

      if (role) {
        return true;
      }

      return false;
    }).map((menu) => {
      menu.isSelected = menu.path === location.pathname;

      if (menu.subMenus.length > 0) {
        menu.subMenus.forEach((subMenu) => {
          subMenu.isSelected = subMenu.path === location.pathname;
        });
      }

      return (
        <MenuItem key={menu.name} menu={menu} onClick={this.handleMenuClick} />
      );
    });
  }

  handleMenuClick = (menu: MenuModel) => {
    this.props.history.push(menu.path);
    //this.props.history.go(0);
  };
}

export default withRouter(Menu);