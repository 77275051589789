import React from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import { Link } from "react-router-dom";
import { ExceptionManagementDTO } from "../../../Models";
import TableHeaderCell from "../../../Components/Table/TableHeaderCell";
import TableSortHeaderCell from "../../../Components/Table/TableSortHeaderCell";
import TableBodyCell from "../../../Components/Table/TableBodyCell";
import StyledTableRow from "../../../Components/Table/StyledTableRow";

interface Props {
  exceptionData: ExceptionManagementDTO[];
}
type SortType = "asc" | "desc";
interface State {
  sortType: SortType;
  sortId: string;
}
interface initialFilter {
  sortId: string;
  sortType: SortType;
}

class ExceptionTable extends React.Component<Props, State> {
  initialFilter: initialFilter = {
    sortId: "",
    sortType: "asc",
  };
  constructor(props: Props | Readonly<Props>) {
    super(props);
    this.state = {
      sortType: "asc",
      sortId: "",
    };
  }
  render() {
    const { exceptionData } = this.props;
    const { sortType, sortId } = this.state;
    const sortedReportData = [...exceptionData].sort((a: any, b: any) => {
      const typea = typeof a[sortId];

      if (sortType === "asc") {
        if (sortId === 'status') {
          const astatus = a.exceptiondecision
            ? a.exceptiondecision
            : a.fmvexceptioncommittestatus
              ? a.fmvexceptioncommittestatus
              : a.vpapproverstatus
                ? a.vpapproverstatus
                : a.exceptionrequeststatus
                  ? a.exceptionrequeststatus
                  : a.fmvadminteamstatus;
          const bstatus = b.exceptiondecision
            ? b.exceptiondecision
            : b.fmvexceptioncommittestatus
              ? b.fmvexceptioncommittestatus
              : b.vpapproverstatus
                ? b.vpapproverstatus
                : b.exceptionrequeststatus
                  ? b.exceptionrequeststatus
                  : b.fmvadminteamstatus;
          return astatus?.localeCompare(bstatus);
        } else if (sortId === 'createdon') {
          return +new Date(a.createdon) - +new Date(b.createdon);
        } else if (sortId === 'fmvadminteamactiondate') {
          return +new Date(a.fmvadminteamactiondate) - +new Date(b.fmvadminteamactiondate);
        } else if (sortId === 'updatedon') {
          return +new Date(a.updatedon) - +new Date(b.updatedon);
        } else {
          if (typea === "string") {
            return a[sortId].localeCompare(b[sortId]);
          } else if (typea === "number") {
            return a[sortId] - b[sortId];
          }
        }
      } else {
        if (sortId === 'status') {
          const astatus = a.exceptiondecision
            ? a.exceptiondecision
            : a.fmvexceptioncommittestatus
              ? a.fmvexceptioncommittestatus
              : a.vpapproverstatus
                ? a.vpapproverstatus
                : a.exceptionrequeststatus
                  ? a.exceptionrequeststatus
                  : a.fmvadminteamstatus;
          const bstatus = b.exceptiondecision
            ? b.exceptiondecision
            : b.fmvexceptioncommittestatus
              ? b.fmvexceptioncommittestatus
              : b.vpapproverstatus
                ? b.vpapproverstatus
                : b.exceptionrequeststatus
                  ? b.exceptionrequeststatus
                  : b.fmvadminteamstatus;
          return bstatus?.localeCompare(astatus);
        } else if (sortId === 'createdon') {
          return +new Date(b.createdon) - +new Date(a.createdon);
        } else if (sortId === 'fmvadminteamactiondate') {
          return +new Date(b.fmvadminteamactiondate) - +new Date(a.fmvadminteamactiondate);
        } else if (sortId === 'updatedon') {
          return +new Date(b.updatedon) - +new Date(a.updatedon);
        } else {
          if (typea === "string") {
            return b[sortId].localeCompare(a[sortId]);
          } else if (typea === "number") {
            return b[sortId] - a[sortId];
          }
        }
      }
      return typea;
    });

    const handleSortChange = (sortId: any) => {
      let sortType: SortType = "asc";

      if (this.state.sortType === sortType) {
        sortType = "desc";
      }

      this.setState({ sortId: sortId, sortType: sortType });
    };

    // console.log('exceptionData', exceptionData.filter((v)=> v));
    return (
      <>
        {sortedReportData ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead style={{ backgroundColor: "#EEEEEE" }}>
                <TableRow>
                  <TableHeaderCell label="Request ID" />
                  <TableSortHeaderCell
                    isActive={sortId === "createdon"}
                    sortType={sortType}
                    sortId="createdon"
                    onSort={handleSortChange}
                    label="Date Requested"
                  />
                  <TableSortHeaderCell
                    isActive={sortId === "firstname"}
                    sortType={sortType}
                    sortId="firstname"
                    onSort={handleSortChange}
                    label="First Name"
                  />
                  <TableSortHeaderCell
                    isActive={sortId === "lastname"}
                    sortType={sortType}
                    sortId="lastname"
                    onSort={handleSortChange}
                    label="Last Name"
                  />
                  <TableSortHeaderCell
                    isActive={sortId === "fmvadminteamactiondate"}
                    sortType={sortType}
                    sortId="fmvadminteamactiondate"
                    onSort={handleSortChange}
                    label="Approval Notice Date"
                  />
                  <TableSortHeaderCell
                    isActive={sortId === "status"}
                    sortType={sortType}
                    sortId="status"
                    onSort={handleSortChange}
                    label="Request Status"
                  />
                  <TableSortHeaderCell
                    isActive={sortId === "updatedon"}
                    sortType={sortType}
                    sortId="updatedon"
                    onSort={handleSortChange}
                    label="Modified"
                  />
                  <TableSortHeaderCell
                    isActive={sortId === "requestername"}
                    sortType={sortType}
                    sortId="requestername"
                    onSort={handleSortChange}
                    label="Requester Name"
                  />
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedReportData ? (
                  sortedReportData.map((row, i) => (
                    <StyledTableRow key={i}>
                      <TableBodyCell
                        label={
                          <Link to={`/fmvreadonlyform/${row.requestid}`}>
                            {row.requestid}
                          </Link>
                        }
                      />
                      <TableBodyCell
                        label={moment(row.createdon).format("MM-DD-YYYY")}
                      />
                      <TableBodyCell label={row.firstname} />
                      <TableBodyCell label={row.lastname} />
                      <TableBodyCell
                        label={
                          row.fmvadminteamactiondate ? (
                            moment(row.fmvadminteamactiondate).format(
                              "MM-DD-YYYY"
                            )
                          ) : (
                            <></>
                          )
                        }
                      />
                      <TableCell>
                        <Typography
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            color: row.exceptiondecision
                              ? "#828282"
                              : row.fmvexceptioncommittestatus
                                ? "#828282"
                                : row.vpapproverstatus
                                  ? "#828282"
                                  : row.exceptionrequeststatus === "Pending"
                                    ? "#FF0000"
                                    : "#828282",
                          }}
                        >
                          {row.exceptiondecision
                            ? row.exceptiondecision
                            : row.fmvexceptioncommittestatus
                              ? row.fmvexceptioncommittestatus
                              : row.vpapproverstatus
                                ? row.vpapproverstatus
                                : row.exceptionrequeststatus
                                  ? row.exceptionrequeststatus
                                  : row.fmvadminteamstatus}
                        </Typography>
                      </TableCell>
                      <TableBodyCell
                        label={
                          row.updatedon ? (
                            moment(row.updatedon).format("MM-DD-YYYY")
                          ) : (
                            <></>
                          )
                        }
                      />
                      <TableBodyCell label={row.requestername} />
                    </StyledTableRow>
                  ))
                ) : (
                  <></>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default ExceptionTable;