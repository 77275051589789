import React from "react";
import { Grid } from "@material-ui/core";

import { FacultyPPQDataDTO } from "../../../Models";

import SearchFilter from "../../../Components/Search/SearchFilter";
import SearchFilterModel from "../../../Components/Search/SearchFilterModel";
import ExportToExcel from "../../../Components/File/ExportToExcel";
import ExceptionRateReportFilterModel from "./ExceptionRateReportFilterModel";

interface Props {
    exceptionRateReportData: FacultyPPQDataDTO[];
    exceptionRateReportFilter: ExceptionRateReportFilterModel;
    onFilterChange: ((exceptionRateReportFilter: ExceptionRateReportFilterModel) => void);
}
interface State { }
class ExceptionRateReportFilter extends React.Component<Props, State> {
    render() {
        const {
            exceptionRateReportData, exceptionRateReportFilter,
        } = this.props;

        const dataToExport = exceptionRateReportData.map(exceptionRateData => ({
            facultyid: exceptionRateData.facultyid,
            ppqsubmissiondate: exceptionRateData.ppqsubmissiondate,
            dateExceptionEntered: exceptionRateData.exceptionratedate,
            firstname: exceptionRateData.firstname,
            lastname: exceptionRateData.lastname,
            hcptype: exceptionRateData.hcptype,
            specialty: exceptionRateData.specialty,
            points: exceptionRateData.points,
            lillygroup: exceptionRateData.lillygroup,
            lillyexceptionrate: exceptionRateData.lillyexceptionrate,
            lillyrate: exceptionRateData.lillyrate,
            lillytier: exceptionRateData.lillytier,
            bigroup: exceptionRateData.bigroup,
            biexceptionrate: exceptionRateData.biexceptionrate,
            birate: exceptionRateData.birate,
            bitier: exceptionRateData.bitier,
        }));

        return (
            <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between">
                <Grid item md={11}>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justify="flex-start"
                        spacing={3}>
                        <Grid item md={4}>
                            <SearchFilter label="Search by HCP Type" value={exceptionRateReportFilter.searchText}
                                onChange={this.handleSearchFilterChange} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={1}>
                    <ExportToExcel fileName="ExceptionRateReport" data={dataToExport} />
                </Grid>
            </Grid>
        );
    }

    handleSearchFilterChange = (searchFilter: SearchFilterModel) => {
        const { exceptionRateReportFilter, onFilterChange } = this.props;

        exceptionRateReportFilter.searchText = searchFilter.searchText;
        onFilterChange(exceptionRateReportFilter);
    }
}

export default ExceptionRateReportFilter;