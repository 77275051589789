import React from 'react';
import { Typography, Grid } from '@material-ui/core';

import ManagementCard from '../ManagementCard';
import IconButton from '../../../Components/Button/IconButton';
import { LillyRateDTO } from "../../../Models";

interface Props {
    lillyrates: LillyRateDTO[],
    onEdit: ((lillyrate: LillyRateDTO) => void);
    onDelete: ((lillyrate: LillyRateDTO) => void);
}
interface State { }
class LillyRateCard extends React.Component<Props, State> {
    render() {
        const { lillyrates } = this.props;

        return (
            <Grid container spacing={2}>
                {lillyrates.map((lillyrate, i) => {
                    return (
                        <Grid key={`LRC-${i}`} item xs={4}>
                            <ManagementCard>
                                <Grid
                                    container
                                    direction="row"
                                    justify="space-between"
                                    alignItems="center"
                                >
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="center"
                                        justify="space-between">
                                        <Grid item>
                                            <Typography style={{ fontSize: 18, fontWeight: 500, color: '#181818' }}>{lillyrate.hcptype}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <IconButton iconType="Edit" onClick={() => this.onEditClick(lillyrate)} />
                                            <IconButton iconType="DeleteDialog" onClick={() => this.onDeleteClick(lillyrate)} />
                                        </Grid>
                                    </Grid>
                                    <Typography style={{ fontSize: 16, fontWeight: 500, color: '#606060', marginTop: 15, marginBottom: 15 }}>{lillyrate.specialty}</Typography>
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="center"
                                        style={{ marginTop: 15, marginBottom: 5 }}>
                                        <Typography
                                            style={{ fontSize: 16, fontWeight: 400, color: '#f27266' }}>Lilly Current Group : {lillyrate.lillygroup}</Typography>
                                        <Typography style={{ marginLeft: 10, marginRight: 10 }}>|</Typography>
                                        <Typography
                                            style={{ fontSize: 16, fontWeight: 400, color: '#f27266' }}>Lilly Future Group : {lillyrate.lillyfuturegroup}</Typography>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="center"
                                        style={{ marginBottom: 15 }}>
                                        <Typography
                                            style={{ fontSize: 16, fontWeight: 400, color: '#f27266' }}>Lilly Tier : {lillyrate.lillytier}</Typography>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="center">
                                        <Typography style={{ fontSize: 14, fontWeight: 400, color: '#595959' }}>Current Year : {lillyrate.lillycurrentyear}</Typography>
                                        <Typography variant="subtitle1" style={{ marginLeft: 10, marginRight: 10 }}>|</Typography>
                                        <Typography style={{ fontSize: 14, fontWeight: 400, color: '#595959' }}>Current Year Rate : ${lillyrate.lillycurrentrate}</Typography>
                                    </Grid>
                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="center">
                                        <Typography style={{ fontSize: 14, fontWeight: 400, color: '#595959' }}>Future Year : {lillyrate.lillyfutureyear}</Typography>
                                        <Typography variant="subtitle1" style={{ marginLeft: 10, marginRight: 10 }}>|</Typography>
                                        <Typography style={{ fontSize: 14, fontWeight: 400, color: '#595959' }}>Future Year Rate : ${lillyrate.lillyfuturerate}</Typography>
                                    </Grid>
                                </Grid>
                            </ManagementCard>
                        </Grid>
                    );
                })}
            </Grid>
        );
    }

    onEditClick(lillyrate: LillyRateDTO) {
        this.props.onEdit(lillyrate);
    }

    onDeleteClick(lillyrate: LillyRateDTO) {
        this.props.onDelete(lillyrate);
    }
}

export default LillyRateCard;
