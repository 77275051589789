import React from "react";
import { Card, CardContent, Grid, Typography, Button } from "@material-ui/core";

import { FacultyDTO } from "../../Models";
import { HCPFileService, CustomEventHandlerService } from "../../Services";
import { DocumentType, FileType, CustomEventType, QuestionnaryStatus } from "../../Common/Enums";
import Utils from "../../Common/Utils";

import FRCard from "../../Components/Card/FRCard";
import GridItem from "../../Components/Grid/GridItem";
import FileUpload from "../../Components/File/FileUpload";
import FileUploadModel from "../../Components/File/FileUploadModel";
import FileUploadCard from "../../Components/File/FileUploadCard";

interface Props {
    faculty: FacultyDTO;
    isPPQSubmit: boolean;
    onCVChange: ((isValid: boolean) => void);
}
interface State {
    files: FileUploadModel[];
    oldFiles: FileUploadModel[];
    ppqPDF?: FileUploadModel;
}
class HCPProfile extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            files: [],
            oldFiles: [],
            ppqPDF: undefined,
        };
    }

    async componentDidMount() {
        const { faculty } = this.props;
        const facultyFiles = await HCPFileService.getAll(faculty.facultyid);

        let files: FileUploadModel[] = [];
        let oldFiles: FileUploadModel[] = [];

        facultyFiles.filter(file => file.documenttype === DocumentType.CV).forEach((facultyFile) => {
            const file = new FileUploadModel();

            file.id = facultyFile.id;
            file.name = facultyFile.filename;
            file.facultyId = facultyFile.facultyid;
            file.type = FileType[facultyFile.documenttype as keyof typeof FileType];
            file.s3filekey = facultyFile.s3filekey;
            file.sizeInKB = facultyFile.fileSizeInKB ?? 0;

            if (!(faculty.questionairystatus === QuestionnaryStatus.SUBMITTED)) {
                files.push(file);
            }

            oldFiles.push(file);
        });

        const ppqPDFFiles = facultyFiles.filter(file => file.documenttype === DocumentType.PPQPDF);

        if (ppqPDFFiles.length > 0) {
            const latestPPQFile = ppqPDFFiles.reduce((a, b) => {
                return new Date(a.createdon ?? '') > new Date(b.createdon ?? '') ? a : b;
            });

            const ppqPDF = new FileUploadModel();

            ppqPDF.id = latestPPQFile.id;
            ppqPDF.name = latestPPQFile.filename;
            ppqPDF.facultyId = latestPPQFile.facultyid;
            ppqPDF.type = FileType[latestPPQFile.documenttype as keyof typeof FileType];
            ppqPDF.s3filekey = latestPPQFile.s3filekey;
            ppqPDF.sizeInKB = latestPPQFile.fileSizeInKB ?? 0;

            this.setState({ files, oldFiles, ppqPDF });
        } else {
            this.setState({ files, oldFiles });
        }

        if (files.length > 0) {
            this.props.onCVChange(true);
        } else {
            this.props.onCVChange(false);
        }
    }

    render() {
        const { files, ppqPDF } = this.state;
        const { faculty, isPPQSubmit } = this.props;

        return (
            <>
                {ppqPDF && !isPPQSubmit ? <Grid container justify="flex-end" style={{ marginBottom: 10 }}>
                    <Grid item>
                        <Button size="medium" variant="outlined" data-html2canvas-ignore
                            style={{ fontSize: 16, fontWeight: 400, color: '#004693', borderColor: '#004693', borderWidth: '1px', textTransform: 'none' }}
                            onClick={this.handlePPQPDFClick}>Download PDF</Button>
                    </Grid>
                </Grid> : null}
                <FRCard title="HCP Profile">
                    <Grid container direction="column" spacing={4}>
                        <Grid item>
                            <Grid container justify="space-between" spacing={2}>
                                <GridItem isFR label="First Name" value={faculty.firstname} />
                                <GridItem isFR label="Middle Name" value={faculty.middleinitials} />
                                <GridItem isFR label="Last Name" value={faculty.lastname} />
                                <GridItem isFR label="Suffix" value="" />
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <Typography style={{ fontSize: 20, fontWeight: 500, color: '#004693' }}>
                                        START HERE: Upload Your CV
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Card style={{ border: '1px solid #1D1D1D', borderRadius: '3px', opacity: 1, boxShadow: '0px 3px 6px #00000029' }}>
                                        <CardContent>
                                            <Grid container direction="row" spacing={2}>
                                                <Grid item md={12}>
                                                    <FileUpload isFR onChange={this.handleFileUpload} disabled={isPPQSubmit} />
                                                </Grid>
                                                <Grid item md={12}>
                                                    <FileUploadCard isFR files={files} showDelete={!isPPQSubmit} isDownload={!isPPQSubmit}
                                                        onDelete={this.handleFileDelete} onDownload={this.handleFileDownload} />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </FRCard>
            </>
        );
    }

    handleFileUpload = async (file: FileUploadModel) => {
        const { files, oldFiles } = this.state;
        const isFileSizeValid = Utils.validateFileSize(file.sizeInMB, DocumentType.CV);
        const isFileTypeValid = Utils.validateFileType(file.type, DocumentType.CV);
        const isNameExists = files.filter(p => p.name.includes(file.name));
        let message = '';

        const sizes = files.map(p => p.sizeInKB);
        const totalSize = sizes.reduce((a, b) => a + b, 0) + file.sizeInKB;

        if (isFileSizeValid && isFileTypeValid && totalSize <= 10000 && isNameExists.length === 0) {
            const { faculty } = this.props;

            file.facultyId = faculty.facultyid;

            if (faculty.questionairystatus === QuestionnaryStatus.SUBMITTED) {
                Promise.all(oldFiles.map(async file => {
                    await HCPFileService.deleteCV(file);
                    return true;
                }));

                this.setState({ files: [], oldFiles: [] });
            }

            const facultyFile = await HCPFileService.uploadCV(file);

            if (facultyFile) {
                const fileUploadDTO = new FileUploadModel();

                fileUploadDTO.id = facultyFile.id;
                fileUploadDTO.name = facultyFile.filename;
                fileUploadDTO.facultyId = facultyFile.facultyid;
                fileUploadDTO.type = FileType[facultyFile.documenttype as keyof typeof FileType];
                fileUploadDTO.s3filekey = facultyFile.s3filekey;
                fileUploadDTO.sizeInKB = file.sizeInKB;
                fileUploadDTO.sizeInMB = file.sizeInMB;

                this.setState(prevState => ({
                    files: [...prevState.files, fileUploadDTO],
                }));

                message = 'CV uploaded successfully';
                CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, { alertId: 'fileUpload', message });
                this.props.onCVChange(true);
            }
        } else {
            if (!isFileSizeValid) {
                message = 'Invalid file size';
            }
            if (!isFileTypeValid) {
                message = 'Invalid file type';
            }
            if (totalSize > 10000) {
                message = 'Total size of attachment cannot exceed 10MB';
            }
            if (isNameExists.length > 0) {
                message = 'File already exists with same name';
            }
            CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, { alertId: 'fileUpload', message });
        }
    }

    handleFileDownload = async (file: FileUploadModel) => {
        const downloadURL = await HCPFileService.getFile(file.s3filekey, file.type);
        Utils.openUrlParent(downloadURL);
    }

    handleFileDelete = async (file: FileUploadModel) => {
        const isSuccess = await HCPFileService.deleteCV(file);
        let message = '';

        if (isSuccess) {
            const { files } = this.state;

            const index = files.findIndex(p => p.name === file.name && p.type === file.type);
            files.splice(index, 1);

            this.setState({ files }, () => {
                const fileInput = document.getElementById("fileUpload") as HTMLInputElement;

                if (fileInput) {
                    fileInput.value = '';
                }
            });

            message = 'CV deleted successfully';
            CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, { alertId: 'fileUpload', message });

            if (files.length === 0) {
                this.props.onCVChange(false);
            }
        } else {
            message = 'Failed to delete CV';
            CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, { alertId: 'fileUpload', message });
        }
    }

    handlePPQPDFClick = () => {
        const { ppqPDF } = this.state;

        if (ppqPDF) {
            this.handleFileDownload(ppqPDF);
        }
    }
}

export default HCPProfile;