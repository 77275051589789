import React from "react";
import { Card, CardContent, Typography, Grid } from "@material-ui/core";
import { FMVRateExceptionDTO } from "../../Models";



interface Props {
    data: FMVRateExceptionDTO;
}
interface State { }
class FacultyDetailCard extends React.Component<Props, State> {
    render() {
        const { data } = this.props;

        return (
            <Card variant="outlined">
                <CardContent>
                    <Grid item container style={{ marginBottom: 10 }}>
                        <Grid item xs={12} >
                            <Typography style={{ fontSize: 20, fontWeight: 500 }}> {`${data.firstname} ${data.lastname}`}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container style={{ marginBottom: 10 }}>
                        <Grid item xs={3} >
                            <Typography>Faculty ID : {data.facultyid}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>HCP Type : {data.hcptype}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container style={{ marginBottom: 10 }}>
                        <Grid item xs={3} >
                            <Typography>Lilly Current Group Tier : {`${data.lillycurrentgrouptier}`}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography>BI Current Group Tier : {`${data.bicurrentgrouptier}`}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography>Lilly Current Rate : {`$${data.lillycurrentrate}`}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography>BI Current Rate : {`$${data.bicurrentrate}`}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container style={{ marginBottom: 10 }}>
                        <Grid item xs={2} >
                            <Typography>Point to next tier :</Typography>
                        </Grid>
                        <Grid item xs={1} >
                            <Typography> Lilly {`(${data.lillypointtonexttier ? data.lillypointtonexttier : 'NA'})`}</Typography>
                        </Grid>
                        <Grid item xs={1} >
                            <Typography> BI {`(${data.bipointtonexttier ? data.bipointtonexttier : 'NA'})`}</Typography>
                        </Grid>

                    </Grid>
                </CardContent>
            </Card>
        );
    }
}

export default FacultyDetailCard;