import SelectModel from '../Components/Select/SelectModel';

class GroupService {
    private readonly Groups = [{ id: '1', group: 'A' }, { id: '2', group: 'B' }, { id: '3', group: 'C' }
        , { id: '4', group: 'D' }, { id: '5', group: 'E' }, { id: '6', group: 'F' }
        , { id: '7', group: 'G' }, { id: '8', group: 'H' }, { id: '9', group: 'I' }
        , { id: '10', group: 'J' }, { id: '11', group: 'K' }];

    async getAllForSelect(): Promise<SelectModel[]> {
        const groups = this.Groups.map((group) => {
            const selectModel = new SelectModel();
            selectModel.text = group.group;
            selectModel.value = group.group;
            return selectModel;
        });

        return Promise.resolve(groups);
    }
}

export default new GroupService();