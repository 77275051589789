import Utils from "../Common/Utils";
import { FacultyTokenModel } from "../Models";

class HCPTokenService {
    private readonly _TokenName = 'hcpToken';

    getToken(): string | null {
        return localStorage.getItem(this._TokenName);
    }

    saveToken(token: string | null) {
        localStorage.setItem(this._TokenName, token ?? '');
    }

    decodeToken(): FacultyTokenModel | null {
        const token = this.getToken();
        return Utils.decodeJWT<FacultyTokenModel>(token);
    }
}

export default new HCPTokenService();