import { AuthProvider } from '@elilillyco/spa_auth';
import Utils from './Utils';

const env = Utils.getEnvVars();

const config = {
    clientId: env.clientId,
};

const adminAuthProvider = new AuthProvider(config);

export default adminAuthProvider;
