import React from 'react';
import { Box, Grid } from '@material-ui/core';

import { CredentialDTO } from "../../../Models";
import TextInputField from '../../../Components/TextInput/TextInputField';
import RedButton from '../../../Components/Button/RedButton';
import WhiteButton from '../../../Components/Button/WhiteButton';

interface Props {
    credential?: CredentialDTO;
    onCancelClick: ((event: React.MouseEvent<HTMLButtonElement>) => void);
    onSubmit: ((credential: CredentialDTO) => void);
}
interface State {
    credential: CredentialDTO;
    formValidationState: { name: string, isValid: boolean }[];
}
class CredentialForm extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);

        let isValid = false;

        if (props.credential && props.credential.credentialid) {
            isValid = true;
        }

        this.state = {
            credential: props.credential ?? {} as CredentialDTO,
            formValidationState: [
                { name: 'credential', isValid },
                { name: 'acronyms', isValid },
            ],
        };
    }

    render() {
        const { credential, formValidationState } = this.state;
        const { onCancelClick } = this.props;

        const isFormNotValid = formValidationState.some(p => p.isValid === false);

        return (
            <Box>
                <form noValidate autoComplete="off">
                    <div>
                        <Grid
                            container spacing={7}
                            alignItems="center"
                            justify="flex-start"
                            style={{ padding: 10 }} >
                            <Grid item xs={3}>
                                <TextInputField id="credential" label="Credential"
                                    value={credential.credential} onChange={this.handleChange} />
                            </Grid>
                            <Grid item xs={3}>
                                <TextInputField id="acronyms" label="Acronyms"
                                    value={credential.acronyms} onChange={this.handleChange} />
                            </Grid>
                            <Grid item>
                                <WhiteButton label="Cancel" onClick={onCancelClick} style={{ marginRight: 20 }} />
                                {isFormNotValid ?
                                    <RedButton disabled={true} label={credential.credentialid !== undefined ? 'Update' : 'Add'} onClick={this.handleSubmit} /> :
                                    <RedButton label={credential.credentialid !== undefined ? 'Update' : 'Add'} onClick={this.handleSubmit} />
                                }
                            </Grid>
                        </Grid>
                    </div>
                </form>
            </Box>
        );
    }

    handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined, isValid: boolean) => {
        if (event) {
            this.setState(prevState => {
                const { id, value } = event.target;
                let credential: any = Object.assign({}, prevState.credential);
                credential[id] = value;

                let formValidationState = prevState.formValidationState;
                const index = formValidationState.findIndex(p => p.name === id);
                formValidationState[index] = { name: id, isValid: isValid };

                return { credential, formValidationState };
            });
        }
    }

    handleSubmit = () => {
        const { credential } = this.state;
        this.props.onSubmit(credential);
    }
}

export default CredentialForm;

