import ProQuestAPIService from "./ProQuestAPIService";
import CustomEventHandlerService from './CustomEventHandlerService';

import { LillyRateDTO } from "../Models";
import { CustomEventType } from '../Common/Enums';

class LillyRateService extends ProQuestAPIService {
    async getAll(): Promise<LillyRateDTO[]> {
        const response = await this.GetAsync<LillyRateDTO[]>('fmvlillyrate/getall');

        let lillyRates: LillyRateDTO[] = [];

        if (response.isSuccess) {
            lillyRates = response.data;

            const today = new Date();

            lillyRates.forEach(lillyRate => {
                lillyRate.lillycurrentyear = today.getFullYear();
                lillyRate.lillyfutureyear = today.getFullYear() + 1;
            });
        }

        return lillyRates;
    }

    async add(lillyrate: LillyRateDTO): Promise<LillyRateDTO | null> {
        const response = await this.PostAsync<LillyRateDTO>('fmvlillyrate/create', lillyrate);

        if (response.isSuccess) {
            CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, response.message);
            return response.data;
        }
        else {
            let message = response.message;

            CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, message);
            return null;
        }
    }

    async edit(lillyrate: LillyRateDTO): Promise<LillyRateDTO | null> {
        const response = await this.PutAsync<LillyRateDTO>(`fmvlillyrate/update/${lillyrate.lillyrateid}`, lillyrate);

        if (response.isSuccess) {
            CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, response.message);
            return response.data;
        }
        else {
            let message = response.message;

            CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, message);
            return null;
        }
    }

    async editAllGroups(lillyrate: LillyRateDTO): Promise<LillyRateDTO | null> {
        const response = await this.PutAsync<LillyRateDTO>(`fmvlillyrate/updategroup/${lillyrate.lillyrateid}`, lillyrate);

        if (response.isSuccess) {
            CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, response.message);
            return response.data;
        }
        else {
            let message = response.message;

            CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, message);
            return null;
        }
    }

    async delete(lillyrate: LillyRateDTO): Promise<boolean> {
        const response = await this.DeleteAsync<null>(`fmvlillyrate/delete/${lillyrate.lillyrateid}`);

        if (response.isSuccess) {
            CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, 'The FMV Lilly Rate has been deleted successfully.');
        }
        else {
            let message = response.message;

            CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, message);
        }

        return response.isSuccess;
    }
}

export default new LillyRateService();