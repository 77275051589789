import React from "react";
import { Grid } from "@material-ui/core";

import { FacultyPPQDataDTO } from "../../../Models";

import SearchFilter from "../../../Components/Search/SearchFilter";
import SearchFilterModel from "../../../Components/Search/SearchFilterModel";
import ExportToExcel from "../../../Components/File/ExportToExcel";
import SingleAutoComplete from "../../../Components/Select/SingleAutoComplete";
import SelectModel from "../../../Components/Select/SelectModel";
import InlineDatePicker from "../../../Components/Date/InlineDatePicker";
import PPQSummaryReportFilterModel from "./PPQSummaryReportFilterModel";

interface Props {
    hcptypes: SelectModel[];
    specialties: SelectModel[];
    ppqSummaryReportData: FacultyPPQDataDTO[];
    ppqSummaryReportFilter: PPQSummaryReportFilterModel;
    onFilterChange: ((ppqSummaryReportFilter: PPQSummaryReportFilterModel) => void);
}
interface State { }
class PPQSummaryReportFilter extends React.Component<Props, State> {
    render() {
        const {
            hcptypes, specialties,
            ppqSummaryReportData, ppqSummaryReportFilter,
        } = this.props;

        const dataToExport = ppqSummaryReportData.map(ppqSummaryData => ({
            facultyid: ppqSummaryData.facultyid,
            ppqsubmissiondate: ppqSummaryData.ppqsubmissiondate,
            firstname: ppqSummaryData.firstname,
            lastname: ppqSummaryData.lastname,
            hcptype: ppqSummaryData.hcptype,
            specialty: ppqSummaryData.specialty,
            points: ppqSummaryData.points,
            lillygroup: ppqSummaryData.lillygroup,
            lillyrate: ppqSummaryData.lillyrate,
            lillytier: ppqSummaryData.lillytier,
            bigroup: ppqSummaryData.bigroup,
            birate: ppqSummaryData.birate,
            bitier: ppqSummaryData.bitier,
            lillyfuturegroup: ppqSummaryData.lillyfuturegroup,
            lillyfuturerate: ppqSummaryData.lillyfuturerate,
            bifuturegroup: ppqSummaryData.bifuturegroup,
            bifuturerate: ppqSummaryData.bifuturerate,
        }));

        return (
            <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between">
                <Grid item md={11}>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justify="flex-start"
                        spacing={3}>
                        <Grid item md={4}>
                            <SearchFilter label="Search by HCP Type or Specialty Name" value={ppqSummaryReportFilter.searchText}
                                onChange={this.handleSearchFilterChange} />
                        </Grid>
                        <Grid item md={2}>
                            <SingleAutoComplete id="hcptype" label="Select HCP Type" values={hcptypes} selectedValue={ppqSummaryReportFilter.hcptype}
                                isRounded isRequired={false} onChange={this.handleSelectFilterChange} />
                        </Grid>
                        <Grid item md={2}>
                            <SingleAutoComplete id="specialty" label="Select Specialty" values={specialties} selectedValue={ppqSummaryReportFilter.specialty}
                                isRounded isRequired={false} onChange={this.handleSelectFilterChange} />
                        </Grid>
                        <Grid item md={2}>
                            <InlineDatePicker id="date" label="Date" selectedDate={ppqSummaryReportFilter.date}
                                onChange={this.handleDateFilterChange} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={1}>
                    <ExportToExcel fileName="PPQSummaryReport" data={dataToExport} />
                </Grid>
            </Grid>
        );
    }

    handleSearchFilterChange = (searchFilter: SearchFilterModel) => {
        const { ppqSummaryReportFilter, onFilterChange } = this.props;

        ppqSummaryReportFilter.searchText = searchFilter.searchText;
        onFilterChange(ppqSummaryReportFilter);
    }

    handleSelectFilterChange = (selected: SelectModel, targetId?: string) => {
        const { ppqSummaryReportFilter, onFilterChange } = this.props;

        if (targetId) {
            let filterData: any = Object.assign({}, ppqSummaryReportFilter);
            filterData[targetId] = selected.value;
            onFilterChange(filterData);
        }
    }

    handleDateFilterChange = (selectedDate: Date | null, targetId?: string) => {
        const { ppqSummaryReportFilter, onFilterChange } = this.props;

        if (targetId) {
            let filterData: any = Object.assign({}, ppqSummaryReportFilter);
            filterData[targetId] = selectedDate;
            onFilterChange(filterData);
        }
    }
}

export default PPQSummaryReportFilter;