import React from "react";
import { Button } from "@material-ui/core";

interface Props {
    id?: string;
    label: string;
    disabled: boolean;
    onClick?: ((event: React.MouseEvent<HTMLButtonElement>) => void);
    startIcon?: React.ReactNode;
    size?: "small" | "large" | "medium" | undefined;
}
interface State { }
class BlueButton extends React.Component<Props, State> {
    public static defaultProps = {
        disabled: false,
        size: 'medium',
    };

    render() {
        const { id, label, disabled, onClick, startIcon, size } = this.props;

        const style: React.CSSProperties = { textTransform: 'none' };

        if (!disabled) {
            style.backgroundColor = '#004693';
            style.color = '#FFFFFF';
        }

        return (
            <Button disabled={disabled} id={id} startIcon={startIcon} size={size} variant="outlined"
                style={style}
                onClick={onClick}>{label}</Button>
        );
    }
}

export default BlueButton;