import React from "react";
import { Avatar, Divider, Grid, Typography } from "@material-ui/core";

import { FacultyDTO, FacultyPPQDataDTO } from "../../../Models";
import Utils from "../../../Common/Utils";
import ExportToPDF from "../../../Components/File/ExportToPDF";
import { QuestionnaryStatus } from "../../../Common/Enums";
import { TabModel } from "../TabModel";

const FacultyInfoGridItem: React.FC<{ label: string, value?: string | number | null }> = ({ label, value }) => {
    return (
        <Grid item>
            <Grid container spacing={1}>
                <Grid item>
                    <Typography style={{ fontSize: 18, fontWeight: 400, color: '#747474' }}>
                        {label}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography style={{ fontSize: 18, fontWeight: 400, color: '#383838' }}>
                        {value}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

interface Props {
    selectedFaculty?: FacultyDTO;
    facultyPPQData: FacultyPPQDataDTO | null;
    currentTab: TabModel;
    totalPoints: number;
}
interface State { }
class FacultyInfo extends React.Component<Props, State> {
    render() {
        const { selectedFaculty, facultyPPQData, currentTab, totalPoints } = this.props;

        let lastSubmittedDate = null;
        let lastUpdatedDate = null;
        let LillyNextTierPoints = 'NA';
        let BINextTierPoints = 'NA';

        if (facultyPPQData) {
            if (facultyPPQData.LillyNextTierPoints) {
                LillyNextTierPoints = facultyPPQData.LillyNextTierPoints.toString();
            }

            if (facultyPPQData.BINextTierPoints) {
                BINextTierPoints = facultyPPQData.BINextTierPoints.toString();
            }
        }

        if (currentTab.type === 'PendingTiering') {
            if (selectedFaculty && (selectedFaculty.questionairystatus === QuestionnaryStatus.PENDING || selectedFaculty.questionairystatus === QuestionnaryStatus.EXCEPTION)) {
                lastUpdatedDate = Utils.getDate(selectedFaculty.updatedon, 'DD MMMM YYYY');
            }
        } else if (currentTab.type === 'Tiered') {
            if (facultyPPQData && facultyPPQData.ppqsubmissionstatus === QuestionnaryStatus.SUBMITTED) {
                lastSubmittedDate = Utils.getDate(facultyPPQData.ppqsubmissiondate, 'DD MMMM YYYY');
            }
        }

        if (selectedFaculty) {
            return (
                <>
                    <Grid container justify="space-between" style={{ padding: 15 }}>
                        <Grid item md={11}>
                            <Grid container justify="space-between" spacing={5}>
                                <Grid item xs={2}>
                                    <Avatar style={{ height: '114px', width: '114px' }} />
                                </Grid>
                                <Grid item xs={10}>
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item>
                                            <Typography style={{ fontSize: 22, fontWeight: 500, color: '#383838' }}>
                                                {`${selectedFaculty.firstname} ${selectedFaculty.lastname}`}
                                            </Typography>
                                        </Grid>
                                        {lastUpdatedDate !== null ? <Grid item>
                                            <Grid container spacing={2}>
                                                <FacultyInfoGridItem label="Last Updated :" value={lastUpdatedDate} />
                                            </Grid>
                                        </Grid> : null}
                                        <Grid item>
                                            <Grid container spacing={2}>
                                                <FacultyInfoGridItem label="Faculty ID :" value={selectedFaculty.facultyid} />
                                                {selectedFaculty.hcptype ? <FacultyInfoGridItem label="HCP Type :" value={selectedFaculty.hcptype} /> : null}
                                            </Grid>
                                        </Grid>
                                        {selectedFaculty.specialty ? <Grid item>
                                            <Grid container spacing={2}>
                                                <FacultyInfoGridItem label="Specialty :" value={selectedFaculty.specialty} />
                                            </Grid>
                                        </Grid> : null}
                                        {facultyPPQData ? <Grid item>
                                            <Grid container spacing={2}>
                                                <FacultyInfoGridItem label="Current Year :" value={facultyPPQData.ppqyear} />
                                                <FacultyInfoGridItem label="Lilly Current Group Tier :" value={`${facultyPPQData.lillytier}${facultyPPQData.lillygroup}`} />
                                                <FacultyInfoGridItem label="BI Current Group Tier :" value={`${facultyPPQData.bitier}${facultyPPQData.bigroup}`} />
                                                <FacultyInfoGridItem label="Lilly Current Rate :" value={`$${facultyPPQData.lillyrate}`} />
                                                <FacultyInfoGridItem label="BI Current Rate :" value={`$${facultyPPQData.birate}`} />
                                                <FacultyInfoGridItem label="PPQ Points :" value={totalPoints} />
                                            </Grid>
                                        </Grid> : null}
                                        {facultyPPQData ? <Grid item>
                                            <Grid container spacing={2}>
                                                <FacultyInfoGridItem label="Future Year :" value={facultyPPQData.ppqyear ? facultyPPQData.ppqyear + 1 : ''} />
                                                <FacultyInfoGridItem label="Lilly Future Group Tier :" value={`${facultyPPQData.lillytier}${facultyPPQData.lillyfuturegroup}`} />
                                                <FacultyInfoGridItem label="BI Future Group Tier :" value={`${facultyPPQData.bitier}${facultyPPQData.bifuturegroup}`} />
                                                <FacultyInfoGridItem label="Lilly Future Rate :" value={`$${facultyPPQData.lillyfuturerate}`} />
                                                <FacultyInfoGridItem label="BI Future Rate :" value={`$${facultyPPQData.bifuturerate}`} />
                                                <FacultyInfoGridItem label="PPQ Points :" value={totalPoints} />
                                            </Grid>
                                        </Grid> : null}
                                        {facultyPPQData && facultyPPQData.israteexception ? <Grid item>
                                            <Grid container spacing={2}>
                                                <FacultyInfoGridItem label="Lilly Group Tier :" value={`${facultyPPQData.lillytier}${facultyPPQData.lillygroup}`} />
                                                <FacultyInfoGridItem label="BI Group Tier :" value={`${facultyPPQData.bitier}${facultyPPQData.bigroup}`} />
                                                <FacultyInfoGridItem label="Lilly Exception Rate :" value={`$${facultyPPQData.lillyexceptionrate}`} />
                                                <FacultyInfoGridItem label="BI Exception Rate :" value={`$${facultyPPQData.biexceptionrate}`} />
                                            </Grid>
                                        </Grid> : null}
                                        <Grid item>
                                            <Grid container spacing={2}>
                                                {lastSubmittedDate !== null ? <Grid item><FacultyInfoGridItem label="Last Submitted :" value={lastSubmittedDate} /></Grid> : null}
                                                <Grid item>
                                                    <FacultyInfoGridItem label="Points to Next Tier :" value={`Lilly (${LillyNextTierPoints}) BI (${BINextTierPoints})`} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={1} data-html2canvas-ignore>
                            <ExportToPDF label="Print" dataId="printPPQPDF"
                                fileName={`${selectedFaculty.firstname} ${selectedFaculty.lastname}.pdf`}
                            />
                        </Grid>
                    </Grid>
                    <Divider variant="fullWidth" />
                </>
            );
        }
        return null;
    }
}

export default FacultyInfo;