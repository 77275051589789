import React from "react";
import moment from "moment";
import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableBody
} from "@material-ui/core";

import { PPQResponseReportModel } from "../../../Models";
import Constants from "../../../Common/Constants";
import TableHeaderCell from "../../../Components/Table/TableHeaderCell";
import TableBodyCell from "../../../Components/Table/TableBodyCell";
import StyledTableRow from "../../../Components/Table//StyledTableRow";

interface Props {
    ppqResponseReportData: PPQResponseReportModel[];
}
interface State { }
class PPQResponseReportTable extends React.Component<Props, State> {
    renderColumns() {
        const columnArray = [];

        for (let i = 1; i < Constants.PPQResponseColumnCount; i++) {
            const questionColumnName = `Question ${i}`;
            const responseColumnName = `Response ${i}`;
            columnArray.push(<TableHeaderCell label={questionColumnName} minWidth={190} />);
            columnArray.push(<TableHeaderCell label={responseColumnName} minWidth={190} />);
        }

        return columnArray.map((column) => {
            return column;
        });
    }

    renderColumnValues(row: any) {
        const columnArray = [];

        for (let i = 1; i < Constants.PPQResponseColumnCount; i++) {
            const questionColumnName = `${Constants.PPQResponseQuestionColumnName}${i}`;
            const responseColumnName = `${Constants.PPQResponseResponseColumnName}${i}`;
            
            columnArray.push(<TableBodyCell label={row[questionColumnName]} />);
            columnArray.push(<TableBodyCell label={row[responseColumnName]} />);
        }

        return columnArray.map((column) => {
            return column;
        });
    }

    render() {
        const { ppqResponseReportData } = this.props;

        return (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead style={{ backgroundColor: '#EEEEEE' }}>
                        <TableRow>
                            <TableHeaderCell label="Faculty ID" minWidth={100} />
                            <TableHeaderCell label="PPQ Submission Date" minWidth={170} />
                            <TableHeaderCell label="FN" />
                            <TableHeaderCell label="LN" />
                            <TableHeaderCell label="HCP Type" />
                            <TableHeaderCell label="Specialty" />
                            <TableHeaderCell label="Points" />
                            <TableHeaderCell label="Current Lilly Group" minWidth={170} />
                            <TableHeaderCell label="Current Lilly Rate" minWidth={170} />
                            <TableHeaderCell label="Current Lilly Tier" minWidth={170} />
                            <TableHeaderCell label="Current BI Alliance Group" minWidth={190} />
                            <TableHeaderCell label="Current BI Alliance Rate" minWidth={190} />
                            <TableHeaderCell label="Current BI Alliance Tier" minWidth={190} />
                            {this.renderColumns()}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ppqResponseReportData.map((row) => {
                            return (
                                <StyledTableRow key={row.id}>
                                    <TableBodyCell label={row.facultyid} />
                                    <TableBodyCell label={moment(row.ppqsubmissiondate).format('MM-DD-YYYY')} />
                                    <TableBodyCell label={row.firstname} />
                                    <TableBodyCell label={row.lastname} />
                                    <TableBodyCell label={row.hcptype} />
                                    <TableBodyCell label={row.specialty} />
                                    <TableBodyCell label={row.points} />
                                    <TableBodyCell label={row.lillygroup} />
                                    <TableBodyCell label={row.lillyrate} />
                                    <TableBodyCell label={row.lillytier} />
                                    <TableBodyCell label={row.bigroup} />
                                    <TableBodyCell label={row.birate} />
                                    <TableBodyCell label={row.bitier} />
                                    {this.renderColumnValues(row)}
                                </StyledTableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
}

export default PPQResponseReportTable;