import ProQuestHCPAPIService from "./ProQuestHCPAPIService";
import CustomEventHandlerService from "./CustomEventHandlerService";
import { FacultyDTO, MessageModel } from "../Models";
import { CustomEventType } from "../Common/Enums";

class HCPFacultyService extends ProQuestHCPAPIService {
    async getByFacultyId(facultyid: number): Promise<FacultyDTO | MessageModel | null> {
        CustomEventHandlerService.dispatch(CustomEventType.LoadingStart);

        const response = await this.GetAsync<FacultyDTO | MessageModel>(`faculty/getbyid/${facultyid}`);

        CustomEventHandlerService.dispatch(CustomEventType.LoadingStop);

        return response.data;
    }
}

export default new HCPFacultyService();