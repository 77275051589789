import React from "react";
import { TextField, WithStyles, withStyles, Theme } from "@material-ui/core";

import { FacultyQuestDTO, ContentfulQuestion } from "../../../Models";
import { EventType } from "../../../Common/Enums";

const styles = (theme: Theme) => ({
    notchedOutline: {
        borderWidth: '1px',
        borderColor: '#FFA49D !important'
    },
    notchedOutlineFR: {
        borderWidth: '1px',
        borderColor: '#004693 !important'
    },
});

interface Props extends WithStyles<typeof styles> {
    question: ContentfulQuestion,
    selectedAnswer?: FacultyQuestDTO;
    disabled: boolean;
    isFR: boolean;
    onChange: ((selected: FacultyQuestDTO, eventType: EventType) => void);
}
interface State {
    value: string;
}
class TextInputField extends React.Component<Props, State> {
    public static defaultProps = {
        disabled: false,
    };
    private timer?: NodeJS.Timeout = undefined;

    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            value: props.selectedAnswer ? props.selectedAnswer.answer : '',
        };
    }

    render() {
        const { disabled, question, isFR, classes } = this.props;
        const { value } = this.state;

        let textFieldNotchOutline = classes.notchedOutline;

        if (isFR) {
            textFieldNotchOutline = classes.notchedOutlineFR;
        }

        return (
            <TextField type="text" variant="outlined"
                InputProps={{
                    classes: {
                        notchedOutline: textFieldNotchOutline,
                    },
                }}
                placeholder={question.placeholder} fullWidth disabled={disabled}
                value={value} onChange={this.handleChange} />
        );
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (this.timer) {
            clearTimeout(this.timer);
        }

        const value = (event.target as HTMLInputElement).value;

        this.setState({ value }, () => {
            this.timer = setTimeout(() => {
                this.handleSave();
            }, 1000);
        });
    }

    handleSave = () => {
        const { question } = this.props;
        const { value } = this.state;

        const answerModel: FacultyQuestDTO = {
            id: 0,
            facultyid: 0,
            questionno: question.id,
            question: question.question,
            answer: value,
            answerid: 0,
            points: 0,
            createdby: '',
            createdon: '',
        };

        this.props.onChange(answerModel, EventType.ADD);
    }
}

export default withStyles(styles)(TextInputField);