import ProQuestAPIService from "./ProQuestAPIService";
import CustomEventHandlerService from './CustomEventHandlerService';

import { CredentialDTO } from "../Models";
import { CustomEventType } from '../Common/Enums';
import SelectModel from "../Components/Select/SelectModel";

class CredentialService extends ProQuestAPIService {
    async getAll(): Promise<CredentialDTO[]> {
        const response = await this.GetAsync<CredentialDTO[]>('credential/getall');

        let credentials: CredentialDTO[] = [];

        if (response.isSuccess) {
            credentials = response.data;
        }

        return credentials;
    }

    async add(credential: CredentialDTO): Promise<CredentialDTO | null> {
        const response = await this.PostAsync<CredentialDTO>('credential/create', credential);

        if (response.isSuccess) {
            CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, response.message);
            return response.data;
        }
        else {
            let message = response.message;

            CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, message);
            return null;
        }
    }

    async edit(credential: CredentialDTO): Promise<CredentialDTO | null> {
        const response = await this.PutAsync<CredentialDTO>(`credential/update/${credential.credentialid}`, credential);

        if (response.isSuccess) {
            CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, response.message);
            return response.data;
        }
        else {
            let message = response.message;

            CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, message);
            return null;
        }
    }

    async delete(credential: CredentialDTO): Promise<boolean> {
        const response = await this.DeleteAsync<null>(`credential/delete/${credential.credentialid}`);

        if (response.isSuccess) {
            CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, 'The credential has been deleted successfully.');
        }
        else {
            let message = response.message;

            CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, message);
        }

        return response.isSuccess;
    }

    async getAllForSelect(): Promise<SelectModel[]> {
        const credentials = await this.getAll();

        return credentials.map((credential) => {
            const selectModel = new SelectModel();
            selectModel.text = credential.credential;
            selectModel.value = credential.credential;
            return selectModel;
        });
    }
}

export default new CredentialService();