import React from 'react';
import { Box, Grid } from '@material-ui/core';

import { UserDTO } from "../../Models";
import TextInputField from '../../Components/TextInput/TextInputField';
import RedButton from '../../Components/Button/RedButton';
import WhiteButton from '../../Components/Button/WhiteButton';

interface Props {
    user?: UserDTO;
    onCancelClick: ((event: React.MouseEvent<HTMLButtonElement>) => void);
    onSubmit: ((user: UserDTO) => void);
}
interface State {
    user: UserDTO;
}
class UserForm extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            user: props.user ?? {} as UserDTO,
        };
    }

    render() {
        const { user } = this.state;
        const { onCancelClick } = this.props;

        return (
            <Box>
                <form noValidate autoComplete="off">
                    <div>
                        <Grid
                            container spacing={5}
                            alignItems="center"
                            justify="flex-start"
                        >
                            <Grid item>
                                <TextInputField id="firstname" label="First Name"
                                    value={user.firstname} onChange={this.handleChange} />
                            </Grid>
                            <Grid item>
                                <TextInputField id="lastname" label="Last Name"
                                    value={user.lastname} onChange={this.handleChange} />
                            </Grid>
                            <Grid item>
                                <TextInputField id="userid" label="User ID"
                                    value={user.userid} onChange={this.handleChange} />
                            </Grid>
                            <Grid item>
                                <TextInputField id="email" label="Email"
                                    value={user.email} onChange={this.handleChange} />
                            </Grid>
                            <Grid item>
                                <WhiteButton label="Cancel" onClick={onCancelClick} style={{ marginRight: 20 }} />
                                <RedButton label={user.id ? 'Update' : 'Add'} onClick={this.handleSubmit} />
                            </Grid>
                        </Grid>
                    </div>
                </form>
            </Box>
        );
    }


    handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        if (event) {
            this.setState(prevState => {
                let user: any = Object.assign({}, prevState.user);
                user[event.target.id] = event.target.value;
                return { user };
            });
        }
    }

    handleSubmit = () => {
        const { user } = this.state;
        this.props.onSubmit(user);
    }
}

export default UserForm;

