import React from 'react';
import { Box, Typography } from '@material-ui/core';

class Footer extends React.Component {
    render() {
        const year = new Date().getFullYear();

        return (
            <Box p={2} display="flex" justifyContent="center" style={{ backgroundColor: '#EE3A29', color: '#FFFFFF' }}>
                <Typography style={{ fontSize: 14, fontWeight: 400 }}>Copyright ©{year} Eli Lilly and Company. All rights reserved.</Typography>
            </Box>
        )
    }
}

export default Footer;