import React from "react";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";

import { ADMINFileService, CustomEventHandlerService } from "../../../Services";
import { FacultyDTO } from "../../../Models";
import { FileType, DocumentType, CustomEventType } from "../../../Common/Enums";
import Utils from "../../../Common/Utils";

import DetailCard from "../../../Components/Card/DetailCard";
import GridItem from "../../../Components/Grid/GridItem";
import FileUpload from "../../../Components/File/FileUpload";
import FileUploadModel from "../../../Components/File/FileUploadModel";
import FileUploadCard from "../../../Components/File/FileUploadCard";

interface Props {
    selectedFaculty?: FacultyDTO;
    onCVChange: ((isValid: boolean) => void);
}
interface State {
    files: FileUploadModel[];
}
class HCPProfile extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);


        this.state = {
            files: [],
        };

    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.selectedFaculty) {
            if (this.props.selectedFaculty.facultyid !== (prevProps.selectedFaculty && prevProps.selectedFaculty.facultyid)) {
                this.loadData();
            }
        } else {
            const { files } = this.state;

            if (files.length > 0) {
                this.setState({
                    files: [],
                });
            }
        }
    }

    render() {
        const { files } = this.state;
        console.log('filess', files);
        const { selectedFaculty } = this.props;

        if (selectedFaculty) {
            return (
                <DetailCard title="HCP Profile">
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Grid container justify="space-between">
                                <GridItem label="First Name" value={selectedFaculty.firstname} />
                                <GridItem label="Middle Name" value={selectedFaculty.middleinitials} />
                                <GridItem label="Last Name" value={selectedFaculty.lastname} />
                                <GridItem label="Suffix" value="" />
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <Typography style={{ fontSize: 16, fontWeight: 500, color: '#5D5D5D' }}>
                                        Upload CV
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Card style={{ border: '1px solid #E5E5E5', borderRadius: '3px', opacity: 1, boxShadow: '0px 3px 6px #00000029' }}>
                                        <CardContent>
                                            <Grid container direction="row" spacing={2}>
                                                <Grid item md={12}>
                                                    <FileUpload onChange={this.handleFileUpload} />
                                                </Grid>
                                                <Grid item md={12}>
                                                    <FileUploadCard files={files}
                                                        onDelete={this.handleFileDelete} onDownload={this.handleFileDownload} />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DetailCard>
            );
        }

        return null;
    }

    loadData = async () => {
        const { selectedFaculty } = this.props;

        if (selectedFaculty) {
            const facultyFiles = await ADMINFileService.getAll(selectedFaculty.facultyid, DocumentType.CV);

            const files: FileUploadModel[] = [];

            facultyFiles.filter(file => file.documenttype === DocumentType.CV).forEach((facultyFile) => {
                const file = new FileUploadModel();

                file.id = facultyFile.id;
                file.name = facultyFile.filename;
                file.facultyId = facultyFile.facultyid;
                file.type = FileType[facultyFile.documenttype as keyof typeof FileType];
                file.s3filekey = facultyFile.s3filekey;
                file.sizeInKB = facultyFile.fileSizeInKB ?? 0;

                files.push(file);
            });

            this.setState({ files });

            if (files.length > 0) {
                this.props.onCVChange(true);
            } else {
                this.props.onCVChange(false);
            }
        }
    }

    handleFileUpload = async (file: FileUploadModel) => {
        const { files } = this.state;
        const isFileSizeValid = Utils.validateFileSize(file.sizeInMB, DocumentType.CV);
        const isFileTypeValid = Utils.validateFileType(file.type, DocumentType.CV);
        const isNameExists = files.filter(p => p.name.includes(file.name));
        let message = '';

        const sizes = files.map(p => p.sizeInKB);
        const totalSize = sizes.reduce((a, b) => a + b, 0) + file.sizeInKB;

        if (isFileSizeValid && isFileTypeValid && totalSize <= 10000 && isNameExists.length === 0) {
            const { selectedFaculty } = this.props;

            if (selectedFaculty) {
                file.facultyId = selectedFaculty.facultyid;

                const facultyFile = await ADMINFileService.uploadFile(file, DocumentType.CV);

                if (facultyFile) {
                    const fileUploadDTO = new FileUploadModel();

                    fileUploadDTO.id = facultyFile.id;
                    fileUploadDTO.name = facultyFile.filename;
                    fileUploadDTO.facultyId = facultyFile.facultyid;
                    fileUploadDTO.type = FileType[facultyFile.documenttype as keyof typeof FileType];
                    fileUploadDTO.s3filekey = facultyFile.s3filekey;
                    fileUploadDTO.sizeInKB = file.sizeInKB
                    fileUploadDTO.sizeInMB = file.sizeInMB;

                    this.setState(prevState => ({
                        files: [...prevState.files, fileUploadDTO],
                    }));

                    message = 'CV uploaded successfully';
                    CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, { alertId: 'fileUpload', message });
                    this.props.onCVChange(true);
                }
            }
        } else {
            if (!isFileSizeValid) {
                message = 'Invalid file size';
            }
            if (!isFileTypeValid) {
                message = 'Invalid file type';
            }
            if (totalSize > 10000) {
                message = 'Total size of attachment cannot exceed 10MB';
            }
            if (isNameExists.length > 0) {
                message = 'File already exists with same name';
            }
            CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, { alertId: 'fileUpload', message });
        }
    }

    handleFileDownload = async (file: FileUploadModel) => {
        const downloadURL = await ADMINFileService.getFile(file.s3filekey, file.type);
        Utils.openUrlParent(downloadURL);
    }

    handleFileDelete = async (file: FileUploadModel) => {
        const isSuccess = await ADMINFileService.deleteFile(file, DocumentType.CV);
        let message = '';

        if (isSuccess) {
            const { files } = this.state;

            const index = files.findIndex(p => p.name === file.name && p.type === file.type);
            files.splice(index, 1);

            this.setState({ files }, () => {
                const fileInput = document.getElementById("fileUpload") as HTMLInputElement;

                if (fileInput) {
                    fileInput.value = '';
                }
            });

            message = 'CV deleted successfully';
            CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, { alertId: 'fileUpload', message });

            if (files.length === 0) {
                this.props.onCVChange(false);
            }
        } else {
            message = 'Failed to delete CV';
            CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, { alertId: 'fileUpload', message });
        }
    }
}

export default HCPProfile;