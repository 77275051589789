import { TableCell, Typography } from "@material-ui/core";

const TableBodyCell: React.FC<{ label: any }> = ({ label }) => {
    return (
        <TableCell>
            <Typography style={{ fontSize: 16, fontWeight: 400, color: '#828282' }}>{label}</Typography>
        </TableCell>
    );
};

export default TableBodyCell;