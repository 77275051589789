import { RESTClient } from '../Common/RESTClient';
import { ResponseModel } from '../Models';
import Utils from '../Common/Utils';

abstract class ProQuestAPIService {
    private readonly _client: RESTClient;

    constructor() {
        const envVars = Utils.getEnvVars();
        const baseURL = envVars.API_URL ?? '';
        this._client = new RESTClient(baseURL);
    }

    protected async GetAsync<T>(path: string): Promise<ResponseModel<T>> {
        const response = await this._client.get<T>(path);
        return response;
    }

    protected async PostAsync<T>(path: string, data: any, headers?: any): Promise<ResponseModel<T>> {
        const response = await this._client.post<T>(path, data, headers);
        return response;
    }

    protected async PutAsync<T>(path: string, data: any): Promise<ResponseModel<T>> {
        const response = await this._client.put<T>(path, data);
        return response;
    }

    protected async DeleteAsync<T>(path: string): Promise<ResponseModel<T>> {
        const response = await this._client.delete<T>(path);
        return response;
    }
}

export default ProQuestAPIService;