import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Divider, IconButton, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import Menus from './Menus';
import MenuModel from './MenuModel';

interface Props extends RouteComponentProps<any> {
    isOpen: boolean;
    onClose: (() => void);
}
interface State { }
class MenuDrawer extends React.Component<Props, State> {
    render() {
        const { isOpen, onClose } = this.props;

        return (
            <Drawer
                variant="persistent"
                anchor="left"
                open={isOpen}
            >
                <IconButton onClick={onClose}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                </IconButton>
                <Divider />
                <List>
                    {Menus.map((menu, index) => (
                        <ListItem button key={`Drawer${menu.name}`} onClick={() => this.handleMenuClick(menu)}>
                            <ListItemIcon><FontAwesomeIcon icon={menu.icon} /></ListItemIcon>
                            <ListItemText primary={menu.name} />
                        </ListItem>
                    ))}
                </List>
            </Drawer>
        );
    }

    handleMenuClick(menu: MenuModel) {
        this.props.history.push(menu.path);
        this.props.onClose();
    }
}

export default withRouter(MenuDrawer);