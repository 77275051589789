import React from "react";
import { Box, Card, CardContent, Divider, Grid } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { UserDTO } from "../../Models";
import UserForm from "./UserForm";
import UserCard from "./UserCard";
import { UserService } from "../../Services";

import RedButton from "../../Components/Button/RedButton";
import PageHeader from "../../Components/Text/PageHeader";
import SearchFilter from "../../Components/Search/SearchFilter";
import SearchFilterModel from "../../Components/Search/SearchFilterModel";
import UserFilterModel from "./UserFilterModel";

interface Props { }
interface State {
    isLoading: boolean;
    showForm: boolean;
    userFormDTO: UserDTO;
    users: UserDTO[];
    filterUsers: UserDTO[];
    userFilter: UserFilterModel;
}
class AdminUserManagement extends React.Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            isLoading: true,
            showForm: false,
            userFormDTO: {} as UserDTO,
            users: [],
            filterUsers: [],
            userFilter: new UserFilterModel(),
        };
    }

    async componentDidMount() {
        const users = await UserService.getAll();
        this.setState({ users, filterUsers: users, isLoading: false });
    }

    render() {
        const { showForm, userFormDTO, filterUsers, userFilter } = this.state;

        return (
            <Box m={2}>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                >
                    <PageHeader label="User Management" />
                    <RedButton id={'Add'} startIcon={<FontAwesomeIcon icon={faPlus} />} label={'New User'} onClick={this.handleAddClick} />
                </Grid>

                <Card style={{ marginTop: 10 }}>
                    <CardContent>
                        {showForm ? <>
                            <UserForm user={userFormDTO}
                                onCancelClick={this.handleCancelClick} onSubmit={this.handleUserFormSubmit} />
                            <Divider orientation="horizontal" style={{ marginTop: 15, marginBottom: 15 }} />
                        </> : <></>}

                        <Grid container style={{ marginBottom: 15 }}>
                            <Grid item xs={3}>
                                <SearchFilter label="Search by Name or ID" value={userFilter.searchText}
                                    onChange={this.onUserFilterChange} />
                            </Grid>
                        </Grid>
                        <UserCard users={filterUsers} onEdit={this.onUserEditClick} onDelete={this.onUserDeleteClick} />
                    </CardContent>
                </Card>
            </Box>
        );
    }

    handleAddClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ showForm: true, userFormDTO: {} as UserDTO });
    }

    handleCancelClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ showForm: false });
    }

    handleUserFormSubmit = (user: UserDTO) => {
        this.setState({ isLoading: true }, async () => {
            if (user.id) {
                const existingUser = await UserService.edit(user);

                const { users } = this.state;

                if (existingUser) {
                    const index = users.findIndex(p => p.id === user.id);
                    users[index] = user;
                }

                this.setState({ users, userFormDTO: user, isLoading: false });
            } else {
                const newUser = await UserService.add(user);

                if (newUser) {
                    this.setState(prevState => ({
                        users: [...prevState.users, newUser],
                        userFormDTO: {} as UserDTO,
                        isLoading: false
                    }));
                }
            }
        });
    }

    onUserEditClick = (user: UserDTO) => {
        this.setState({ showForm: false }, () => {
            this.setState({ showForm: true, userFormDTO: user });
        });
    }

    onUserDeleteClick = (user: UserDTO) => {
        this.setState({ showForm: false, userFormDTO: {} as UserDTO, isLoading: true }, async () => {
            const isSuccess = await UserService.delete(user);

            const { users } = this.state;

            if (isSuccess) {
                const index = users.findIndex(p => p.id === user.id);
                users.splice(index, 1);
            }

            this.setState({ users, isLoading: false });
        });
    }

    onUserFilterChange = (searchFilterModel: SearchFilterModel) => {
        const { userFilter, users } = this.state;

        const filterUsers = users.filter(user => {
            if (user.firstname.toUpperCase().includes(searchFilterModel.searchText.toUpperCase())) {
                return true;
            }

            return false;
        });

        userFilter.searchText = searchFilterModel.searchText;

        this.setState({ userFilter, filterUsers });
    }
}

export default AdminUserManagement;